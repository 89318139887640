import axios from "axios";
// import "../../../../public/assets/css/style.css";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { GetDataWithToken, PostDataWithToken } from "../../api/apiHelper";
import {
  dispatchMotorQuote,
  dispatchProcessFlowHealth,
  dispatchProcessFlowMotor,
  dispatchQuickQuote,
} from "../../store/action/userActions";
import { createQuotePayloadObj } from "../../store/reducer/userReducers";
import {
  downloadBase64File,
  downloadDigitPdf,
  changePolicyStatus,
  processFlow,
  getPaymentStatus,
  getPolicyDocument,
  getFlow,
  getPreviousJourneyDetails,
  getKycServiceStatus,
} from "../../components/services/TPApiCall";
import Header from "../common/Header";
import { toast } from "react-toastify";
import { caseStatus, extractCustomerLeadId } from "../utility/constants";
import CryptoJS from "crypto-js";
import { Cases } from "@mui/icons-material";

// import Layout from '../Layout'
// var FileSaver = require("file-saver");

const SuccessMessage = () => {
  const selectedPlan = useSelector((state) => state.root.selectedPlan);
  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  const motorRequest = useSelector((state) => state.root.motorRequest);
  const processFlowObjHealth = useSelector(
    (state) => state?.root?.processFlowObjHealth
  );
  const processFlowObjMotor = useSelector(
    (state) => state?.root?.processFlowObjMotor
  );
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const bajajId = searchParams.get("p_policy_ref");
  const kotakId = searchParams.get("vPolicyNumber");

  const [sompoPdf, setSompoPdf] = useState(null);
  const [downloadLoader, setDownloadLoader] = useState(true);

  const [policyDetails, setPolicyDetails] = useState({});
  const [paymentStatusState, setPaymentStatusState] = useState("");
  const [customerLeadId, setCustomerLeadId] = useState("");
  const [kycRedirection, setKycRedirection] = useState(false);
  const [kycService, setKycService] = useState(null);

  const location = useLocation();

  const [paramsData, setParamsData] = useState({
    applicationId: "",
    quotationNumber: "",
    policyNumber: "",
    Api_name: "",
    ProposalNumber: "",
    ProductCode: "",
    caseId: "",
  });

  const params = useParams();

  useEffect(() => {
    const confettiColors = [
      "#EF2964",
      "#00C09D",
      "#2D87B0",
      "#48485E",
      "#EFFF1D",
    ];
    const confettiAnimations = ["slow", "medium", "fast"];
    const containerEl = document.querySelector(".js-container");

    const setupElements = () => {
      const container = document.createElement("div");
      const elPosition = containerEl.style.position;

      if (elPosition !== "relative" || elPosition !== "absolute") {
        containerEl.style.position = "relative";
      }

      container.classList.add("confetti-container");
      containerEl.appendChild(container);
    };

    setupElements();

    const renderConfetti = () => {
      setInterval(() => {
        const confettiEl = document.createElement("div");
        const confettiSize = Math.floor(Math.random() * 3) + 7 + "px";
        const confettiBackground =
          confettiColors[Math.floor(Math.random() * confettiColors.length)];
        const confettiLeft =
          Math.floor(Math.random() * containerEl.offsetWidth) + "px";
        const confettiAnimation =
          confettiAnimations[
            Math.floor(Math.random() * confettiAnimations.length)
          ];

        confettiEl.classList.add(
          "confetti",
          "confetti--animation-" + confettiAnimation
        );
        confettiEl.style.left = confettiLeft;
        confettiEl.style.width = confettiSize;
        confettiEl.style.height = confettiSize;
        confettiEl.style.backgroundColor = confettiBackground;

        confettiEl.removeTimeout = setTimeout(function () {
          confettiEl.parentNode.removeChild(confettiEl);
        }, 3000);

        containerEl.appendChild(confettiEl);
      }, 25);
    };

    renderConfetti();
    const custLeadId = location?.pathname.split("/");

    setCustomerLeadId(custLeadId[2]);

    return () => {
      clearInterval(renderConfetti);
    };
  }, []);

  useEffect(() => {
    const currentURL = window.location.search;
    const searchParams = new URLSearchParams(location.search);
    const applicationId = searchParams.get("applicationId");
    const quotationNumber = searchParams.get("quotationNumber");
    const policyNumber = searchParams.get("policyNumber");
    const Api_name = searchParams.get("Api_name");
    const ApiUniqueNumber = searchParams.get("ApiUniqueNumber");
    const ProposalNumber = searchParams.get("ProposalNumber");
    const ProductCode = searchParams.get("ProductCode");
    const caseId = searchParams.get("caseId");

    setParamsData({
      applicationId: applicationId,
      quotationNumber: quotationNumber,
      policyNumber: policyNumber,
      Api_name: Api_name,
      ApiUniqueNumber: ApiUniqueNumber,
      ProposalNumber: ProposalNumber,
      ProductCode: ProductCode,
      caseId: caseId,
    });

    const urlCustomerLeadId = extractCustomerLeadId(location?.pathname);

    // getPreviousJourneyDetails({
    //   customerLeadId: urlCustomerLeadId,
    // });
    // let fetchDetailsResponse;
    // fetchDetails(urlCustomerLeadId).then((res) => {
    //   console.log("fetchDetails", res);
    //   fetchDetailsResponse = res;
    // });
    // console.log("fetchDetailsResponse", fetchDetailsResponse);
    // const motorRequestData = fetchDetailsResponse?.data?.step12?.motorRequest;
    // console.log("motorRequestData", motorRequestData);
    const fetchDetails = async () => {
      const response = await getPreviousJourneyDetails({
        customerLeadId: urlCustomerLeadId,
      });
      console.log("getPreviousJourneyDetails", response);
      const motorRequestData = response?.data?.stepsData?.step11?.motorRequest;
      console.log("motorRequestData", motorRequestData);
      for (let key in motorRequestData) {
        dispatchMotorQuote(key, motorRequestData[key]);
      }
      paymentStatus(caseId, motorRequestData);
    };
    fetchDetails();

    if (Api_name !== "digit") {
      const startIndex =
        currentURL.indexOf("Payment%20successfully.|") +
        "Payment%20successfully.|".length;
      const linkAfterSuccess = currentURL.substring(startIndex);

      // Decode the URL component to handle encoded characters
      const decodedLink = decodeURIComponent(linkAfterSuccess);

      // Do something with the extracted value

      var finalUrl = decodedLink.split("|");

      setSompoPdf(finalUrl[0]);
      // const urlParams =  new URLSearchParams(currentURL)
      // const quoteID = urlParams.get('MSG')
      // console.log("QuoteID", quoteID );
      // const postData = {
      //   ...processFlowObjHealth,
      //   step: "step10",
      //   step10: { pdf: finalUrl[0] },
      // };
      // processFlow(postData);
      // dispatchProcessFlowHealth("step10", postData?.step10);
    }
  }, []);

  const paymentStatus = async (data, motorRequestData) => {
    console.log("motorRequestData", motorRequestData);
    setDownloadLoader(true);

    try {
      const response = await getPaymentStatus(data);

      setPaymentStatusState(response?.data?.caseStatus);

      if (
        response?.data?.caseStatus === caseStatus.paymentSuccess ||
        response?.data?.caseStatus === caseStatus.policyDocSuccess
      ) {
        if (response?.data?.policyNo) {
          setPolicyDetails({
            policyNumber: response?.data?.policyNo,
          });
        }
        if (response?.data?.isKycStatusServiceCall) {
          // setKycRedirection(true);
          // const postData = {
          //   caseId: motorRequestData?.caseId,
          //   vehicleType: motorRequestData?.vehicleType,
          // };
          const kycService = await kycServiceStatus(motorRequestData);
          console.log("kycService", kycService);
          if (kycService === "success") {
            try {
              const documentResponse = await getPolicyDocument(
                response?.data?.caseId
              );

              if (
                documentResponse?.status === "success" &&
                documentResponse?.data?.caseStatus ===
                  caseStatus.policyDocSuccess
              ) {
                const decryptPolicyDocumentUrl = decryptAes256Text(
                  documentResponse?.data?.policyDocumentUrl,
                  process.env.REACT_APP_DECRYPTION_KEY
                );
                setPolicyDetails({
                  policyNumber: documentResponse?.data?.policyNo,
                  policyDocumentUrl: decryptPolicyDocumentUrl,
                });
                const dataForFlow = {
                  ...processFlowObjMotor,
                  customerLeadId: motorRequest?.customerLeadId,

                  processDiscription: "pdfGeneration",
                  step: "step13",
                  step13: {
                    ...processFlowObjMotor.step13,
                    pdfGeneration: {
                      pdfUrl: decryptPolicyDocumentUrl,
                      pdfStatus: "pdfGenerated",
                    },
                    motorRequest: { ...motorRequest },
                  },
                };
                processFlow(dataForFlow);
              } else {
                // Handle the case where the document response is not successful

                toast.error(
                  documentResponse?.data?.displayMessage
                    ? `${documentResponse.data.displayMessage}`
                    : documentResponse?.data?.errors
                    ? `${documentResponse?.data?.errors[0].displayMessage}`
                    : `Error in getting Policy Document`,
                  {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
                const dataForFlow = {
                  ...processFlowObjMotor,
                  customerLeadId: motorRequest?.customerLeadId,

                  processDiscription: "pdfGeneration",
                  step: "step13",
                  step13: {
                    ...processFlowObjMotor.step13,
                    pdfGeneration: documentResponse?.data?.displayMessage
                      ? `${documentResponse.data.displayMessage}`
                      : documentResponse?.data?.errors
                      ? `${documentResponse?.data?.errors[0].displayMessage}`
                      : `${documentResponse?.errors?.[0]?.displayMessage}`,
                    motorRequest: { ...motorRequest },
                  },
                };
                processFlow(dataForFlow);
              }
            } catch (error) {
              toast.error(
                documentResponse?.data?.displayMessage
                  ? `${documentResponse.data.displayMessage}`
                  : documentResponse?.data?.errors
                  ? `${documentResponse?.data?.errors[0].displayMessage}`
                  : `Error in getting Policy Document`,
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                }
              );
              const dataForFlow = {
                ...processFlowObjMotor,
                customerLeadId: motorRequest?.customerLeadId,

                processDiscription: "pdfGeneration",
                step: "step13",
                step13: {
                  ...processFlowObjMotor.step13,
                  pdfGeneration: documentResponse?.data?.displayMessage
                    ? `${documentResponse.data.displayMessage}`
                    : documentResponse?.data?.errors
                    ? `${documentResponse?.data?.errors[0].displayMessage}`
                    : `${documentResponse?.errors?.[0]?.displayMessage}`,
                  motorRequest: { ...motorRequest },
                },
              };
              processFlow(dataForFlow);
            }
            // setKycRedirection(true);
            dispatchMotorQuote("kycServiceStatusResponse", kycService);
            // dispatchMotorQuote("redirection", true);
            // setTimeout(() => {
            //   window.open(kycService?.data?.link, "_blank");
            // }, 3000);
          } else {
            return;
          }
        } else {
          try {
            const documentResponse = await getPolicyDocument(
              response?.data?.caseId
            );

            if (
              documentResponse?.status === "success" &&
              documentResponse?.data?.caseStatus === caseStatus.policyDocSuccess
            ) {
              const decryptPolicyDocumentUrl = decryptAes256Text(
                documentResponse?.data?.policyDocumentUrl,
                process.env.REACT_APP_DECRYPTION_KEY
              );
              setPolicyDetails({
                policyNumber: documentResponse?.data?.policyNo,
                policyDocumentUrl: decryptPolicyDocumentUrl,
              });
              const dataForFlow = {
                ...processFlowObjMotor,
                customerLeadId: motorRequest?.customerLeadId,

                processDiscription: "pdfGeneration",
                step: "step13",
                step13: {
                  ...processFlowObjMotor.step13,
                  pdfGeneration: {
                    pdfUrl: decryptPolicyDocumentUrl,
                    pdfStatus: "pdfGenerated",
                  },
                  motorRequest: { ...motorRequest },
                },
              };
              processFlow(dataForFlow);
            } else {
              // Handle the case where the document response is not successful

              toast.error(
                documentResponse?.data?.displayMessage
                  ? `${documentResponse.data.displayMessage}`
                  : documentResponse?.data?.errors
                  ? `${documentResponse?.data?.errors[0].displayMessage}`
                  : `Error in getting Policy Document`,
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                }
              );
              const dataForFlow = {
                ...processFlowObjMotor,
                customerLeadId: motorRequest?.customerLeadId,

                processDiscription: "pdfGeneration",
                step: "step13",
                step13: {
                  ...processFlowObjMotor.step13,
                  pdfGeneration: documentResponse?.data?.displayMessage
                    ? `${documentResponse.data.displayMessage}`
                    : documentResponse?.data?.errors
                    ? `${documentResponse?.data?.errors[0].displayMessage}`
                    : `${documentResponse?.errors?.[0]?.displayMessage}`,
                  motorRequest: { ...motorRequest },
                },
              };
              processFlow(dataForFlow);
            }
          } catch (error) {
            toast.error(
              documentResponse?.data?.displayMessage
                ? `${documentResponse.data.displayMessage}`
                : documentResponse?.data?.errors
                ? `${documentResponse?.data?.errors[0].displayMessage}`
                : `Error in getting Policy Document`,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
            const dataForFlow = {
              ...processFlowObjMotor,
              customerLeadId: motorRequest?.customerLeadId,

              processDiscription: "pdfGeneration",
              step: "step13",
              step13: {
                ...processFlowObjMotor.step13,
                pdfGeneration: documentResponse?.data?.displayMessage
                  ? `${documentResponse.data.displayMessage}`
                  : documentResponse?.data?.errors
                  ? `${documentResponse?.data?.errors[0].displayMessage}`
                  : `${documentResponse?.errors?.[0]?.displayMessage}`,
                motorRequest: { ...motorRequest },
              },
            };
            processFlow(dataForFlow);
          }
        }
      } else {
        // Handle the case where the payment status is not successful
        console.error("Payment status not successful: ", response);
        const dataForFlow = {
          ...processFlowObjMotor,
          customerLeadId: motorRequest?.customerLeadId,

          processDiscription: "paymentGateway",
          step: "step12",
          step12: {
            ...processFlowObjMotor.step12,
            paymentStatus: "failure",
            motorRequest: { ...motorRequest },
          },
        };
        processFlow(dataForFlow);
      }
    } catch (error) {
      console.error("Error fetching payment status", error);
    } finally {
      setDownloadLoader(false);
    }
  };

  const kycServiceStatus = async (motorRequestData) => {
    console.log("postData", motorRequestData);
    const postData = {
      caseId: motorRequestData?.caseId,
      vehicleType: motorRequestData?.vehicleType,
    };
    const kycService = await getKycServiceStatus(postData);
    if (kycService?.data?.kycStatus === "failure") {
      setKycService(kycService?.data?.kycStatus);
      setKycRedirection(true);
      dispatchMotorQuote("kycServiceStatusResponse", kycService);
      dispatchMotorQuote("redirection", true);
      window.open(kycService?.data?.redirectionUrl, "_blank");
      return kycService?.data?.kycStatus;
    } else {
      setKycRedirection(false);
      setKycService(kycService?.data?.kycStatus);
      return kycService?.data?.kycStatus;
    }
  };

  const decryptAes256Text = (encrypted, key) => {
    // Decode base64 encoded string to a WordArray
    const input = CryptoJS.enc.Base64.parse(encrypted);

    // Extract the IV (first 16 bytes) and the ciphertext (remaining bytes)
    const iv = CryptoJS.lib.WordArray.create(input.words.slice(0, 4), 16); // First 16 bytes (128 bits)
    const ciphertext = CryptoJS.lib.WordArray.create(
      input.words.slice(4),
      input.sigBytes - 16
    ); // Remaining bytes

    // Hash the key using SHA-256
    const hashedKey = CryptoJS.SHA256(key);

    // Decrypt the ciphertext using the hashed key and IV
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: ciphertext },
      hashedKey,
      {
        iv: iv,
        mode: CryptoJS.mode.CTR,
        padding: CryptoJS.pad.NoPadding, // NoPadding because CTR mode doesn't require padding
      }
    );

    // Convert decrypted WordArray to UTF-8 string
    return CryptoJS.enc.Utf8.stringify(decrypted);
  };

  const getPolicyDoc = async (data) => {
    try {
      const documentResponse = await getPolicyDocument(data);

      if (
        documentResponse?.status === "success" &&
        documentResponse?.data?.caseStatus === "Policy Document Success"
      ) {
        const decryptPolicyDocumentUrl = decryptAes256Text(
          documentResponse?.data?.policyDocumentUrl,
          process.env.REACT_APP_DECRYPTION_KEY
        );
        setPolicyDetails({
          policyNumber: documentResponse?.data?.policyNo,
          policyDocumentUrl: decryptPolicyDocumentUrl,
        });
        const dataForFlow = {
          ...processFlowObjMotor,
          customerLeadId: motorRequest?.customerLeadId,

          processDiscription: "pdfGeneration",
          step: "step13",
          step13: {
            ...processFlowObjMotor.step13,
            pdfGeneration: {
              pdfUrl: decryptPolicyDocumentUrl,
              pdfStatus: "pdfGenerated",
            },
            motorRequest: { ...motorRequest },
          },
        };
        processFlow(dataForFlow);
      } else {
        // Handle the case where the document response is not successful

        toast.error(
          documentResponse?.data?.displayMessage
            ? `${documentResponse.data.displayMessage}`
            : documentResponse?.data?.errors
            ? `${documentResponse?.data?.errors[0].displayMessage}`
            : `Error in getting Policy Document`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        const dataForFlow = {
          ...processFlowObjMotor,
          customerLeadId: motorRequest?.customerLeadId,

          processDiscription: "pdfGeneration",
          step: "step13",
          step13: {
            ...processFlowObjMotor.step13,
            pdfGeneration: documentResponse?.data?.displayMessage
              ? `${documentResponse.data.displayMessage}`
              : documentResponse?.data?.errors
              ? `${documentResponse?.data?.errors[0].displayMessage}`
              : `${documentResponse?.errors?.[0]?.displayMessage}`,
            motorRequest: { ...motorRequest },
          },
        };
        processFlow(dataForFlow);
      }
    } catch (error) {
      toast.error(
        documentResponse?.data?.displayMessage
          ? `${documentResponse.data.displayMessage}`
          : documentResponse?.data?.errors
          ? `${documentResponse?.data?.errors[0].displayMessage}`
          : `Error in getting Policy Document`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      const dataForFlow = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,

        processDiscription: "pdfGeneration",
        step: "step13",
        step13: {
          ...processFlowObjMotor.step13,
          pdfGeneration: documentResponse?.data?.displayMessage
            ? `${documentResponse.data.displayMessage}`
            : documentResponse?.data?.errors
            ? `${documentResponse?.data?.errors[0].displayMessage}`
            : `${documentResponse?.errors?.[0]?.displayMessage}`,
          motorRequest: { ...motorRequest },
        },
      };
      processFlow(dataForFlow);
    }
  };
  // const digitKYCStatusApi = () => {
  //   setDownloadLoader(true);
  //   let postData = {
  //     applicationId: paramsData.applicationId,
  //     policyNumber: paramsData.policyNumber,
  //     quotationNumber: paramsData.quotationNumber,
  //   };

  //   PostDataWithToken("kyc/digit-kyc", postData).then((response) => {
  //     if (response.status === true) {
  //       if (
  //         response.data["Motor-KYC status search Api"].kycVerificationStatus !==
  //         "DONE"
  //       ) {
  //         alert("You have to complete your digit kyc first");
  //         window.open(
  //           response.data["Motor-KYC status search Api"].link,
  //           "_blank"
  //         );
  //       } else if (
  //         response.data["Motor-KYC status search Api"].kycVerificationStatus ==
  //         "DONE"
  //       ) {
  //         downloadDigitPdf(
  //           paramsData.applicationId,
  //           paramsData?.ApiUniqueNumber,
  //           paramsData?.quotationNumber,
  //           processFlowObjMotor,
  //           setDownloadLoader
  //         );
  //       }
  //     }
  //   });
  // };

  // const digitStatusOFAPI = () => {
  //   setDownloadLoader(true);
  //   let postData = {
  //     applicationId: paramsData.applicationId,
  //     policyNumber: paramsData.policyNumber,
  //     quotationNumber: paramsData?.quotationNumber,
  //   };

  //   PostDataWithToken("motor/search-digit", postData).then((response) => {
  //     if (response.status === true) {
  //       if (response.data.kycStatus.kycVerificationStatus != "DONE") {
  //         alert("You have to complete your digit kyc first");
  //         window.open(response.data.kycStatus.link, "_blank");
  //       } else if (response.data.kycStatus.kycVerificationStatus === "DONE") {
  //         downloadDigitPdf(
  //           paramsData?.applicationId,
  //           paramsData?.ApiUniqueNumber,
  //           paramsData?.quotationNumber,
  //           processFlowObjMotor,
  //           setDownloadLoader
  //         );
  //       }
  //     }
  //   });
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (paramsData?.Api_name === "digit") {
  //       // setDownloadLoader(true)
  //       digitKYCStatusApi();
  //     } else if (paramsData?.Api_name === "Kotak") {
  //       // setDownloadLoader(true)
  //       downloadKotakPdf();
  //     }
  //   }, [2000]);
  // }, [paramsData]);

  // const downloadKotakPdf = () => {
  //   setDownloadLoader(true);
  //   let postData = {
  //     vProposalNumber: paramsData.ProposalNumber,
  //     vPolicyNumber: kotakId,
  //     vProductCode: paramsData?.ProductCode,
  //   };
  //   PostDataWithToken("motor/kotak-pdf-download", postData).then((res) => {
  //     if (res.status === true) {
  //       const fileName = `INSUREFAST${kotakId}`;
  //       const linkSource = `data:application/pdf;base64,${res.data}`;
  //       const downloadLink = document.createElement("a");
  //       document.body.appendChild(downloadLink);

  //       downloadLink.href = linkSource;
  //       downloadLink.target = "_self";
  //       downloadLink.download = fileName;

  //       downloadLink.click();
  //       setDownloadLoader(false);
  //       dispatchProcessFlowMotor("step13.pdf", downloadLink);
  //       // postData = {
  //       //   ...processFlowObjMotor,
  //       //   apiRequestQQ: { ...apiRequestQQ },
  //       //   step: "step13",
  //       //   step13: downloadLink,
  //       // };
  //       // processFlow(postData);
  //     }
  //   });
  // };

  // const sendPolicyPdfToEmail = (policyNumber, base64) => {
  //   let postData = {
  //     name: apiRequestQQ.FirstName,
  //     amount: selectedPlan.FinalPremium,
  //     quoteId: policyNumber,
  //     email: apiRequestQQ.Email,
  //     base64: base64,
  //   };
  //   PostDataWithToken("motor/send-policy", postData).then((response) => {
  //     console.log("email sent");
  //   });
  // };

  // const digitKYCStatusApi = () => {
  //   let postData = {
  //     applicationId: apiRequestQQ.applicationId,
  //     policyNumber: apiRequestQQ.ApiId
  //   }
  //   PostDataWithToken('kyc/digit-kyc', postData).then(response => {
  //     if (response.status === true) {
  //       if (response.data.kycVerificationStatus != 'DONE') {
  //         console.log('respo', response.data.link)
  //         alert('You have to complete your digit kyc first')
  //         window.open(response.data.link, '_blank')
  //       } else if (response.data.kycVerificationStatus == 'DONE') {
  //         downloadDigitPdf(apiRequestQQ.applicationId)
  //       }
  //     }
  //   })
  // }

  return (
    <>
      <Header />
      {kycRedirection ? (
        <div style={{ marginTop: "200px", height: "100vh" }}>
          <div className="kyc_fail">
            <span>Policy Purcahsed SuccessFully</span>
            <div>But Your KYC Failed! Please Click the Button To Redo KYC</div>
            <button
              onClick={() => kycServiceStatus(motorRequest)}
              className="btn btn-primary fs-5 px-2 py-2 mt-1"
            >
              <span>Verify Kyc</span>
            </button>
          </div>
        </div>
      ) : (
        <div
          className="card text-center"
          style={{
            width: "100%",
            height: "100%",
            marginTop: "3rem",
            overflow: "hidden",
          }}
        >
          <div>
            <div className="js-container"></div>
            <div
              style={{
                textAlign: "center",
                marginTop: "30px",
                position: "relative",
                width: "100%",
                height: "100vh",
                overflow: "hidden",
                top: "100px",
                left: "0px",
              }}
            >
              {paymentStatusState === caseStatus?.paymentSuccess ||
              paymentStatusState === caseStatus?.policyDocSuccess ? (
                <>
                  {" "}
                  <div class="circle-loader">
                    <div class="checkmark draw"></div>
                  </div>
                  <h1>Congratulations!</h1>
                  <p>Policy purchased successfully.</p>
                  {paramsData.Api_name === "digit" && (
                    <p>Policy Number {paramsData.policyNumber}</p>
                  )}
                  {selectedPlan.Api_name == "bajaj" && (
                    <p>Policy Number {bajajId}</p>
                  )}
                  {selectedPlan.Api_name == "Kotak" && (
                    <>
                      <p> Proposal Number: {selectedPlan.vProposalNumber}</p>
                      <p> Policy Number: {kotakId}</p>
                    </>
                  )}
                  {policyDetails?.policyNumber && (
                    <p>Policy Number: {policyDetails.policyNumber}</p>
                  )}
                  <div className="mt-3">
                    {
                      // selectedPlan.Api_name ===
                      // "Go Digit General Insurance Limited" ? (
                      //   <button
                      //     className="btn btn-primary fs-5 px-2 py-2 mt-1"
                      //     onClick={() => {
                      //       paymentStatus(paramsData.caseId);
                      //     }}
                      //   >
                      //     {downloadLoader ? (
                      //       <div
                      //         class="spinner-border text-success"
                      //         role="status"
                      //       >
                      //         <span class="sr-only"></span>
                      //       </div>
                      //     ) : (
                      //       "Download PDF"
                      //     )}
                      //   </button>
                      // ) :
                      paramsData.caseId ? (
                        <button
                          className="btn btn-primary fs-5 px-2 py-2 mt-1"
                          onClick={() =>
                            policyDetails?.policyDocumentUrl
                              ? window.open(policyDetails?.policyDocumentUrl)
                              : kycService === "success"
                              ? getPolicyDoc(motorRequest?.caseId)
                              : null
                          }
                        >
                          {downloadLoader ? (
                            <div
                              class="spinner-border text-light"
                              role="status"
                            >
                              <span class="sr-only"></span>
                            </div>
                          ) : kycRedirection ? (
                            <span>Verify Kyc</span>
                          ) : (
                            "Download PDF"
                          )}
                        </button>
                      ) : null
                    }
                  </div>
                </>
              ) : paymentStatusState === caseStatus?.paymentFailure ? (
                <>
                  <div class="o-circle c-container__circle o-circle__sign--failure">
                    <div class="o-circle__sign"></div>
                  </div>
                  <h1 className="payment_failure_text">Payment Failed</h1>
                  <button
                    className="btn btn-danger fs-3 px-3 py-2 mt-3"
                    onClick={() => navigate(`/proposal/${customerLeadId}`)}
                  >
                    Retry
                  </button>
                </>
              ) : kycRedirection ? (
                <div className="loader_circle">
                  <div class="payment-loader">
                    <div class="pad">
                      <div class="chip"></div>
                      <div class="line line1"></div>
                      <div class="line line2"></div>
                    </div>
                    <div class="loader-text">
                      Your KYC has been failed. Redirecting you to the Insurer's
                      Portal...
                    </div>
                  </div>
                </div>
              ) : (
                <div className="loader_circle">
                  <div class="payment-loader">
                    <div class="pad">
                      <div class="chip"></div>
                      <div class="line line1"></div>
                      <div class="line line2"></div>
                    </div>
                    <div class="loader-text">
                      Please wait while payment is pending
                    </div>
                  </div>
                </div>
              )}

              {/* <div className="checkmark-circle">
              <div className="background"></div>
              <div className="checkmark draw"></div>
            </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SuccessMessage;
