import React from "react";
import { useForm } from "react-hook-form";
import { dispatchHealthQuickQuote } from "../../store/action/userActions";
import { useSelector } from "react-redux";

const Care = ({
  setActiveStep3,
  handleNextStep,
  policyOverview,
  handlePreviousStep,
}) => {
  const {
    handleSubmit: handleSubmit2,
    register: register2,
    formState: { errors: errors2 },
    watch: watch1,
    setValue: setValue1,
    getValues: getValues1,
    reset: reset2,
  } = useForm();

  const healthRequestQQ = useSelector((state) => state?.root?.healthRequestQQ);

  const submitMedicalHistory = (data) => {
    for (let key in data) {
      dispatchHealthQuickQuote(
        `CustomerDetails.customerMedicalHistory.${key}`,
        data[key]
      );

      handleNextStep();
      setActiveStep3(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const maxDateCare = `${currentYear}-${currentMonth}`;

  return (
    <form onSubmit={handleSubmit2(submitMedicalHistory)}>
      <div className="onwer-personal-details-questions">
        {/* -----------------Question 0------------- */}

        {/* -----------------Question 1------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>
              Q. Diabetes Mellitus type 1 or Diabetes on insulin or Diabetes
              associated with blindness or chronic foot ulcer?
            </h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="Diabetes"
                    id="ques1option1"
                    {...register2(
                      "Diabetes",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques1option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="Diabetes"
                        id="ques1option2customer"
                        {...register2(
                          "Diabetes",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques1option2customer">Yes</label>
                    </div>
                    {watch1("Diabetes") === "true" && (
                      <div className="d-flex mb-4">
                        <div className="ms-5 did-floating-label-content">
                          {/* <label htmlFor="DiabetesSinceID">
                      Existing Since
                    </label> */}
                          <input
                            type="month"
                            className=" did-floating-input"
                            id="monthYear"
                            {...register2("DiabetesSince")}
                            // value={date}
                            // onChange={handleDateChange}
                            // min="2022-01"
                            max={maxDateCare}
                          />
                          <label
                            htmlFor="iDiabetesSinceID"
                            className="did-floating-label"
                          >
                            Existing Since
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 2------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>Q. Do you have Hypertension / High Blood Pressure?</h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="hypertensionOrBloodPressure"
                    id="ques2option1"
                    {...register2(
                      "hypertensionOrBloodPressure",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques2option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="hypertensionOrBloodPressure"
                        id="ques2option2customer"
                        {...register2(
                          "hypertensionOrBloodPressure",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques2option2customer">Yes</label>
                    </div>
                    {watch1("hypertensionOrBloodPressure") === "true" && (
                      <div className="d-flex mb-4">
                        <div className="ms-5 did-floating-label-content">
                          {/* <label htmlFor="DiabetesSinceID">
                      Existing Since
                    </label> */}
                          <input
                            type="month"
                            className=" did-floating-input"
                            id="monthYear"
                            // value={date}
                            {...register2("hypertensionOrBloodPressureSince", {
                              required: "Field is required",
                            })}
                            // onChange={handleDateChange}
                            // min="2022-01"
                            max={maxDateCare}
                          />
                          <label
                            htmlFor="iDiabetesSinceID"
                            className="did-floating-label"
                          >
                            Existing Since
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 3------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>
              Q. Pancreatitis or Liver disease (including but not limited to
              Cirrhosis / Hepatitis B or C / Willson’s disease) or any other
              digestive track disorder (disorders of esophagus or stomach or
              intestine or any other)?
            </h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="liverDesease"
                    id="ques3option1"
                    {...register2(
                      "liverDesease",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques3option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="liverDesease"
                        id="ques3option2customer"
                        {...register2(
                          "liverDesease",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques3option2customer">Yes</label>
                    </div>
                    {watch1("liverDesease") === "true" && (
                      <div className="d-flex mb-4">
                        <div className="ms-5 did-floating-label-content">
                          {/* <label htmlFor="DiabetesSinceID">
                      Existing Since
                    </label> */}
                          <input
                            type="month"
                            className=" did-floating-input"
                            id="monthYear"
                            // value={date}
                            {...register2("liverDeseaseSince", {
                              required: "Field is required",
                            })}
                            // onChange={handleDateChange}
                            // min="2022-01"
                            max={maxDateCare}
                          />
                          <label
                            htmlFor="iDiabetesSinceID"
                            className="did-floating-label"
                          >
                            Existing Since
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 4------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>Q. Do you have Cancer?</h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="cancerDeaseas"
                    id="ques4option1"
                    {...register2(
                      "cancerDeaseas",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques4option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="liverDesease"
                        id="ques4option2customer"
                        {...register2(
                          "cancerDeaseas",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques4option2customer">Yes</label>
                    </div>
                    {watch1("cancerDeaseas") === "true" && (
                      <div className="d-flex mb-4">
                        <div className="ms-5 did-floating-label-content">
                          {/* <label htmlFor="DiabetesSinceID">
                      Existing Since
                    </label> */}
                          <input
                            type="month"
                            className=" did-floating-input"
                            id="monthYear"
                            // value={date}
                            {...register2("cancerDeaseasSince", {
                              required: "Field is required",
                            })}
                            // onChange={handleDateChange}
                            // min="2022-01"
                            max={maxDateCare}
                          />
                          <label
                            htmlFor="iDiabetesSinceID"
                            className="did-floating-label"
                          >
                            Existing Since
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 5------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>
              Q. Any cardiovascular/Heart Disease (including but not limited to
              Coronary artery disease / Rheumatic heart disease / Heart Attack
              or Myocardial infarction / Heart failure / Bypass Grafting or CABG
              / Angioplasty or PTCA / Heart valve diseases / Pacemaker
              implantation)?
            </h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="cardiacDeaseas"
                    id="ques5option1"
                    {...register2(
                      "cardiacDeaseas",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques5option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="cardiacDeaseas"
                        id="ques5option2customer"
                        {...register2(
                          "cardiacDeaseas",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques5option2customer">Yes</label>
                    </div>
                    {watch1("cardiacDeaseas") === "true" && (
                      <div className="d-flex mb-4">
                        <div className="ms-5 did-floating-label-content">
                          {/* <label htmlFor="DiabetesSinceID">
                      Existing Since
                    </label> */}
                          <input
                            type="month"
                            className=" did-floating-input"
                            id="monthYear"
                            // value={date}
                            {...register2("cardiacDeaseasSince", {
                              required: "Field is required",
                            })}
                            // onChange={handleDateChange}
                            // min="2022-01"
                            max={maxDateCare}
                          />
                          <label
                            htmlFor="iDiabetesSinceID"
                            className="did-floating-label"
                          >
                            Existing Since
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 6------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>
              Q. Any disorders of Blood and / or Immunity (including but not
              limited to bleeding or clotting disorders, Systemic Lupus
              Erythematosus, Rheumatoid Arthritis, Crohn’s disease, Ulcerative
              Colitis).?
            </h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="jointPain"
                    id="ques6option1"
                    {...register2(
                      "jointPain",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques6option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="jointPain"
                        id="ques6option2customer"
                        {...register2(
                          "jointPain",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques6option2customer">Yes</label>
                    </div>
                    {watch1("jointPain") === "true" && (
                      <div className="d-flex mb-4">
                        <div className="ms-5 did-floating-label-content">
                          {/* <label htmlFor="DiabetesSinceID">
                      Existing Since
                    </label> */}
                          <input
                            type="month"
                            className=" did-floating-input"
                            id="monthYear"
                            // value={date}
                            {...register2("jointPainSince", {
                              required: "Field is required",
                            })}
                            // onChange={handleDateChange}
                            // min="2022-01"
                            max={maxDateCare}
                          />
                          <label
                            htmlFor="iDiabetesSinceID"
                            className="did-floating-label"
                          >
                            Existing Since
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 7------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>Q. Any Kidney / urinary track / reproductive organ Disease?</h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="kidneyDeasease"
                    id="ques7option1"
                    {...register2(
                      "kidneyDeasease",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques7option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="kidneyDeasease"
                        id="ques7option2customer"
                        {...register2(
                          "kidneyDeasease",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques7option2customer">Yes</label>
                    </div>
                    {watch1("kidneyDeasease") === "true" && (
                      <div className="d-flex mb-4">
                        <div className="ms-5 did-floating-label-content">
                          {/* <label htmlFor="DiabetesSinceID">
                      Existing Since
                    </label> */}
                          <input
                            type="month"
                            className=" did-floating-input"
                            id="monthYear"
                            // value={date}
                            {...register2("kidneyDeaseaseSince", {
                              required: "Field is required",
                            })}
                            // onChange={handleDateChange}
                            // min="2022-01"
                            max={maxDateCare}
                          />
                          <label
                            htmlFor="iDiabetesSinceID"
                            className="did-floating-label"
                          >
                            Existing Since
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 8------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>
              Q. Any Neuromuscular (muscles or nervous system) disorder or
              Psychiatric disorders (including but not limited to Motor Neuron
              Disease, Muscular dystrophies, Epilepsy, Paralysis, Parkinsonism,
              multiple sclerosis, stroke, mental illness)?
            </h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="paralysis"
                    id="ques8option1"
                    {...register2(
                      "paralysis",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques8option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="paralysis"
                        id="ques8option2customer"
                        {...register2(
                          "paralysis",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques8option2customer">Yes</label>
                    </div>
                    {watch1("paralysis") === "true" && (
                      <div className="d-flex mb-4">
                        <div className="ms-5 did-floating-label-content">
                          {/* <label htmlFor="DiabetesSinceID">
                      Existing Since
                    </label> */}
                          <input
                            type="month"
                            className=" did-floating-input"
                            id="monthYear"
                            // value={date}
                            {...register2("paralysisSince", {
                              required: "Field is required",
                            })}
                            // onChange={handleDateChange}
                            // min="2022-01"
                            max={maxDateCare}
                          />
                          <label
                            htmlFor="iDiabetesSinceID"
                            className="did-floating-label"
                          >
                            Existing Since
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 9------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>Q. Do you have Congenital Disorder?</h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="congenitalDeaseas"
                    id="ques9option1"
                    {...register2(
                      "congenitalDeaseas",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques9option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="congenitalDeaseas"
                        id="ques9option2customer"
                        {...register2(
                          "congenitalDeaseas",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques9option2customer">Yes</label>
                    </div>
                    {watch1("congenitalDeaseas") === "true" && (
                      <div className="d-flex mb-4">
                        <div className="ms-5 did-floating-label-content">
                          {/* <label htmlFor="DiabetesSinceID">
                      Existing Since
                    </label> */}
                          <input
                            type="month"
                            className=" did-floating-input"
                            id="monthYear"
                            // value={date}
                            {...register2("congenitalDeaseasSince", {
                              required: "Field is required",
                            })}
                            // onChange={handleDateChange}
                            // min="2022-01"
                            max={maxDateCare}
                          />
                          <label
                            htmlFor="iDiabetesSinceID"
                            className="did-floating-label"
                          >
                            Existing Since
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 10------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>Q. Do you have HIV/ AIDS/ STD? * ?</h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="hivAndAidsDeaseas"
                    id="ques10option1"
                    {...register2(
                      "hivAndAidsDeaseas",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques10option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="hivAndAidsDeaseas"
                        id="ques10option2customer"
                        {...register2(
                          "hivAndAidsDeaseas",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques10option2customer">Yes</label>
                    </div>
                    {watch1("hivAndAidsDeaseas") === "true" && (
                      <div className="d-flex mb-4">
                        <div className="ms-5 did-floating-label-content">
                          {/* <label htmlFor="DiabetesSinceID">
                      Existing Since
                    </label> */}
                          <input
                            type="month"
                            className=" did-floating-input"
                            id="monthYear"
                            // value={date}
                            {...register2("hivAndAidsDeaseasSince", {
                              required: "Field is required",
                            })}
                            // onChange={handleDateChange}
                            // min="2022-01"
                            max={maxDateCare}
                          />
                          <label
                            htmlFor="iDiabetesSinceID"
                            className="did-floating-label"
                          >
                            Existing Since
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 11------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>
              Q. Does any person(s) to be insured has any Pre-existing diseases?
              ?
            </h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="PED"
                    id="ques11option1"
                    {...register2(
                      "PED",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques11option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="PED"
                        id="ques11option2customer"
                        {...register2(
                          "PED",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques11option2customer">Yes</label>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 12------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>
              Q. Have any of the above mentioned person(s) to be insured been
              diagnosed / hospitalized for any illness / injury during the last
              48 months?
            </h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="Hospitalized"
                    id="ques12option1"
                    {...register2(
                      "Hospitalized",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques12option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="Hospitalized"
                        id="ques12option2customer"
                        {...register2(
                          "Hospitalized",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques12option2customer">Yes</label>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 13------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>
              Q. Have any of the person(s) to be insured ever filed a claim with
              their current / previous insurer?
            </h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="healthClaim"
                    id="ques13option1"
                    {...register2(
                      "healthClaim",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques13option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="healthClaim"
                        id="ques13option2customer"
                        {...register2(
                          "healthClaim",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques13option2customer">Yes</label>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 14------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>
              Q. Has any proposal for Health insurance been declined, cancelled
              or charged a higher premium?
            </h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="healthDeclined"
                    id="ques14option1"
                    {...register2(
                      "healthDeclined",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques14option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="healthDeclined"
                        id="ques14option2customer"
                        {...register2(
                          "healthDeclined",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques14option2customer">Yes</label>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 15------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>
              Q.Is any of the person(s) to be insured, already covered under any
              other health insurance policy of Religare Health Insurance?
            </h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="healthCovered"
                    id="ques15option1"
                    {...register2(
                      "healthCovered",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques15option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="healthCovered"
                        id="ques15option2customer"
                        {...register2(
                          "healthCovered",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques15option2customer">Yes</label>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 16------key pending------- */}
        {/* <div className="proposal-cantainer">
    <div className="text-box">
      <h4>
        Q.Any other diseases or ailments not mentioned above
        ?
      </h4>
      <div className="proposal-text">
        <div className="proposal-text">
          <div className="proposal-text-self">
            <input
              type="radio"
              name="Hospitalized"
              id="ques16option1"
              {...register2(
                "Hospitalized",
                { value: "false" },
                {
                  required: "Field is mandatory",
                }
              )}
              value={false}
            />
            <label for="ques16option1">No</label>
          </div>
        </div>

        {
          <div className="proposal-text-self">
            <div className="div-proposal-box d-block">
              <div className="d-flex">
                <input
                  type="radio"
                  name="Hospitalized"
                  id="ques16option2customer"
                  {...register2(
                    "Hospitalized",
                    { value: "true" },
                    {
                      required: "Field is mandatory",
                    }
                  )}
                  value={true}
                />
                <label for="ques16option2customer">
                  Yes
                </label>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div> */}
        {/* -----------------Question 17------key pending others------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>Q. Any other diseases or ailments not mentioned above?</h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="otherDeaseas"
                    id="ques17option1"
                    {...register2(
                      "otherDeaseas",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques17option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="otherDeaseas"
                        id="ques17option2customer"
                        {...register2(
                          "otherDeaseas",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques17option2customer">Yes</label>
                    </div>
                    {watch1("otherDeaseas") === "true" && (
                      <div className="d-flex mb-4">
                        <div className="ms-5 did-floating-label-content">
                          {/* <label htmlFor="DiabetesSinceID">
                      Existing Since
                    </label> */}
                          <input
                            type="month"
                            className=" did-floating-input"
                            id="monthYear"
                            // value={date}
                            {...register2("otherDeaseasSince", {
                              required: "Field is required",
                            })}
                            // onChange={handleDateChange}
                            // min="2022-01"
                            max={maxDateCare}
                          />
                          <label
                            htmlFor="iDiabetesSinceID"
                            className="did-floating-label"
                          >
                            Existing Since
                          </label>
                        </div>
                        <div className="ms-5 did-floating-label-content">
                          {/* <label htmlFor="DiabetesSinceID">
                      Existing Since
                    </label> */}
                          <input
                            type="text"
                            className=" did-floating-input"
                            id="monthYear"
                            {...register2("otherDeaseasDiscription", {
                              required: "Field is required",
                            })}
                          />
                          <label
                            htmlFor="iDiabetesSinceID"
                            className="did-floating-label"
                          >
                            Description
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 18------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>
              Q. Any Respiratory disease / Disease of Lungs, Pleura and airway
              (including but not limited to Asthma / Tuberculosis / Pleural
              effusion / Bronchitis / Emphysema)?
            </h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="respoiratoryDisease"
                    id="ques18option1"
                    {...register2(
                      "respoiratoryDisease",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques18option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="respoiratoryDisease"
                        id="ques18option2customer"
                        {...register2(
                          "respoiratoryDisease",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques18option2customer">Yes</label>
                    </div>
                    {watch1("respoiratoryDisease") === "true" && (
                      <div className="d-flex mb-4">
                        <div className="ms-5 did-floating-label-content">
                          {/* <label htmlFor="DiabetesSinceID">
                      Existing Since
                    </label> */}
                          <input
                            type="month"
                            className=" did-floating-input"
                            id="monthYear"
                            // value={date}
                            {...register2("respoiratoryDiseaseSince", {
                              required: "Field is required",
                            })}
                            // onChange={handleDateChange}
                            // min="2022-01"
                            max={maxDateCare}
                          />
                          <label
                            htmlFor="iDiabetesSinceID"
                            className="did-floating-label"
                          >
                            Existing Since
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 19------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>
              Q. Any disorders of the endocrine system (including but not
              limited to Pituitary / Parathyroid / adrenal gland disorders)?
            </h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="endoAndPituitaryDeasease"
                    id="ques19option1"
                    {...register2(
                      "endoAndPituitaryDeasease",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques19option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="endoAndPituitaryDeasease"
                        id="ques19option2customer"
                        {...register2(
                          "endoAndPituitaryDeasease",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques19option2customer">Yes</label>
                    </div>
                    {watch1("endoAndPituitaryDeasease") === "true" && (
                      <div className="d-flex mb-4">
                        <div className="ms-5 did-floating-label-content">
                          {/* <label htmlFor="DiabetesSinceID">
                      Existing Since
                    </label> */}
                          <input
                            type="month"
                            className=" did-floating-input"
                            id="monthYear"
                            // value={date}
                            {...register2("endoAndPituitaryDeaseaseSince", {
                              required: "Field is required",
                            })}
                            // onChange={handleDateChange}
                            // min="2022-01"
                            max={maxDateCare}
                          />
                          <label
                            htmlFor="iDiabetesSinceID"
                            className="did-floating-label"
                          >
                            Existing Since
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 20------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>
              Q. Has any of the Proposed to be Insured consulted/taken treatment
              or recommended to take investigations/medication/surgery other
              than for childbirth/minor injuries? *?
            </h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="illness"
                    id="ques20option1"
                    {...register2(
                      "illness",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques20option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="illness"
                        id="ques20option2customer"
                        {...register2(
                          "illness",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques20option2customer">Yes</label>
                    </div>
                    {watch1("illness") === "true" && (
                      <div className="d-flex mb-4">
                        <div className="ms-5 did-floating-label-content">
                          {/* <label htmlFor="DiabetesSinceID">
                      Existing Since
                    </label> */}
                          <input
                            type="month"
                            className=" did-floating-input"
                            id="monthYear"
                            // value={date}
                            {...register2("illnessSince", {
                              required: "Field is required",
                            })}
                            // onChange={handleDateChange}
                            // min="2022-01"
                            max={maxDateCare}
                          />
                          <label
                            htmlFor="iDiabetesSinceID"
                            className="did-floating-label"
                          >
                            Existing Since
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 21------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>
              Q. Has any of the Proposed to be Insured been hospitalized or has
              been under any prolonged treatment for any illness/injury or has
              undergone surgery other than for childbirth/minor injuries? *?
            </h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="surgeries"
                    id="ques21option1"
                    {...register2(
                      "surgeries",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques21option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="surgeries"
                        id="ques21option2customer"
                        {...register2(
                          "surgeries",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques21option2customer">Yes</label>
                    </div>
                    {watch1("surgeries") === "true" && (
                      <div className="d-flex mb-4">
                        <div className="ms-5 did-floating-label-content">
                          {/* <label htmlFor="DiabetesSinceID">
                      Existing Since
                    </label> */}
                          <input
                            type="month"
                            className=" did-floating-input"
                            id="monthYear"
                            // value={date}
                            {...register2("surgeriesSince", {
                              required: "Field is required",
                            })}
                            // onChange={handleDateChange}
                            // min="2022-01"
                            max={maxDateCare}
                          />
                          <label
                            htmlFor="iDiabetesSinceID"
                            className="did-floating-label"
                          >
                            Existing Since
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* -----------------Question 22------------- */}
        <div className="proposal-cantainer">
          <div className="text-box">
            <h4>
              Q. Do You smoke, consume alcohol, or chew tobacco, ghutka or paan
              or use any recreational drugs? If ‘Yes’ then please provide the
              frequency & amount consumed. ?
            </h4>
            <div className="proposal-text">
              <div className="proposal-text">
                <div className="proposal-text-self">
                  <input
                    type="radio"
                    name="smoke"
                    id="ques22option1"
                    {...register2(
                      "smoke",
                      { value: "false" },
                      {
                        required: "Field is mandatory",
                      }
                    )}
                    value={false}
                  />
                  <label for="ques22option1">No</label>
                </div>
              </div>

              {
                <div className="proposal-text-self">
                  <div className="div-proposal-box d-block">
                    <div className="d-flex">
                      <input
                        type="radio"
                        name="smoke"
                        id="ques22option2customer"
                        {...register2(
                          "smoke",
                          { value: "true" },
                          {
                            required: "Field is mandatory",
                          }
                        )}
                        value={true}
                      />
                      <label for="ques22option2customer">Yes</label>
                    </div>
                    {watch1("smoke") === "true" && (
                      <div className="d-flex mb-4">
                        <div className="ms-5 did-floating-label-content">
                          {/* <label htmlFor="DiabetesSinceID">
                      Existing Since
                    </label> */}
                          <input
                            type="month"
                            className=" did-floating-input"
                            id="monthYear"
                            // value={date}
                            {...register2("smokeSince", {
                              required: "Field is required",
                            })}
                            // onChange={handleDateChange}
                            // min="2022-01"
                            max={maxDateCare}
                          />
                          <label
                            htmlFor="iDiabetesSinceID"
                            className="did-floating-label"
                          >
                            Existing Since
                          </label>
                        </div>
                        <div className="ms-5 did-floating-label-content">
                          {/* <label htmlFor="DiabetesSinceID">
                      Existing Since
                    </label> */}
                          <input
                            type="text"
                            className=" did-floating-input"
                            // id="monthYear"
                            // value={date}
                            {...register2("smokeDescription", {
                              required: "Field is required",
                            })}
                            // onChange={handleDateChange}
                            // min="2022-01"
                            max={maxDateCare}
                          />
                          <label
                            htmlFor="iDiabetesSinceID"
                            className="did-floating-label"
                          >
                            Other Smoke Details
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>

        <div className="next-btn-btn">
          <button className="proceedBtn1" onClick={() => handlePreviousStep()}>
            Previous
          </button>

          {policyOverview ? (
            <button
              type="submit"
              className="proceedBtn1"
              onClick={() => handleNextStep()}
            >
              Proceed
            </button>
          ) : (
            <button
              type="submit"
              className="proceedBtn1"
              // onClick={() => handleNextStep()}
            >
              Proceed
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default Care;
