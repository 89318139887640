import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./Quotelist.css";
import {
  dispatchMotorQuote,
  dispatchQuickQuote,
  resetQuickQuoteResults,
} from "../../store/action/userActions";
import { useDispatch, useSelector } from "react-redux";
import {
  KotakAPICall,
  digitAPICall,
  processFlow,
  sompoAPICall,
} from "../services/TPApiCall";
import { POLICY_TYPE } from "../utility/constants";
import { RxCross2 } from "react-icons/rx";
function QuotelistModelPlan({
  show,
  setModel,
  filter,
  filteredList,
  handleFilteredResult,
}) {
  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const processFlowObjMotor = useSelector(
    (state) => state?.root?.processFlowObjMotor
  );

  const toggle = () => setModel(!show);
  const [select, setSelect] = useState(motorRequest?.newPolicyType);
  const [newData, setNewData] = useState({});
  const [selectPolicyTypeError, setSelectPolictTypeError] = useState(false);
  const dispatch = useDispatch();
  const list = [
    // {
    //   type: "All",
    //   desc: "",
    // },
    {
      type: "Comprehensive",
      value: "comprehensive",
      // desc: "This covers your car (around 50%) and any damage to any third party.",
    },
    {
      type: "Third Party",
      value: "thirdParty",
      // desc: "This covers only accidental damage to any property or any other person but does not cover damage to your car.",
    },
    {
      type: "Own Damage",
      value: "odOnly",
      // desc: "100% coverage to your car, and does not depreciate the value of the car excluding consumables like engine oil,nuts, etc",
    },
  ];
  // const apiRequestQQ = useSelector((state) => state?.root?.apiRequestQQ);

  // useEffect(() => {
  //   apiCall();
  // }, [newData?.NewPolicyType]);

  const submitPolicyType = () => {
    if (!select) {
      setSelectPolictTypeError(true);
      return;
    } else if (select === motorRequest?.newPolicyType) {
      toggle();
      return;
    }

    // let newData = { ...apiRequestQQ };
    let newStructureData = { ...motorRequest };
    dispatchQuickQuote("NewPolicyType", select);
    let newStructurePolicyType;
    select === POLICY_TYPE.COMPREHENSIVE
      ? (newStructurePolicyType = POLICY_TYPE?.COMPREHENSIVE)
      : select === POLICY_TYPE.THIRDPARTY
      ? (newStructurePolicyType = POLICY_TYPE?.THIRDPARTY)
      : select === POLICY_TYPE.ODONLY
      ? (newStructurePolicyType = POLICY_TYPE?.ODONLY)
      : select === POLICY_TYPE.BUNDLED
      ? (newStructurePolicyType = POLICY_TYPE?.BUNDLED)
      : null;

    dispatchMotorQuote("newPolicyType", newStructurePolicyType);
    dispatch(resetQuickQuoteResults());

    if (select === "thirdParty") {
      // for (let key in apiRequestQQ?.AddOns) {
      //   if (
      //     key === "PersonalAccident" ||
      //     key === "PassengerCover" ||
      //     key === "LiabilitiesToPaidDriver" ||
      //     key === "PassengerCoverAmount" ||
      //     key === "NoOfLegelDrives"
      //   ) {
      //     console.log("NEWDATA", newData);
      //   } else {
      //     dispatchQuickQuote(`AddOns.${key}`, false);
      //     newData.AddOns[key] = false;
      //   }
      // }
      for (let key in motorRequest) {
        if (
          key === "personalAccident" ||
          key === "isPassengerCover" ||
          key === "liabilitiesToPaidDriver" ||
          key === "passengerCoverAmount" ||
          key === "noOfPaidDrivers"
        ) {
          console.log("NEWDATA", newData);
        } else {
          odAddons.forEach((addon) => {
            const newKey = addon.value;
            dispatchQuickQuote(`${newKey}`, false);
            newStructureData[newKey] = false;
          });
        }
      }
    }
    localStorage.setItem("filter", JSON.stringify(newData?.AddOns));

    // newData = { ...newData, NewPolicyType: select };
    newStructureData = {
      ...newStructureData,
      newPolicyType: newStructurePolicyType,
      idv: 0,
    };
    dispatchMotorQuote("idv", 0);
    // digitAPICall(newData);
    // // sompoAPICall(newData);
    // KotakAPICall(newData);

    const postData = {
      ...processFlowObjMotor,
      customerLeadId: motorRequest?.customerLeadId,
      step: "step7",
      processDiscription: "quotation",
      motorRequest: { ...newStructureData },
      step7: {
        ...processFlowObjMotor.step7,

        motorRequest: { ...newStructureData },
      },
    };
    processFlow(postData).then((response) => console.log("response", response));
    handleFilteredResult(newData, newStructureData);
    toggle();
  };

  // const apiCall = () => {
  //   setNewData(apiRequestQQ);
  //   console.log("NEW ADAARATAAA", newData);
  //   digitAPICall(newData);
  //   sompoAPICall(newData);
  //   KotakAPICall(newData);
  // };

  return (
    <Modal isOpen={show} toggle={toggle} backdrop="static" centered size="md">
      <ModalBody>
        <div class="p-2">
          <div class="gsc_modalWrapper">
            <div className="modal-logo">
              <a>
                <img src="/assets/images/insurefastlogos.svg"></img>
              </a>
            </div>
            <div className="modal_heading w-100 mb-3">
              <h4 className="text-center fs-5">
                {filter === "Plan Model" ? "Select Policy Type" : "Confirm NCB"}
              </h4>

              <div
                onClick={toggle}
                class="v2_close false"
                style={{ cursor: "pointer" }}
              >
                <a>
                  {/* <img
                    src="/assets/images/delete_icon.svg"
                    title="Close"
                    alt="close"
                  /> */}
                  <RxCross2 style={{ color: "#fff" }} />
                </a>
              </div>
            </div>
            <div className="mb-3">
              {filteredList?.map((res) => {
                return (
                  <div
                    onClick={() => {
                      setSelect(res?.value);
                      setSelectPolictTypeError(false);
                    }}
                    className={`plan-type-list ${
                      select == res?.value ? "plan-type-list-active" : ""
                    }`}
                  >
                    <div style={{ cursor: "pointer" }}>
                      <span className="plan-type-list-span">{res?.type}</span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div>
              {selectPolicyTypeError && (
                <p className="text-danger mx-2">Please Select Policy Type</p>
              )}
            </div>
            <div class="v2_btngrp mb-3">
              <div class="wrapper">
                <button
                  type="submit"
                  onClick={() => submitPolicyType()}
                  name="submitBtn"
                  class="button submitButton gsc_col-xs-12 gsc_col-md-12"
                >
                  <div>Confirm</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default QuotelistModelPlan;

const odAddons = [
  { label: "Zero Dep Cover", value: "zeroDepriciationCover", type: "select" },
  {
    label: "Road Side Assistance",
    value: "roadSideAssistance",
    type: "select",
  },
  { label: "Consumables", value: "consumables", type: "select" },
  { label: "Key Replacement", value: "keyReplacement", type: "select" },
  { label: "Invoice Cover", value: "invoiceCover", type: "select" },
  // { label: "Engine Gearbox Cover", value: "engineGearBox", type: "select" },
  { label: "NCB Protection", value: "ncbProtection", type: "select" },
  {
    label: "Loss Of Personal Belongings",
    value: "lossOfPersonalBelongings",
    type: "select",
  },
  { value: "engineProtection", label: "Engine Protection", type: "select" },
  { value: "tyreProtection", label: "Tyre Protection", type: "select" },
  // { value: "rimProtection", label: "Rim Protection", type: "select" },

  // {
  //   label: "Voluntary Deductive",
  //   value: "isVoluntaryDeductable",
  //   type: "input",
  //   min: 0,
  //   inputName: "voluntaryDeductableAmount",
  // },
  {
    label: "Electrical Accessories",
    value: "isElectricalAccessories",
    type: "input",
    min: "1000",
    inputName: "electricAmount",
  },
  {
    label: "Non-Electrical Accessories",
    value: "isNonElectricalAccessories",
    type: "input",
    min: "1000",
    inputName: "nonElectricAmount",
  },
  {
    label: "Is Bi-fuel Kit?",
    value: "isCngAccessories",
    type: "input",
    min: 0,
    inputName: "cngValue",
  },
];
