import React, { useState, useCallback, useEffect } from "react";
import {
  addMonths,
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameDay,
  isSameMonth,
  differenceInCalendarDays,
} from "date-fns";
import {
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSelector } from "react-redux";

const DayButton = styled(Button)(({ theme }) => ({
  minWidth: 36,
  minHeight: 36,
  padding: 0,
  borderRadius: "50%",
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const Calendar = ({ onDateSelect, handleDontKnowDate }) => {
  const motorRequest = useSelector((state) => state?.root?.motorRequest);

  const today = new Date();
  const prevPolicyEndDate = motorRequest?.prevPolicyEndDate
    ? new Date(motorRequest.prevPolicyEndDate)
    : null;

  const isWithinRange =
    prevPolicyEndDate &&
    Math.abs(differenceInCalendarDays(today, prevPolicyEndDate)) <= 90;

  const [selectedDate, setSelectedDate] = useState(
    isWithinRange ? prevPolicyEndDate : today
  );

  const [currentDate, setCurrentDate] = useState(startOfMonth(selectedDate));

  useEffect(() => {
    setCurrentDate(startOfMonth(selectedDate));
  }, [selectedDate]);

  const nextMonths = useCallback(() => {
    setCurrentDate((prevDate) => addMonths(prevDate, 1));
  }, []);

  const prevMonths = useCallback(() => {
    setCurrentDate((prevDate) => addMonths(prevDate, -1));
  }, []);

  const handleDateClick = useCallback(
    (date) => {
      setSelectedDate(date);
      onDateSelect(date);
    },
    [onDateSelect]
  );

  const renderMonth = (monthDate) => {
    const monthStart = startOfMonth(monthDate);
    const monthEnd = endOfMonth(monthDate);
    const monthDays = eachDayOfInterval({ start: monthStart, end: monthEnd });

    return (
      <Box sx={{ width: "100%", maxWidth: 300, p: 2 }}>
        <Typography variant="h6" align="center" gutterBottom>
          {format(monthDate, "MMMM yyyy")}
        </Typography>
        <Grid container spacing={1}>
          {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day) => (
            <Grid item key={day} xs={1.7}>
              <Typography variant="subtitle2" align="center">
                {day}
              </Typography>
            </Grid>
          ))}
          {monthDays.map((day) => (
            <Grid item key={day.toISOString()} xs={1.7}>
              <DayButton
                fullWidth
                variant={isSameDay(day, selectedDate) ? "contained" : "text"}
                color={isSameDay(day, selectedDate) ? "primary" : "inherit"}
                disabled={!isSameMonth(day, monthDate)}
                onClick={() => handleDateClick(day)}
              >
                {format(day, "d")}
              </DayButton>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          maxWidth: 650,
          marginTop: "20px",
          mb: 2,
        }}
      >
        <IconButton onClick={prevMonths} aria-label="Previous month">
          <ChevronLeftIcon />
        </IconButton>
        <h4 className={`${isMobile ? "text-center fs-5" : ""}`}>
          Select Previous Policy Expiry Date
        </h4>
        <IconButton onClick={nextMonths} aria-label="Next month">
          <ChevronRightIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: 2,
        }}
      >
        {renderMonth(currentDate)}
        {!isMobile && renderMonth(addMonths(currentDate, 1))}{" "}
      </Box>
      <p
        className={`mt-3 ${isMobile ? "text-center px-4" : ""}`}
        onClick={handleDontKnowDate}
        style={{ color: "#56b96e", cursor: "pointer" }}
      >
        Don't know Previous Policy Expiry Date
      </p>
    </Box>
  );
};

export default Calendar;
