import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./Quotelist.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  KotakAPICall,
  digitAPICall,
  getMotorQuote,
  processFlow,
  sompoAPICall,
} from "../services/TPApiCall";
import {
  dispatchMotorQuote,
  dispatchProcessFlowMotor,
  dispatchQuickQuote,
  resetQuickQuoteResults,
} from "../../store/action/userActions";
import { POLICY_STATUS, POLICY_TYPE } from "../utility/constants";
import { handleBusinessType } from "../../store/reducer/userReducers";
import { RxCross2 } from "react-icons/rx";
function QuotelistModel({ show, setModel, handleFilteredResult }) {
  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const generateNCBList = () => {
    // If registrationDate is not available, return the full list
    if (!motorRequest?.registrationDate) {
      return [0, 20, 25, 35, 45, 50]; // This is the full list of available NCBs
    }
    const currentYear = new Date();
    const registrationYear = new Date(motorRequest.registrationDate);
    const yearDifference = currentYear - registrationYear;
    const differenceInYears = (
      yearDifference /
      (1000 * 60 * 60 * 24 * 365.25)
    ).toFixed(4);

    const ncbList = [0]; // Always show 0

    if (differenceInYears >= 0.9) ncbList.push(20); // Show 20 if the vehicle is at least 1 year old
    if (differenceInYears >= 1.9) ncbList.push(25); // Show 25 if the vehicle is at least 2 years old
    if (differenceInYears >= 2.9) ncbList.push(35); // Show 35 if the vehicle is at least 3 years old
    if (differenceInYears >= 3.9) ncbList.push(45); // Show 45 if the vehicle is at least 4 years old
    if (differenceInYears >= 4.9) ncbList.push(50); // Show 50 if the vehicle is at least 5 years old

    return ncbList;
  };

  const list = generateNCBList();
  const QuickQouteResult = useSelector(
    (state) => state?.root?.QuickQouteResult
  );

  const toggle = () => setModel(!show);
  // const [select, setSelect] = useState(
  //   QuickQouteResult?.length > 0 ? "no" : ""
  // );
  const [select, setSelect] = useState(
    !motorRequest?.isPreviousInsuranceClaimed
      ? "no"
      : motorRequest?.isPreviousInsuranceClaimed
      ? "yes"
      : ""
  );
  const [ownership, setOwnership] = useState(
    motorRequest?.isOwnerChanged ? "yes" : "no"
  );
  const [bonus, setBonus] = useState(motorRequest?.prevNoClaimBonus);
  const [prevBusinessType, setPreviousBusinessType] = useState(null);
  const apiRequestQQ = useSelector((state) => state?.root?.apiRequestQQ);
  const processFlowObjMotor = useSelector(
    (state) => state?.root?.processFlowObjMotor
  );
  const dispatch = useDispatch();

  const handleNCB = () => {
    let newData = { ...apiRequestQQ };
    let newStructureData = { ...motorRequest };
    dispatchQuickQuote("PreviousNoClaimBonus", bonus);
    dispatchMotorQuote("prevNoClaimBonus", bonus);
    const previousClaimData = {
      isPreviousInsuranceClaimed: motorRequest?.isPreviousInsuranceClaimed,
      prevNoClaimBonus: bonus,
    };
    dispatchProcessFlowMotor("step7.previousClaimData", previousClaimData);
    const postData = {
      ...processFlowObjMotor,
      customerLeadId: motorRequest?.customerLeadId,
      step: "step7",
      processDiscription: "quotation",
      motorRequest: { ...motorRequest },
      step7: {
        ...processFlowObjMotor.step7,
        previousClaimData: previousClaimData,
        motorRequest: { ...motorRequest, prevNoClaimBonus: bonus },
      },
    };
    processFlow(postData).then((response) => console.log("response", response));
    dispatchQuickQuote("NCBSET", true);
    const businessType = handleBusinessType(
      newStructureData?.prevPolicyEndDate
    );
    if (ownership === "no") {
      if (select === "no") {
        const PreviousNoClaimBonus = bonus;
        newData = { ...newData, PreviousNoClaimBonus };
        newStructureData = {
          ...newStructureData,
          prevNoClaimBonus: bonus,
        };
        console.log(newStructureData);
        console.log("newStructureData", newStructureData);

        console.log("businessType", businessType);
        newStructureData = { ...newStructureData, businessType: businessType };
        console.log("newStructureData", newStructureData);
        handleFilteredResult(newData, newStructureData);
        // getMotorQuote(newStructureData);
        toggle();
      } else {
        const PreviousNoClaimBonus = 0;
        newData = { ...newData, PreviousNoClaimBonus };
        dispatchMotorQuote("prevNoClaimBonus", PreviousNoClaimBonus);
        newStructureData = {
          ...newStructureData,
          prevNoClaimBonus: PreviousNoClaimBonus,
        };
        dispatch(resetQuickQuoteResults());

        // digitAPICall(newData);
        // KotakAPICall(newData);
        handleFilteredResult(newData, newStructureData);
        // sompoAPICall(newData);
        toggle();
      }
    } else {
      newStructureData = {
        ...newStructureData,
        prevNoClaimBonus: 0,
      };
      console.log(newStructureData);
      handleFilteredResult(newData, newStructureData);
      toggle();
    }
  };

  useEffect(() => {
    console.log(motorRequest?.businessType);
    console.log(
      !motorRequest?.isOwnerChanged &&
        (motorRequest?.businessType !== POLICY_STATUS.EXPIREDBEFORE90 ||
          motorRequest?.businessType !== POLICY_STATUS.USED)
    );
    console.log(
      motorRequest?.businessType !== POLICY_STATUS.EXPIREDBEFORE90 ||
        motorRequest?.businessType !== POLICY_STATUS.USED
    );
  }, []);

  const dontKnowNCB = () => {
    let newData = { ...apiRequestQQ };
    let newStructureData = { ...motorRequest };
    const PreviousNoClaimBonus = 0;

    newData = { ...newData, PreviousNoClaimBonus };
    newStructureData = {
      ...newStructureData,
      prevNoClaimBonus: PreviousNoClaimBonus,
    };

    dispatch(resetQuickQuoteResults());
    // digitAPICall(newData);
    // KotakAPICall(newData);
    // sompoAPICall(newData);
    handleFilteredResult(newData, newStructureData);
    toggle();
  };

  return (
    <Modal isOpen={show} toggle={toggle} backdrop="static" centered size="md">
      <ModalBody>
        <div class="p-2">
          <div class="gsc_modalWrapper">
            {/* <div className="modal-logo">
              <a>
                <img src="/assets/images/insurefastlogos.svg"></img>
              </a>
            </div> */}
            <div className="modal_heading w-100 mb-3">
              {/* <h4 className="text-center fs-5">Confirm NCB</h4> */}

              <div
                onClick={toggle}
                class="v2_close false"
                style={{ cursor: "pointer" }}
              >
                <a>
                  {/* <img
                    src="/assets/images/delete_icon.svg"
                    title="Close"
                    alt="close"
                  /> */}
                  <RxCross2 style={{ color: "#fff" }} />
                </a>
              </div>
            </div>

            <div className="mb-3">
              {/* {QuickQouteResult.length === 0 && ( */}
              {!motorRequest?.isVehicleNew && (
                <div className="mb-3">
                  <h6 class="table bold">Has the ownership been changed?</h6>
                  <div className="d-flex justify-content-around align-items-center confirm-checkbox">
                    <label
                      onClick={() => {
                        setOwnership("yes");
                        setPreviousBusinessType(motorRequest?.businessType);
                        // dispatchQuickQuote("PreviousInsuranceClaimed", true);
                        dispatchMotorQuote("isOwnerChanged", true);
                        dispatchMotorQuote("businessType", POLICY_STATUS.USED);
                        setBonus(0);
                      }}
                      style={{
                        border:
                          ownership == "yes"
                            ? "3px solid green"
                            : "1px solid grey",
                        width: "40%",
                        borderRadius: "5px",
                        padding: "5px 0",
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      Yes
                    </label>
                    <label
                      onClick={() => {
                        setOwnership("no");
                        // dispatchQuickQuote("PreviousInsuranceClaimed", false);
                        dispatchMotorQuote("isOwnerChanged", false);
                        dispatchMotorQuote("businessType", prevBusinessType);
                      }}
                      style={{
                        border:
                          ownership == "no"
                            ? "3px solid green"
                            : "1px solid grey",
                        width: "40%",
                        borderRadius: "5px",
                        padding: "5px 0",
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      No
                    </label>
                  </div>
                </div>
              )}
              {!motorRequest?.isOwnerChanged &&
                motorRequest?.businessType !==
                  POLICY_STATUS.EXPIREDBEFORE90 && (
                  <div className="mb-3">
                    <h6 class="table bold">
                      Have you made a claim in the previous year?
                    </h6>
                    <div className="d-flex justify-content-around align-items-center confirm-checkbox">
                      <label
                        onClick={() => {
                          setSelect("yes");
                          // dispatchQuickQuote("PreviousInsuranceClaimed", true);
                          dispatchMotorQuote(
                            "isPreviousInsuranceClaimed",
                            true
                          );
                          setBonus(0);
                        }}
                        style={{
                          border:
                            select == "yes"
                              ? "3px solid green"
                              : "1px solid grey",
                          width: "40%",
                          borderRadius: "5px",
                          padding: "5px 0",
                        }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        Yes
                      </label>
                      <label
                        onClick={() => {
                          setSelect("no");
                          // dispatchQuickQuote("PreviousInsuranceClaimed", false);
                          dispatchMotorQuote(
                            "isPreviousInsuranceClaimed",
                            false
                          );
                        }}
                        style={{
                          border:
                            select == "no"
                              ? "3px solid green"
                              : "1px solid grey",
                          width: "40%",
                          borderRadius: "5px",
                          padding: "5px 0",
                        }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        No
                      </label>
                    </div>
                  </div>
                )}
              {/* )} */}
              {select == "no" &&
                motorRequest?.newPolicyType !== POLICY_TYPE.THIRDPARTY &&
                !motorRequest?.isPreviousInsuranceClaimed &&
                !motorRequest?.isOwnerChanged &&
                motorRequest?.businessType !==
                  POLICY_STATUS.EXPIREDBEFORE90 && (
                  <div className="">
                    <h6>Select your Existing NCB (No Claim Bonus)</h6>
                    <p>This is mentioned in your previous policy document</p>
                    <div className="ul-percent">
                      {list?.map((res) => {
                        return (
                          <p onClick={() => setBonus(res)}>
                            <label className={bonus == res ? "selected" : ""}>
                              <span>{res}%</span>
                            </label>
                          </p>
                        );
                      })}
                    </div>
                  </div>
                )}
              {/* <label className="mb-2">
                <input
                  type="checkbox"
                  style={{ marginRight: "10px", borderColor: "grey" }}
                />
                I had Zero Dep in my last year policy
              </label> */}
              <div class="ncbmsg">
                {" "}
                <img src="/assets/images/v2_bulbyellow.svg" alt="Idea" /> No
                Claim Bonus (NCB) is a discount offered on Own Damage (OD)
                premium for not claiming on your motor insurance during the
                policy period.
              </div>
            </div>
            <div class="v2_btngrp mb-3">
              <div class="wrapper">
                <button
                  type="submit"
                  onClick={() => handleNCB()}
                  name="submitBtn"
                  class="button submitButton gsc_col-xs-12 gsc_col-md-12"
                >
                  {QuickQouteResult.length > 0 ? "Update Quotes" : "Get Quotes"}
                </button>
              </div>
            </div>
            {/* {select == "no" && (
              <div
                onClick={() => dontKnowNCB()}
                className="d-flex justify-content-center align-items-center"
                style={{ cursor: "pointer" }}
              >
                <p className="text-decoration-underline">I don't Know my NCB</p>
              </div>
            )} */}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default QuotelistModel;
