import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  getVehicleMake,
  getVehicleModel,
  getVehicleVariant,
} from "../services/masterServices";
import { useDispatch, useSelector } from "react-redux";
// import ReactSelect from "react-select";
import ReactSelect from "../Tags/ReactSelectEditDetails";
import {
  dispatchMotorQuote,
  dispatchProcessFlowMotor,
  dispatchQuickQuote,
  resetQuickQuoteResults,
} from "../../store/action/userActions";
import {
  KotakAPICall,
  digitAPICall,
  processFlow,
  sompoAPICall,
} from "../services/TPApiCall";
import { POLICY_STATUS, POLICY_TYPE } from "../utility/constants";
import moment from "moment";
import DatePicker from "react-datepicker";
import ReactInputMask from "react-input-mask";
import { handlePolicyType } from "../../store/reducer/userReducers";
import { useNavigate } from "react-router-dom";

const EditDetailsModal = ({
  show,
  setModal,
  filterPlanTypeList,
  handleFilteredResult,
  motorQuotation,
  noPlans,
  setNoPlans,
  setPolicyDateExceeded,
  policyDateExceeded,
}) => {
  const toggle = () => setModal(!show);
  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const QuickQouteResult = useSelector(
    (state) => state?.root?.QuickQouteResult
  );
  const [makeData, setMakeData] = useState([]);
  const [newFilter, setNewFilter] = useState({
    makeName: motorRequest?.makeName,
    makeId: motorRequest?.makeId,
    modelName: motorRequest?.modelName,
    modelId: motorRequest?.modelId,
    variantName: motorRequest?.variantName,
    variantId: motorRequest?.variantId,
    registrationDate: motorRequest?.registrationDate,
    manufaturingDate: motorRequest?.manufaturingDate,
    prevPolicyEndDate: motorRequest?.prevPolicyEndDate,
    fuelType: motorRequest?.fuelType,
  });
  const [modelData, setModelData] = useState([]);
  const [variantData, setVariantData] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [makeChange, setMakeChanghe] = useState(false);
  const [modelError, setModelError] = useState(false);
  const [variantError, setVariantError] = useState(false);
  const [modelDifferenceError, setModelDifferenceError] = useState(false);
  const [earlyDateError, setEarlyRegDateError] = useState(false);

  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  const processFlowObjMotor = useSelector(
    (state) => state?.root?.processFlowObjMotor
  );
  const navigate = useNavigate();

  const [manufacturingDate, setManufacturingDate] = useState(
    newFilter?.manufaturingDate
      ? new Date(newFilter.manufaturingDate)
      : motorRequest?.manufaturingDate
      ? new Date(motorRequest.manufaturingDate)
      : null
  );
  const [registrationDate, setRegistrationDate] = useState(
    newFilter?.registrationDate
      ? new Date(newFilter.registrationDate)
      : motorRequest?.registrationDate
      ? new Date(motorRequest.registrationDate)
      : null
  );
  const [prevPolicyEndDate, setPrevPolicyEndDate] = useState(
    newFilter?.prevPolicyEndDate
      ? new Date(newFilter.prevPolicyEndDate)
      : motorRequest?.prevPolicyEndDate
      ? new Date(motorRequest.prevPolicyEndDate)
      : null
  );

  const dispatch = useDispatch();
  const vehicleModel =
    apiRequestQQ.VehicleType === "4w"
      ? "Pvt Car"
      : apiRequestQQ.VehicleType === "2w"
      ? "MotorBike"
      : apiRequestQQ.VehicleType === "gcv"
      ? "Goods Carrying"
      : apiRequestQQ.VehicleType === "pcv" && "Passenger Carrying";

  useEffect(() => {
    let arr = [];
    getVehicleMake({ vehicleType: motorRequest?.vehicleType }).then(
      (response) => {
        if (response.status === "success") {
          let data = response.data;
          let i = 0;
          while (i < data.length) {
            let item = data[i];
            arr.push({
              label: item.make,
              value: item.makeId,
            });
            i++;
          }
          setMakeData(arr);
        }
      }
    );
    getVehicleModel({
      makeId: motorRequest?.makeId,
      vehicleType: motorRequest?.vehicleType,
    }).then((response) => {
      if (response.status === "success") {
        // handleStepSelection(activeStep + 1);
        let data = response.data;
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            label: item?.model,
            value: item?.modelId,
            makeId: item?.makeId,
          });
          i++;
        }

        setModelData(arr);
      }
    });
    getVehicleVariant({
      makeId: motorRequest.makeId,
      modelId: motorRequest?.modelId,
      vehicleType: motorRequest?.vehicleType,
    }).then((response) => {
      if (response.status === "success") {
        let data = response.data;
        let fuelTypeArr = data.filter(
          (v, i, a) => a.findIndex((v2) => v2.fuelType === v.fuelType) === i
        );
        let j = 0;
        let fuelarr = [];
        while (j < fuelTypeArr.length) {
          fuelarr.push(fuelTypeArr[j].fuelType);
          j++;
        }
        setFuelTypes(fuelarr);
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            make: item?.make,
            makeId: item?.makeId,
            model: item?.model,
            modelId: item?.modelId,
            label: `${item?.variant}(${item?.cc}cc)`,
            value: item?.variantId,
            fuelType: item.fuelType,
            cubicCapacity: item.cc,
            seatingCapacity: item.seats,
          });
          i++;
        }

        setVariantData(arr);
      }
    });
  }, []);

  const handleSelectMake = (make) => {
    setNewFilter((prevState) => ({
      ...prevState,
      makeName: make?.label,
      makeId: make?.value,
    }));
    setModelData([]);
    getVehicleModel({
      makeId: make?.value,
      vehicleType: motorRequest?.vehicleType,
    }).then((response) => {
      if (response.status === "success") {
        let data = response.data;
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            label: item?.model,
            value: item?.modelId,
            makeId: item?.makeId,
          });
          i++;
        }
        setModelData(arr);
        setVariantData([]);
      }
    });
  };

  const handleSelectModel = (model) => {
    setModelDifferenceError(false);
    setModelError(false);
    setNewFilter((prevState) => ({
      ...prevState,
      modelName: model?.label,
      modelId: model?.value,
    }));
    getVehicleVariant({
      makeId: newFilter.makeId ? newFilter.makeId : motorRequest?.makeId,
      modelId: model?.value,
      vehicleType: motorRequest?.vehicleType,
    }).then((response) => {
      if (response.status === "success") {
        let data = response.data;
        let fuelTypeArr = data.filter(
          (v, i, a) => a.findIndex((v2) => v2.fuelType === v.fuelType) === i
        );
        let j = 0;
        let fuelarr = [];
        while (j < fuelTypeArr.length) {
          fuelarr.push(fuelTypeArr[j].fuelType);
          j++;
        }
        setFuelTypes(fuelarr);
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            make: item?.make,
            makeId: item?.makeId,
            model: item?.model,
            modelId: item?.modelId,
            label: `${item.variant}(${item.cc}cc)`,
            value: item.variantId,
            fuelType: item.fuelType,
            cubicCapacity: item.cc,
            seatingCapacity: item.seats,
          });
          i++;
        }
        setVariantData(arr);
      }
    });
  };

  const handleSelectPetrolType = (val) => {
    // dispatchQuickQuote("FuelType", val);
    setNewFilter((prevState) => ({ ...prevState, fuelType: val }));
  };

  const handleSelectVariant = (variant) => {
    setModelDifferenceError(false);
    setVariantError(false);
    setNewFilter((prevState) => ({
      ...prevState,
      variantId: variant?.value,
      fuelType: variant?.fuelType,
      variantName: variant?.label,
    }));
  };

  const handleUpdateMMV = () => {
    debugger;
    setPolicyDateExceeded(false);
    if (
      !(
        new Date(newFilter.manufaturingDate) -
          new Date(newFilter.registrationDate) <=
        0
      )
    ) {
      setEarlyRegDateError(true);
      return;
    }
    if (earlyDateError) return;

    let newStructureData = { ...motorRequest };

    console.log("newFilter", newFilter);

    if (newFilter) {
      if (newFilter?.makeName !== motorRequest?.makeName) {
        if (!newFilter?.modelName) {
          setModelError(true);
          return;
        } else {
          console.log("newFilter", newFilter);
          console.log("makeDATAAA", makeData);
          console.log("modelDATAAA", modelData);
          console.log("variantDATAAA", variantData);
          const modelIdExist = modelData.some(
            (model) => model?.value === newFilter?.modelId
          );
          console.log("model", modelIdExist);
          if (!modelIdExist) {
            setModelDifferenceError(true);
            return;
          }
        }
        if (!newFilter?.variantName) {
          setVariantError(true);
          return;
        } else {
          const variantIdExist = variantData.some(
            (variant) => variant?.value === newFilter?.variantId
          );
          console.log("variantIdExist", variantIdExist);
          if (!variantIdExist) {
            setModelDifferenceError(true);
            return;
          }
        }
      }

      // if (
      //   new Date(newFilter?.manufaturingDate) <
      //   new Date(newFilter?.registrationDate)
      // ) {
      //   console.log(
      //     "------",
      //     new Date(newFilter?.manufaturingDate) <
      //       new Date(newFilter?.registrationDate)
      //   );
      //   console.log(
      //     "------",
      //     new Date(newFilter?.manufaturingDate) -
      //       new Date(newFilter?.registrationDate)
      //   );
      //   console.log("------", new Date(newFilter?.manufaturingDate));
      //   console.log("------", new Date(newFilter?.registrationDate));
      //   setEarlyRegDateError(true);
      //   return;
      // }
    }

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 60);
    const regDate = new Date(
      newFilter?.registrationDate
        ? newFilter?.registrationDate
        : motorRequest?.registrationDate
    );
    const difference = Math.abs(currentDate - regDate);
    const diffInYears = parseFloat(
      (difference / (1000 * 60 * 60 * 24 * 365.25)).toFixed(3)
    );

    dispatchQuickQuote("differenceInYears", diffInYears);
    dispatchMotorQuote("differenceInYears", diffInYears);
    dispatchMotorQuote(
      "variantId",
      newFilter?.variantId ? newFilter?.variantId : newStructureData?.variantId
    );

    for (let key in newFilter) {
      if (newStructureData.hasOwnProperty(key)) {
        newStructureData[key] = newFilter[key];
        dispatchMotorQuote(`${key}`, newFilter[key]);
      }
    }

    const handlePolicy = handlePolicyType(regDate, motorRequest?.vehicleType);
    console.log(
      "newStructureData edit vehicle details",
      newStructureData,
      handlePolicy
    );
    newStructureData = {
      ...newStructureData,
      newPolicyType: handlePolicy?.newPolicyType,
      prevPolicyType: handlePolicy?.prevPolicyType,
    };
    console.log("newStructureData edit vehicle details", newStructureData);

    const endDate = new Date(newFilter?.prevPolicyEndDate);
    console.log("currentDate", currentDate, endDate);
    if (endDate > currentDate) {
      const convertedDate = moment(endDate).format("YYYY-MM-DD");
      dispatchMotorQuote("prevPolicyEndDate", convertedDate);

      if (QuickQouteResult.length > 0) {
        dispatch(resetQuickQuoteResults());
      }
      dispatchMotorQuote("policyDateExceeded", true);
      setPolicyDateExceeded(true);

      const newData = { ...motorRequest, policyDateExceeded: true };
      const newDataForFlow = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,
        step: "step7",
        motorRequest: { ...newData },
        processDiscription: "quotation",
        step7: {
          ...processFlowObjMotor.step7,
          motorRequest: { ...newStructureData },
        },
      };

      processFlow(newDataForFlow);
      setModal(!show);
      return;
    }

    if (handlePolicy?.noPlanToShow) {
      navigate(`/quotelist/${motorRequest?.customerLeadId}`);
      setNoPlans(true);
      dispatch(resetQuickQuoteResults());
      setModal(!show);
      return;
    }

    dispatchMotorQuote("idv", 0);

    newStructureData = { ...newStructureData, idv: 0 };

    dispatchProcessFlowMotor("step", "step7");
    const newData = {
      ...processFlowObjMotor,
      customerLeadId: motorRequest?.customerLeadId,
      step: "step7",
      motorRequest: { ...newStructureData },
      processDiscription: "quotation",
      step7: {
        ...processFlowObjMotor.step7,
        motorRequest: { ...newStructureData },
      },
    };
    processFlow(newData);

    filterPlanTypeList();
    dispatch(resetQuickQuoteResults());

    dispatchQuickQuote("initialIdvSet", false);

    motorQuotation(newStructureData);
    // handleFilteredResult(newData);
    // updateMMV();
    setModal(!show);
  };

  const handleMfgDate = (val) => {
    setNewFilter((prevState) => ({ ...prevState, manufaturingDate: val }));
    // dispatchQuickQuote("ManufaturingDate", val);
  };

  const handleMfgDateChange = (date) => {
    if (new Date(date) < new Date(newFilter?.registrationDate)) {
      setEarlyRegDateError(false);
    } else if (new Date(date) < new Date(newFilter?.registrationDate)) {
      setEarlyRegDateError(true);
    }
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      setManufacturingDate(date);
      handleMfgDate(formattedDate);
    } else {
      setManufacturingDate(null);
      handleMfgDate("");
    }
  };

  // Handle manual input change with mask
  const handleMfgInputChange = (event) => {
    const inputValue = event.target.value;

    if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputValue)) {
      const [day, month, year] = inputValue.split("/");
      const date = new Date(`${year}-${month}-${day}`);

      if (!isNaN(date.getTime())) {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        setManufacturingDate(date);
        handleMfgDate(formattedDate);
      }
    } else {
      setManufacturingDate(null);
      handleMfgDate("");
    }
  };

  // const handleRegDate = (val) => {
  //   const regYear = new Date(val).getFullYear();

  //   setNewFilter((prevState) => ({ ...prevState, registrationDate: val }));
  // };

  const handleRegDateChange = (date) => {
    if (new Date(date) > new Date(newFilter?.manufaturingDate)) {
      setEarlyRegDateError(false);
    } else if (new Date(date) < new Date(newFilter?.manufaturingDate)) {
      setEarlyRegDateError(true);
    }
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      setRegistrationDate(date);
      setNewFilter((prevState) => ({
        ...prevState,
        registrationDate: formattedDate,
      }));
    } else {
      setRegistrationDate(null);
      setNewFilter((prevState) => ({ ...prevState, registrationDate: "" }));
    }
  };

  // Handle manual input change with mask
  const handleRegInputChange = (event) => {
    const inputValue = event.target.value;

    if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputValue)) {
      const [day, month, year] = inputValue.split("/");
      const date = new Date(`${year}-${month}-${day}`);

      if (!isNaN(date.getTime())) {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        setRegistrationDate(date);
        setNewFilter((prevState) => ({
          ...prevState,
          registrationDate: formattedDate,
        }));
      }
    } else {
      setRegistrationDate(null);
      setNewFilter((prevState) => ({ ...prevState, registrationDate: "" }));
    }
  };

  const handlePrevPolicyEndDate = (val) => {
    setNewFilter((prevState) => ({ ...prevState, prevPolicyEndDate: val }));
  };

  const handlePrevPolicyEndDateChange = (date) => {
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      setPrevPolicyEndDate(date);
      setNewFilter((prevState) => ({
        ...prevState,
        prevPolicyEndDate: formattedDate,
      }));
    } else {
      setPrevPolicyEndDate(null);
      setNewFilter((prevState) => ({ ...prevState, prevPolicyEndDate: "" }));
    }
  };

  // Handle manual input change with mask
  const handlePrevPolicyEndInputChange = (event) => {
    const inputValue = event.target.value;

    if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputValue)) {
      const [day, month, year] = inputValue.split("/");
      const date = new Date(`${year}-${month}-${day}`);

      if (!isNaN(date.getTime())) {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        setPrevPolicyEndDate(date);
        setNewFilter((prevState) => ({
          ...prevState,
          prevPolicyEndDate: formattedDate,
        }));
      }
    } else {
      setPrevPolicyEndDate(null);
      setNewFilter((prevState) => ({ ...prevState, prevPolicyEndDate: "" }));
    }
  };

  useEffect(() => {
    console.log("newFilter", newFilter);
  }, [newFilter]);

  return (
    <>
      <Modal
        isOpen={show}
        toggle={toggle}
        centered
        size="md"
        backdrop="static"
        keyboard={false}
      >
        {/* <ModalHeader className="d-flex justify-content-between">
          
        </ModalHeader> */}
        <ModalBody>
          <div className="p-3">
            <div className="modal_heading w-100 mb-5">
              <h4 className="text-center fs-3">Edit Details</h4>
              <div
                onClick={toggle}
                class="v2_close false"
                style={{ cursor: "pointer" }}
              >
                <img
                  src="/assets/images/delete_icon.svg"
                  title="Close"
                  alt="close"
                />
              </div>
            </div>
            <div>
              <div className="row">
                <div className="did-floating-label-content">
                  <ReactSelect
                    value={makeData?.find(
                      (option) =>
                        option.label ===
                        (newFilter?.makeName
                          ? newFilter?.makeName
                          : motorRequest?.makeName)
                    )}
                    options={makeData}
                    name="make"
                    placeholder="Select Manufacturer"
                    onChange={(val) => handleSelectMake(val)}
                    // defaultValue={
                    //   newFilter?.makeName
                    //     ? newFilter?.makeName
                    //     : motorRequest?.makeName
                    // }
                  />{" "}
                  <label htmlFor="idxx4" className="did-floating-label">
                    Manufacture
                  </label>
                </div>
                <div className="did-floating-label-content">
                  <ReactSelect
                    options={modelData}
                    name="model"
                    value={modelData?.find(
                      (option) =>
                        option.label ===
                        (newFilter?.modelName
                          ? newFilter?.modelName
                          : motorRequest?.modelName)
                    )}
                    placeholder="Select vehicle modal"
                    onChange={(val) => handleSelectModel(val)}
                    // defaultValue={
                    //   newFilter?.modelName
                    //     ? newFilter?.modelName
                    //     : motorRequest?.modelName
                    // }
                  />
                  <label htmlFor="idxx4" className="did-floating-label">
                    Model
                  </label>
                  {modelError && (
                    <span className="text-danger">Field is required</span>
                  )}
                </div>

                <div className="did-floating-label-content">
                  <ReactSelect
                    value={variantData?.find(
                      (option) =>
                        option?.label ==
                        (newFilter?.variantName
                          ? newFilter?.variantName
                          : motorRequest?.variantName)
                    )}
                    options={variantData}
                    name="variant"
                    // options={variantData.filter(
                    //   (item) => item.Fuel_Type == newFilter.FuelType
                    // )}
                    placeholder="Select vehicle variant"
                    onChange={(val) => handleSelectVariant(val)}
                    // defaultValue={
                    //   newFilter?.variantName
                    //     ? newFilter?.variantName
                    //     : motorRequest?.variantName
                    // }
                  />
                  <label htmlFor="idxx4" className="did-floating-label">
                    Variant
                  </label>
                  {variantError && (
                    <span className="text-danger">Field is required</span>
                  )}
                </div>
                <div className="did-floating-label-content">
                  <select
                    className="form-select text-capitalize"
                    defaultValue={
                      newFilter.fuelType
                        ? newFilter.fuelType
                        : motorRequest?.fuelType
                    }
                    onChange={(e) => handleSelectPetrolType(e.target.value)}
                  >
                    <option>Select Fuel Type</option>
                    {fuelTypes.map((fuel) => (
                      <option
                        selected={
                          newFilter?.fuelType === fuel
                            ? true
                            : motorRequest?.fuelType === fuel
                            ? true
                            : false
                        }
                        // className="text-capitalize"
                        value={fuel}
                      >
                        {fuel}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="idxx4" className="did-floating-label">
                    Fuel Type
                  </label>
                </div>

                <div className="">
                  <div className="">
                    <div className="did-floating-label-content">
                      <DatePicker
                        className="did-floating-input w-100"
                        selected={manufacturingDate}
                        onChange={handleMfgDateChange}
                        customInput={
                          <ReactInputMask
                            mask="99/99/9999"
                            placeholder="DD/MM/YYYY"
                            value={
                              manufacturingDate
                                ? moment(manufacturingDate).format("DD/MM/YYYY")
                                : ""
                            }
                            onChange={handleMfgInputChange}
                            alwaysShowMask={false}
                          />
                        }
                        minDate={
                          motorRequest?.isVehicleNew
                            ? moment()
                                .subtract(1, "years")
                                .startOf("year")
                                .toDate()
                            : moment()
                                .subtract(20, "years")
                                .startOf("year")
                                .toDate()
                        }
                        maxDate={moment().toDate()}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select Manufacture Date"
                      />
                      <label className="did-floating-label">
                        Manufacture Date
                      </label>
                    </div>
                  </div>
                  <div className="">
                    <div className="did-floating-label-content">
                      <DatePicker
                        selected={registrationDate}
                        onChange={handleRegDateChange}
                        customInput={
                          <ReactInputMask
                            mask="99/99/9999"
                            placeholder="DD/MM/YYYY"
                            value={
                              registrationDate
                                ? moment(registrationDate).format("DD/MM/YYYY")
                                : ""
                            }
                            onChange={handleRegInputChange}
                            alwaysShowMask={false}
                          />
                        }
                        className="w-100 did-floating-input"
                        // minDate={
                        //   newFilter?.manufaturingDate
                        //     ? new Date(newFilter.manufaturingDate)
                        //     : new Date(motorRequest?.manufacturingDate)
                        // }
                        maxDate={moment().toDate()}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select Registration Date"
                      />
                      <label className="did-floating-label">
                        Registration Date
                      </label>
                    </div>
                  </div>
                  {motorRequest?.isVehicleNew === false &&
                    (motorRequest?.businessType ===
                      POLICY_STATUS.EXPIREDWITHIN90 ||
                      motorRequest?.businessType ===
                        POLICY_STATUS.NOTEXPIRED) && (
                      <div className="">
                        <div className="did-floating-label-content">
                          <DatePicker
                            selected={prevPolicyEndDate}
                            onChange={handlePrevPolicyEndDateChange}
                            customInput={
                              <ReactInputMask
                                mask="99/99/9999"
                                placeholder="DD/MM/YYYY"
                                value={
                                  prevPolicyEndDate
                                    ? moment(prevPolicyEndDate).format(
                                        "DD/MM/YYYY"
                                      )
                                    : ""
                                }
                                onChange={handlePrevPolicyEndInputChange}
                                alwaysShowMask={false}
                              />
                            }
                            className="w-100 did-floating-input"
                            minDate={
                              motorRequest?.businessType ===
                              POLICY_STATUS.EXPIREDWITHIN90
                                ? moment().subtract(90, "days").toDate()
                                : moment().subtract(0, "days").toDate()
                            }
                            maxDate={
                              motorRequest?.businessType ===
                              POLICY_STATUS.EXPIREDWITHIN90
                                ? moment().subtract(0, "days").toDate()
                                : moment().add(180, "days").toDate()
                            }
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select Previous Policy End Date"
                          />
                          <label className="did-floating-label">
                            Previous Policy End Date
                          </label>
                        </div>
                      </div>
                    )}
                  {modelDifferenceError && (
                    <p className="text-danger">Make, Model, Variant Mismatch</p>
                  )}

                  {earlyDateError && (
                    <p className="text-danger">
                      Registration Date cannot be earlier than Manufacturing
                      Date
                    </p>
                  )}

                  <div className="text-center">
                    <button
                      type="button"
                      onClick={() => handleUpdateMMV()}
                      className="btn btn-primary text-center"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditDetailsModal;
