import React from "react";
import "./PolicyDateError.css";

import { BiErrorCircle } from "react-icons/bi";
import { BsTelephone } from "react-icons/bs";

const PolicyDateError = () => {
  return (
    <div className="policy-error-container">
      <div className="policy-error-card">
        {/* <div className="policy-error-icon-wrapper">
          <img
            src="/assets/images/Confused Guy 2.svg"
            className="policy-error-icon"
          />
        </div> */}
        <img src="/assets/images/waiting mam 3 copy.svg" />
        <h3 className="policy-error-title">
          It’s Too Early to Renew Your Policy!
        </h3>
        <p className="policy-error-message">
          We noticed your policy is still active for more than 60 days. Please
          visit us closer to your policy expiry date to get the best quotes and
          offers tailored for you.
        </p>
        {/* <button className="policy-error-button">
          <BsTelephone className="policy-error-button-icon" />
          Contact Support
        </button> */}
      </div>
    </div>
  );
};

export default PolicyDateError;
