import React from "react";
import Calendar from "./Calender";
import moment from "moment";
import {
  dispatchMotorQuote,
  resetQuickQuoteResults,
} from "../../store/action/userActions";
import { POLICY_STATUS } from "../utility/constants";
import { useDispatch, useSelector } from "react-redux";
import { handleBusinessType } from "../../store/reducer/userReducers";
import { processFlow } from "../services/TPApiCall";

export default function BasicDateCalendar({
  prevPolicyEndDate,
  setPrevPolicyEndDate,
  toggle,
  setNcbModel,
  dateModalOpenFromQuotelist,
  handleFilteredResult,
  policyDateExceeded,
  setPolicyDateExceeded,
}) {
  const dispatch = useDispatch();
  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const QuickQouteResult = useSelector(
    (state) => state?.root?.QuickQouteResult
  );
  const processFlowObjMotor = useSelector(
    (state) => state?.root?.processFlowObjMotor
  );

  const handleDateSelect = (date) => {
    dispatchMotorQuote("policyDateExceeded", false);
    const convertedDate = moment(date).format("YYYY-MM-DD");
    console.log("Selected date:", date);
    const currentDate = new Date();
    const maxDateForPolicy = new Date(
      currentDate.setDate(currentDate.getDate() + 60)
    );
    console.log("maxDateForPolicy:", maxDateForPolicy);

    if (date > maxDateForPolicy) {
      dispatchMotorQuote("prevPolicyEndDate", convertedDate);
      if (QuickQouteResult.length > 0) {
        dispatch(resetQuickQuoteResults());
      }
      dispatchMotorQuote("policyDateExceeded", true);
      setPolicyDateExceeded(true);
      toggle();
      const newData = { ...motorRequest, policyDateExceeded: true };
      const newDataForFlow = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,
        step: "step7",
        motorRequest: { ...newData },
        processDiscription: "quotation",
        step7: {
          ...processFlowObjMotor.step7,
          motorRequest: { ...newData },
        },
      };

      processFlow(newDataForFlow);

      return;
    }

    handleBusinessType(convertedDate);
    dispatchMotorQuote("prevPolicyEndDate", convertedDate);
    setPrevPolicyEndDate(convertedDate);
    const newData = { ...motorRequest, prevPolicyEndDate: convertedDate };
    setTimeout(() => {
      if (dateModalOpenFromQuotelist) {
        handleFilteredResult("", newData);
        toggle();
      } else {
        toggle();
        setNcbModel(true);
      }
    }, [500]);
  };

  const handleDontKnowDate = () => {
    console.log("handleDontKnowDate");
    dispatchMotorQuote("businessType", POLICY_STATUS.EXPIREDBEFORE90);
    dispatchMotorQuote("prevPolicyEndDate", "");

    toggle();
    setNcbModel(true);
  };

  return (
    <div className="container mx-auto py-8">
      {/* <h1 className="text-2xl font-bold mb-4">Custom Two-Month Calendar</h1> */}
      <Calendar
        onDateSelect={handleDateSelect}
        handleDontKnowDate={handleDontKnowDate}
      />
    </div>
  );
}
