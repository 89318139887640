import React, { Component, useEffect, useState } from "react";
import Select from "react-select";
import { FixedSizeList as List } from "react-window";

// Function to sanitize input (only alphanumeric characters and spaces allowed)
const sanitizeInput = (input) => {
  return input.replace(/[^a-zA-Z0-9 ]/g, ""); // Only allow alphanumeric and spaces
};

const ReactSelect = ({
  options,
  name,
  placeholder,
  value,
  defaultValue,
  onChange = () => {},
}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [inputValue, setInputValue] = useState(""); // Track input value separately

  // useEffect(() => {
  //   if (
  //     defaultValue &&
  //     !value &&
  //     options.some(
  //       (option) => option.label.toLowerCase() === defaultValue.toLowerCase()
  //     )
  //   ) {
  //     setSelectedValue(
  //       options.find(
  //         (option) => option.label.toLowerCase() === defaultValue.toLowerCase()
  //       )
  //     );
  //   }
  // }, [defaultValue, value, options]);

  useEffect(() => {
    console.log(" selectedValue VLAUE", value);
    console.log(" selectedValue VLAUE", options);

    setSelectedValue(options.find((option) => option?.label == value?.label));
  }, [value]);

  useEffect(() => {
    console.log("selectedValue", selectedValue);
  }, [selectedValue]);

  // Handle input change, sanitize input to prevent special characters
  const handleInputChange = (value) => {
    const sanitizedValue = sanitizeInput(value); // Remove special characters
    setInputValue(sanitizedValue); // Update the sanitized input value
  };

  const handleChange = (selectedOption) => {
    if (!selectedOption || selectedOption.value !== selectedValue?.value) {
      setSelectedValue(selectedOption); // Update selected value
      onChange(selectedOption); // Trigger onChange callback with selected option
    }
  };

  return (
    <div>
      <Select
        styles={{
          control: (base) => ({
            ...base,
            width: "100%",
            height: "50px",
            borderRadius: "7px",
            border: "1px solid #56b96c",
          }),
        }}
        isSearchable
        options={options}
        components={{ MenuList, IndicatorSeparator: () => null }}
        onChange={handleChange}
        onInputChange={handleInputChange} // Handle and sanitize input change
        name={name}
        placeholder={placeholder}
        value={selectedValue}
        inputValue={inputValue} // Set sanitized input value
      />
    </div>
  );
};

export default ReactSelect;

const height = 35;

// MenuList component for virtualized rendering with react-window
class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}
