import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "../QuotelistComponents/Quotelist.css";
import OtpInput from "react-otp-input";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  createCustomerHealth,
  sendOtp,
  verifyOtp,
} from "../services/TPApiCall";
import { healthQuickQuoteResult } from "../../store/action/userActions";
import store from "../../store";
import { useNavigate } from "react-router-dom";
function OtpModel({
  show,
  setModel,
  resendObj,
  editMobileNumber,
  mobileNumber,
}) {
  const list = [0, 20, 25, 35, 45, 50];
  const toggle = () => setModel(!show);
  const [select, setSelect] = useState("");
  const [bonus, setBonus] = useState(0);
  const [otp, setOtp] = useState("");
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [verificationFailed, setVerificationFailed] = useState(false);
  const [quotesError, setQuotesError] = useState(false);
  const [spinner, setSpinner] = useState();
  const [timer, setTimer] = useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors },

    reset,
  } = useForm();

  const healthRequestQQ = useSelector((state) => state?.root?.healthRequestQQ);

  useEffect(() => {
    setTimeout(() => {
      setTimer(true);
    }, 30000);
  }, [timer]);

  useEffect(() => {
    if (otp.length === 4) {
      submitOtp();
    }
  }, [otp]);

  const submitOtp = async () => {
    const newData = {
      phone: healthRequestQQ?.CustomerDetails?.customerMobileNumber,
      otp: otp,
    };
    const verifyOtpResponse = await verifyOtp(newData);

    if (verifyOtpResponse.success === true) {
      setVerificationSuccess(true);
      navigate("/quotelist");
      // setSpinner(true);
      // const createCustomer = await createCustomerHealth(healthRequestQQ);
      // console.log("createCustomer", createCustomer);
      // if (createCustomer) {
      //   // Handle the successful response here
      //   console.log(
      //     "Received response in the health component:",
      //     createCustomer
      //   );
      //   let healthQuoteResults = createCustomer?.data?.successQuotes;

      //   store.dispatch(healthQuickQuoteResult(healthQuoteResults));
      //   setSpinner(false);
      //   navigate("/quotelist");
      // } else {
      //   // Handle the case where response is null or success is not true
      //   setVerificationSuccess(false);
      //   setQuotesError(true);
      //   setSpinner(false);
      //   console.error("Failed to get a valid response");
      //   console.log(
      //     verificationFailed,
      //     quotesError,
      //     "gyfhtdgrsdhftgjykugjfthjghk"
      //   );
      // }
    }
    // (verifyOtpResponse?.success === false)
    else {
      setVerificationFailed(true);
    }
  };
  return (
    <Modal isOpen={show} toggle={toggle} centered size="md">
      <ModalBody>
        <form onSubmit={handleSubmit(submitOtp)}>
          <div class="p-2">
            <div class="gsc_modalWrapper">
              <div className="modal-logo">
                <a>
                  <img src="/assets/images/insurefastlogos.svg"></img>
                </a>
              </div>
              <div className="modal_heading w-100 mb-4">
                <h4 className="text-center fs-6">
                  Please Enter OTP sent on {mobileNumber}
                </h4>
                <p
                  className="text-center fw-bold"
                  onClick={() => editMobileNumber()}
                  style={{ color: "#59b67c", cursor: "pointer" }}
                >
                  Edit Number
                </p>
                <div onClick={toggle} class="v2_close false">
                  <a>
                    <img
                      src="/assets/images/delete_icon.svg"
                      title="Close"
                      alt="close"
                    />
                  </a>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex w-100 justify-content-center align-items-center">
                  <OtpInput
                    value={otp}
                    inputStyle={{
                      borderRadius: "5px",
                      width: "50px",
                      height: "50px",
                      border: "1px solid black",
                      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
                      gap: "10px",
                    }}
                    // containerStyle={{
                    //   width: "70%",
                    // }}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span> - </span>}
                    inputType="number"
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
              </div>
              <div>
                {quotesError === true && verificationSuccess === false && (
                  <p className="text-danger text-center">
                    Unable to Provide quotes for you!!
                  </p>
                )}
                {verificationSuccess === true ? (
                  <p className="text-success text-center">
                    Your Otp has been successfully Verified
                  </p>
                ) : verificationFailed === true ? (
                  <p className="text-danger text-center">
                    Please enter a valid Otp
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div class="v2_btngrp mb-3">
                <div class="wrapper">
                  {/* <button
                    type="submit"
                    name="submitBtn"
                    class="button submitButton gsc_col-xs-12 gsc_col-md-12"
                  >
                    {spinner ? (
                      <div
                        class="spinner-border text-light"
                        role="status"
                      ></div>
                    ) : (
                      "Submit"
                    )}
                  </button> */}
                  {timer && (
                    <div>
                      <p className="central-login-Receive-OTP text-center">
                        In Case of didn't receive an OTP?{" "}
                        <span
                          className="text-success ms-1 fw-bold"
                          style={{ color: "#59b67c", cursor: "pointer" }}
                          onClick={() => {
                            sendOtp(resendObj);
                            setTimer(false);
                          }}
                        >
                          Resend
                        </span>
                      </p>
                    </div>
                  )}
                  {select == "no" && (
                    <div className="d-flex justify-content-center align-items-center">
                      <p className="text-decoration-underline">
                        I don't Know my NCB
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default OtpModel;
