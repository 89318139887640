import React from "react";
import { useForm } from "react-hook-form";
import ReactSelect from "../Tags/ReactSelectProposal";
import { useSelector } from "react-redux";
import { dispatchQuickQuote } from "../../store/action/userActions";
import { createCustomer } from "../services/TPApiCall";

const OwnerDetailsOrganization = ({
  pincodeData,
  proceedSpinner,
  setProceedSpinner,
  handleNextStep,
  setActiveStep2,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset: reset1,
    setValue,
    clearErrors,
  } = useForm();

  const apiRequestQQ = useSelector((state) => state?.root?.apiRequestQQ);

  const handleOwnerOrganisationDetails = (data) => {
    clearErrors("Pincode");
    console.log("DATAA handleOwnerOrganisationDetails", data);
    for (let key in data) {
      console.log(`${key}`, data[key]);

      dispatchQuickQuote(`${key}`, data[key]);
    }

    setProceedSpinner(true);

    const postData = {
      name:
        apiRequestQQ?.FirstName +
        " " +
        apiRequestQQ?.MiddleName +
        " " +
        apiRequestQQ?.LastName,
      email: apiRequestQQ?.Email,
      phone: apiRequestQQ?.MobileNumber,
      dateOfIncorporation: data?.DateOfIncorporation,
      city: data?.City,
      state: data?.State,
      pan_card: data.PanNumber,
      cinNUmber: data?.CINNumber,
    };
    console.log("POST DATA", postData);
    createCustomer(postData);
    handleNextStep();
    setActiveStep2(true);
    setProceedSpinner(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSelectPincodeOwner = (val) => {
    reset1({ City: val.City, State: val.State });
    console.log("VALUE PINCODE", val);
    setValue("Pincode", val.value);
    clearErrors("Pincode");
  };
  console.log("CONSOLE ERRORS", errors);

  return (
    <form onSubmit={handleSubmit(handleOwnerOrganisationDetails)}>
      <div className="onwer-personal-details">
        <div className="row">
          <div className="col-md-6 mb-2">
            <div className="onwer-details">
              <div className="did-floating-label-content">
                <input
                  type="text"
                  className="did-floating-input"
                  // disabled
                  {...register("CompanyName", {
                    required: "Company Name is required",
                  })}
                  defaultValue={""}
                  placeholder=""
                />
                <label htmlFor="idxx4" className="did-floating-label">
                  Enter Company Name
                </label>
                <p className="f-error text-danger">
                  {errors?.CompanyName?.message}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <div className="onwer-details">
              <div className="did-floating-label-content">
                <input
                  type="number"
                  className="did-floating-input"
                  // disabled
                  {...register("MobileNumber", {
                    required: "Mobile Number is required",
                  })}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (
                      value.startsWith("0") ||
                      value.startsWith("1") ||
                      value.startsWith("2") ||
                      value.startsWith("3") ||
                      value.startsWith("4") ||
                      value.startsWith("5")
                    ) {
                      value = value.substring("1");
                    }
                    e.target.value = value;
                  }}
                  onInput={(e) =>
                    (e.target.value = e.target.value.slice(0, 10))
                  }
                  defaultValue={apiRequestQQ?.MobileNumber}
                  placeholder=""
                />
                <label htmlFor="idxx4" className="did-floating-label">
                  Enter Mobile Number
                </label>
                <p className="f-error text-danger">
                  {errors?.MobileNumber?.message}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <div className="onwer-details">
              <div className="did-floating-label-content">
                <input
                  type="email"
                  className="did-floating-input"
                  // disabled
                  {...register("Email", {
                    required: "Email is required",
                  })}
                  defaultValue={apiRequestQQ?.Email}
                  placeholder=""
                />
                <label htmlFor="idxx4" className="did-floating-label">
                  Email Address
                </label>
                <p className="f-error text-danger">{errors?.Email?.message}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <div className="onwer-details">
              <div className="did-floating-label-content">
                <input
                  type="text"
                  className="did-floating-input"
                  // disabled
                  {...register("RegistrationAddress", {
                    required: "Registration Address is required",
                  })}
                  defaultValue={""}
                  placeholder=""
                />
                <label htmlFor="idxx4" className="did-floating-label">
                  Enter Registration Address
                </label>
                <p className="f-error text-danger">
                  {errors?.RegistrationAddress?.message}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-2">
            <div className="onwer-details">
              <div className="did-floating-label-content">
                <ReactSelect
                  {...register("Pincode", {
                    required: "Pincode is required",
                  })}
                  options={pincodeData}
                  onChange={(val) => handleSelectPincodeOwner(val)}
                  placeholder="RTO Pincode"
                  className="form-select"
                  defaultValue={apiRequestQQ.Pincode}
                />
                {errors.Pincode && (
                  <p className="f-error text-danger">Pincode is required</p>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-2">
            <div className="onwer-details">
              <div className="did-floating-label-content">
                <input
                  type="text"
                  className="did-floating-input"
                  // disabled
                  {...register("City", {
                    required: "City is required",
                  })}
                />
                <label htmlFor="idxx4" className="did-floating-label">
                  City
                </label>
                <p className="f-error text-danger">{errors?.City?.message}</p>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-2">
            <div className="onwer-details">
              <div className="did-floating-label-content">
                <input
                  type="text"
                  className="did-floating-input"
                  // disabled
                  {...register("State", {
                    required: "State is required",
                  })}
                />
                <label htmlFor="idxx4" className="did-floating-label">
                  Enter State
                </label>
                <p className="f-error text-danger">{errors?.State?.message}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <div className="onwer-details">
              <div className="did-floating-label-content">
                <input
                  type="text"
                  className="did-floating-input"
                  // disabled
                  {...register("CINNumber", {
                    required: "CIN Number is required",
                  })}
                  defaultValue={""}
                  placeholder=""
                />
                <label htmlFor="idxx4" className="did-floating-label">
                  Enter CIN Number
                </label>
                <p className="f-error text-danger">
                  {errors?.CINNumber?.message}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-2">
            <div className="onwer-details">
              <div className="did-floating-label-content">
                <input
                  type="text"
                  className="did-floating-input"
                  // disabled
                  {...register("PanNumber", {
                    pattern: /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/,
                    message: "Invalid Pan Number",
                    required: "Pan Number is required",
                  })}
                  maxLength={10}
                  onChange={(e) =>
                    dispatchQuickQuote("PanNumber", e.target.value)
                  }
                  defaultValue={""}
                  placeholder=""
                />
                <label htmlFor="idxx4" className="did-floating-label">
                  Enter Pan Number
                </label>
                <p className="f-error text-danger">
                  {errors?.PanNumber?.message}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-2">
            <div className="onwer-details">
              <div className="did-floating-label-content">
                <input
                  type="date"
                  className="did-floating-input"
                  placeholder=""
                  {...register("DateOfIncorporation", {
                    required: "Date of Incorporation is required",
                  })}
                  //   max={moment().subtract("18", "year").format("YYYY-MM-DD")}
                />
                <label htmlFor="idxx4" className="did-floating-label">
                  Date Of Incorporation
                </label>
                <p className="f-error text-danger">
                  {errors?.DateOfIncorporation?.message}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="next-btn-btn">
            <button className="proceedBtn1" disabled>
              Previous
            </button>

            <button
              type="submit"
              className="proceedBtn1"
              // onClick={() => handleNextStep()}
            >
              {proceedSpinner ? (
                <div class="spinner-border text-light" role="status"></div>
              ) : (
                "Proceed"
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default OwnerDetailsOrganization;
