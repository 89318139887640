import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  getFiancierBankData,
  getStates,
  getVehiclePincode,
  getVehiclePincodeHealth,
  getVehiclePreviousInsurer,
  getVehicleRto,
  sendSuccessMessageKYC,
} from "../services/masterServices";
import ReactSelect from "../Tags/ReactSelectProposal";
import { GetDataWithToken, PostDataWithToken } from "../../api/apiHelper";
import moment from "moment";
import {
  dispatchKycConfig,
  dispatchMotorQuote,
  dispatchProcessFlowMotor,
  dispatchQuickQuote,
  dispatchResetMotorQuote,
  dispatchUserDetails,
  resetQuickQuoteResults,
  resetSelectedPlan,
  selectedPlanAction,
} from "../../store/action/userActions";
import {
  createCustomer,
  getPreviousJourneyDetails,
  kotakIssueApi,
  processFlow,
  sompoCreateQuote,
} from "../services/TPApiCall";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../common/Header";
import Layout from "../common/Layout";
import { Store } from "react-notifications-component";
import OwnerDetailsOrganization from "./OwnerDetailsOrganization";
import KYC from "./KYC";
import NomineeDetails from "./NomineeDetails";
import VehicleDetails from "./VehicleDetails";
import {
  CUSTOMER_TYPE,
  POLICY_TYPE,
  caseStatus,
  extractCustomerLeadId,
  moveToTop,
} from "../utility/constants";
import { useCookies } from "react-cookie";
import { formatRegistrationNumber } from "../../store/reducer/userReducers";

const ProposalForm = () => {
  const {
    handleSubmit: handleSubmit1,
    register: register1,
    formState: { errors: errors1 },
    reset: reset1,
    setValue,
    getValue,
    watch,
  } = useForm();
  const {
    handleSubmit: handleSubmit2,
    register: register2,
    formState: { errors: errors2 },
    reset: reset2,
    watch: watch2,
    setValue: setValue2,
  } = useForm();
  const {
    handleSubmit: handleSubmit3,
    register: register3,
    formState: { errors: errors3 },
    reset: reset3,
  } = useForm();

  const [cookies, setCookie, removeCookie] = useCookies(["customerLeadId"]);
  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  const selectedPlan = useSelector((state) => state.root.selectedPlan);
  const motorRequest = useSelector((state) => state.root.motorRequest);
  console.log("motorRequestmotorRequest", motorRequest?.kycMode);
  const processFlowObjMotor = useSelector(
    (state) => state?.root?.processFlowObjMotor
  );
  const QuickQouteResult = useSelector(
    (state) => state?.root?.QuickQouteResult
  );
  const [currentStep, setCurrentStep] = useState(1);
  const [pincodeData, setPincodeData] = useState([]);

  const [policyOverview, setPolicyOverview] = useState(false);
  const [modal, setModal] = useState(false);
  const [insurerData, setInsurerData] = useState([]);
  const [rtoData, setRtoData] = useState([]);
  const [disabled, setDisabled] = useState("");
  const [RegistrationNumber, setRegistrationNumner] = useState("");
  const [rtoCode, setRtoCode] = useState("");
  const [relianceRto, setRelianceRto] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [docType, setDocType] = useState("");
  const [Hpn, setHpn] = useState(false);
  const [isValidPUC, setIsValidPUC] = useState(false);
  const [financierData, setFinancierData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [selectedRelianceRTO, setSelRelianceRTO] = useState("");
  const [stateName, setState] = useState("");
  const [activeStep2, setActiveStep2] = useState(false);
  const [activeStep3, setActiveStep3] = useState(false);
  const [proceedSpinner, setProceedSpinner] = useState(false);
  const [userInputBank, setUserInputBank] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [extractUrlCustomerLeadId, setExtractUrlCustomerLeadId] =
    useState(false);
  const [sompoManualKYCRefNumber, setManualKycReferenceNumber] =
    useState(false);
  const [kycMode, setKycMode] = useState(
    // motorRequest?.ovdActive ? "ovd" : "ckyc"
    null
  );
  const [vehicleDetailsSpinner, setVehicleDetailsSpinner] = useState(false);
  const healthRequestQQ = useSelector((state) => state?.root?.healthRequestQQ);
  const kycConfig = useSelector((state) => state?.root?.kycConfig);

  const [pan, setPan] = useState({
    number: "",
    image: "",
    error: "",
  });
  const [ckyc, setCkyc] = useState({
    number: "",
    error: "",
  });
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    console.log("LOCATION", location);
    console.log("state location", location);
    if (location?.state?.activeStep === 3) {
      setCurrentStep(3);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    if (
      location?.state?.kycStatus &&
      motorRequest?.addressLine1 === "" &&
      motorRequest?.addressLine2 === "" &&
      motorRequest?.addressLine3 === ""
    ) {
      setCurrentStep(1);
    } else if (location?.state?.kycStatus && selectedPlan?.PersonalAccident) {
      if (
        motorRequest?.nomineeName !== "" &&
        motorRequest?.nomineeName !== "" &&
        motorRequest?.nomineeName !== ""
      ) {
        setCurrentStep(3);
      } else {
        setCurrentStep(2);
      }
    } else if (location?.state?.kycStatus) {
      setCurrentStep(3);
    }
  }, [location]);

  useEffect(() => {
    moveToTop();
  }, []);

  useEffect(() => {
    dispatchMotorQuote("handlePreviousButton", true);
    // debugger;
    const journeyDetails = {
      previousProcessJourney: location?.state?.previousProcessJourney,
      stepsData: location?.state?.stepsData,
    };
    console.log("location?.state?.kycStatus", location?.state?.kycStatus);
    if (journeyDetails?.previousProcessJourney && journeyDetails?.stepsData) {
      updateJourneyData(
        journeyDetails?.stepsData,
        journeyDetails?.previousProcessJourney?.processStep,
        journeyDetails?.previousProcessJourney?.processDescription
      );
    } else {
      console.log("location?.pathname", location?.pathname);
      const urlCustomerLeadId = extractCustomerLeadId(location?.pathname);
      console.log("urlCustomerLeadId", urlCustomerLeadId);
      if (urlCustomerLeadId !== motorRequest?.customerLeadId) {
        dispatchResetMotorQuote();
        dispatch(resetQuickQuoteResults());
        dispatch(resetSelectedPlan());
      }
      setExtractUrlCustomerLeadId(true);
      dispatchMotorQuote("customerLeadId", urlCustomerLeadId);
      const now = new Date();
      // Set the cookie to expire in 2 hours
      const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
      setCookie("customerLeadId", urlCustomerLeadId, { expires });

      getPreviousJourneyDetails({ customerLeadId: urlCustomerLeadId })
        .then((response) => {
          console.log("getPreviousJourneyDetails response", response);
          const stepsData = response?.data?.stepsData;
          // if (
          //   response?.data?.processStep === "step10" &&
          //   response?.data?.processDiscription === "vehicleDetails" &&
          //   stepsData?.step10?.vehicleDetails?.proposalStatus ===
          //     caseStatus?.proposalSuccess
          // ) {
          //   navigate(`/checkout/${response?.data?.customerLeadId}`);
          // } else
          if (
            (response?.data?.processStep === "step8" &&
              response?.data?.processDiscription === "kycAndUserDetails") ||
            (response?.data?.processStep === "step9" &&
              response?.data?.processDiscription === "nomineeDetails") ||
            (response?.data?.processStep === "step10" &&
              response?.data?.processDiscription === "vehicleDetails")
          ) {
            updateJourneyData(
              stepsData,
              response?.data?.processStep,
              response?.data?.processDiscription
            );
          } else if (
            response?.data?.processStep === "step13" &&
            response?.data?.processDiscription === "pdfGeneration"
          ) {
            const caseId = stepsData?.step13?.motorRequest?.caseId;
            for (let key in stepsData?.step13?.motorRequest) {
              dispatchMotorQuote(
                `${key}`,
                stepsData?.step13?.motorRequest[key]
              );
            }
            navigate(`/success-page/${urlCustomerLeadId}?caseId=${caseId}`);
          } else if (
            response?.data?.processStep === "step7" &&
            response?.data?.processDiscription === "quotation"
          ) {
            if (stepsData?.step12) {
              const caseId = stepsData?.step12?.motorRequest?.caseId;
              for (let key in stepsData?.step12?.motorRequest) {
                dispatchMotorQuote(
                  `${key}`,
                  stepsData?.step12?.motorRequest[key]
                );
              }
              navigate(`/checkout/${urlCustomerLeadId}`);
            } else if (stepsData?.step11) {
              const caseId = stepsData?.step11?.motorRequest?.caseId;
              for (let key in stepsData?.step11?.motorRequest) {
                dispatchMotorQuote(
                  `${key}`,
                  stepsData?.step11?.motorRequest[key]
                );
              }
              navigate(`/checkout/${urlCustomerLeadId}`);
            } else if (stepsData?.step8) {
              if (stepsData?.step7?.motorRequest?.kycStatus === "completed") {
                for (let key in stepsData?.step8?.kycAndUserDetails) {
                  dispatchMotorQuote(
                    `${key}`,
                    stepsData?.step8?.kycAndUserDetails[key]
                  );
                }
              }
              // dispatch(selectedPlanAction(stepsData?.step8?.planDetails));
              for (let key in stepsData?.step8?.motorRequest) {
                if (key === "kycStatus") continue;
                dispatchMotorQuote(
                  `${key}`,
                  stepsData?.step8?.motorRequest[key]
                );
              }
              for (let key in stepsData?.step7?.motorRequest) {
                dispatchMotorQuote(
                  `${key}`,
                  stepsData?.step7?.motorRequest[key]
                );
              }
              // navigate(`/quotelist/${urlCustomerLeadId}`);

              navigate(`/proposal/${urlCustomerLeadId}`);
            }
            // else if (stepsData?.step7) {
            //   for (let key in stepsData?.step7?.motorRequest) {
            //     dispatchMotorQuote(
            //       `${key}`,
            //       stepsData?.step7?.motorRequest[key]
            //     );
            //   }
            //   navigate(`/quotelist/${urlCustomerLeadId}`);
            // }
            // else {
            //   for (let key in stepsData?.step7?.motorRequest) {
            //     dispatchMotorQuote(
            //       `${key}`,
            //       stepsData?.step7?.motorRequest[key]
            //     );
            //   }
            //   navigate(`/quotelist/${urlCustomerLeadId}`);
            // }
          }
        })
        .catch((error) => {
          console.error("Error fetching previous journey details:", error);
        });
    }

    // if (
    //   motorRequest?.businessType === "new" ||
    //   motorRequest?.newPolicyType === POLICY_TYPE?.THIRDPARTY ||
    //   motorRequest?.isOwnerChanged
    // ) {
    //   motorQuotation(motorRequest);
    // }
  }, []);

  useEffect(() => {
    getVehiclePincode()
      .then((response) => {
        console.log("RESPONSE PINCODE DATA", response);
        if (response.status === true) {
          let i = 0;
          let arr = [];
          let arr1 = [];
          let j = 0;
          while (i < response.data.length) {
            arr.push({
              value: response.data[i].Pin_Code,
              label:
                response.data[i].Pin_Code + " - " + response.data[i].District,
              District: response.data[i].District,
              City: response.data[i].City,
              State: response.data[i].State,
            });

            i++;
          }
          while (j < response.data.length) {
            arr1.push({
              value: response.data[j].Pin_Code,
              label: response.data[j].District,
              District: response.data[j].District,
              City: response.data[j].City,
              State: response.data[j].State,
            });

            j++;
          }
          setPincodeData(arr);
          console.log("ARRAY PINCODE DATA", arr);
          setCityData(arr1);
        }
      })
      .catch((err) => console.log(err));

    getVehiclePreviousInsurer().then((response) => {
      if (response.status === true) {
        let i = 0;
        let data = response.data;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            option: item.Name,
            value: item.Digit_Code,
            insurerId: item?.prevInsurerId,
          });
          i++;
        }
        setInsurerData(arr);
      }
    });
  }, []);

  useEffect(() => {
    getFiancierBankData(selectedPlan?.insurerId, motorRequest?.vehicleType)
      .then((response) => {
        if (response.status === true) {
          let i = 0;
          let arr = [];
          while (i < response.data.length) {
            arr.push({
              value: response.data[i].financer,
              id: response.data[i].financerId,
            });

            i++;
          }
          console.log("setFinancierData", arr);
          setFinancierData(arr);
        }
      })
      .catch((err) => console.log(err));
  }, [selectedPlan?.insurerId, motorRequest?.vehicleType]);

  const updateJourneyData = (stepsData, processStep, processDiscription) => {
    try {
      console.log("stepsData?.step8?.kycConfig", stepsData?.step8?.kycConfig);
      dispatchKycConfig(stepsData?.step8?.kycConfig);
      dispatchProcessFlowMotor("step8", stepsData?.step8);

      for (let key in stepsData?.step8?.motorRequest) {
        dispatchMotorQuote(`${key}`, stepsData?.step8?.motorRequest[key]);
      }

      for (let key in stepsData?.step8?.kycAndUserDetails) {
        dispatchMotorQuote(`${key}`, stepsData?.step8?.kycAndUserDetails[key]);
      }
      processStep === "step8"
        ? dispatch(selectedPlanAction(stepsData?.step8?.planDetails))
        : processStep === "step9"
        ? dispatch(selectedPlanAction(stepsData?.step9?.planDetails))
        : processStep === "step10"
        ? dispatch(selectedPlanAction(stepsData?.step10?.planDetails))
        : dispatch(selectedPlanAction(stepsData?.step8?.planDetails));
      dispatchKycConfig(stepsData?.step8?.kycConfig);

      if (stepsData?.step8?.kycAndUserDetails?.addressLine1) {
        if (stepsData.step8.planDetails.PersonalAccident) {
          if (
            processStep === "step9" &&
            processDiscription === "nomineeDetails"
          ) {
            for (let key in stepsData?.step9?.motorRequest) {
              if (stepsData?.step9?.motorRequest.hasOwnProperty(key)) {
                dispatchMotorQuote(
                  `${key}`,
                  stepsData?.step9?.motorRequest[key]
                );
              }
            }
            for (let key in stepsData?.step9?.nomineeDetails) {
              if (stepsData?.step9?.nomineeDetails.hasOwnProperty(key)) {
                dispatchMotorQuote(
                  `${key}`,
                  stepsData?.step9?.nomineeDetails[key]
                );
              }
            }
            setCurrentStep(3);
          } else if (
            processStep === "step10" &&
            processDiscription === "vehicleDetails"
          ) {
            for (let key in stepsData?.step10?.motorRequest) {
              if (stepsData?.step10?.motorRequest.hasOwnProperty(key)) {
                dispatchMotorQuote(
                  `${key}`,
                  stepsData?.step10?.motorRequest[key]
                );
              }
            }
            for (let key in stepsData?.step10?.vehicleDetails) {
              if (stepsData?.step10?.vehicleDetails.hasOwnProperty(key)) {
                dispatchMotorQuote(
                  `${key}`,
                  stepsData?.step10?.vehicleDetails[key]
                );
              }
            }
            setCurrentStep(3);
          } else {
            setCurrentStep(2);
          }
        } else if (
          processStep === "step10" &&
          processDiscription === "vehicleDetails"
        ) {
          for (let key in stepsData?.step10?.motorRequest) {
            if (stepsData?.step10?.motorRequest.hasOwnProperty(key)) {
              dispatchMotorQuote(
                `${key}`,
                stepsData?.step10?.motorRequest[key]
              );
            }
          }
          for (let key in stepsData?.step10?.vehicleDetails) {
            if (stepsData?.step10?.vehicleDetails.hasOwnProperty(key)) {
              dispatchMotorQuote(
                `${key}`,
                stepsData?.step10?.vehicleDetails[key]
              );
            }
          }
          setCurrentStep(3);
        } else {
          setCurrentStep(3);
        }
      } else if (
        processStep === "step13" &&
        processDiscription === "pdfGeneration"
      ) {
        const caseId = stepsData?.step13?.motorRequest?.caseId;
        for (let key in stepsData?.step13?.motorRequest) {
          dispatchMotorQuote(`${key}`, stepsData?.step13?.motorRequest[key]);
        }
        navigate(`/success-page/${urlCustomerLeadId}?caseId=${caseId}`);
      }
    } catch (error) {
      console.error("Error fetching previous journey details:", error);
    }
  };

  const getPreviousPolicyName = () => {
    let index = PreviousInsurer.findIndex(
      (item) => item.Digit_Code == apiRequestQQ.PreInsurerCode
    );
    if (index > -1) {
      return PreviousInsurer[index].Name;
    } else {
      return "N/A";
    }
  };

  useEffect(() => {
    setDisabled("");
    reset3({
      RegistrationNumber: apiRequestQQ.RegistrationNumber,
      PrePolicyNumber: apiRequestQQ.PrePolicyNumber,
      PrePolicyEndDate: apiRequestQQ.PrePolicyEndDate,
      PreviousInsuranceClaimed: apiRequestQQ.PreviousInsuranceClaimed,
      PreInsurerCode: apiRequestQQ.PreInsurerCode,
      PreviousInsurancePolicyNumber: apiRequestQQ.PreviousInsurancePolicyNumber,
    });
    setRegistrationNumner(apiRequestQQ.RegistrationNumber);
    let rtoNumber = apiRequestQQ.RegistrationNumber.split("-");
    if (apiRequestQQ.IsVehicleNew) {
      setRtoCode(apiRequestQQ.RtoCode);
    } else {
      setRtoCode(rtoNumber[0] + rtoNumber[1]);
    }
    let RTOCode = apiRequestQQ.RtoCode
      ? apiRequestQQ.RtoCode
      : rtoNumber[0] + rtoNumber[1];
  }, []);

  console.log("Query", window.location.search);

  useEffect(() => {
    if (window?.location?.search) {
      // const verifyKotakApi = (requestid, tokenId) => {
      let postData = {
        requestid: apiRequestQQ.ApiId,
        ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.Kotak,
        tokenId: window.location.search,
      };
      PostDataWithToken("kyc/verify-kotak-kyc", postData).then((response) => {
        if (response.status === true) {
          console.log(response.data);
        }
      });
      // };
    }
  }, []);

  const handlePincodeChange = (val) => {
    console.log("PINCODE", val);
    // dispatchHealthQuickQuote("CustomerDetails.customerAddress", val.value);
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSelectPincodeOwner = (val) => {
    console.log("PINCODE VAL", val);
    reset1({ City: val.City, State: val.State });
    dispatchQuickQuote("Pincode", val.value);

    dispatchQuickQuote("District", val.District);
    dispatchQuickQuote("City", val.City);
    console.log("VALUE PINCODE", val.value);
    setValue("pincode", val.value);
    // getStates(val.value)
    //   .then((response) => {
    //     console.log("RESPONSE", response);
    //     if (response.status === true) {
    //       let stats = response.data[0];
    //       setStateData(response.data[0]);
    //       // reset1({ State: stats.STATEDESC });
    //       dispatchQuickQuote("State", stats.STATEDESC);
    //       dispatchQuickQuote("StateCode", stats.Digit_Code);
    //     }
    //   })
    //   .catch((err) => console.log(err));
  };

  console.log("STATE DATA", stateData);

  const handleSelectState = (val) => {
    // setState(val.label)
    dispatchQuickQuote("State", val);
    // let index = stateData.findIndex((item) => item.STATEDESC == val);
    // if (index > -1) {
    //   dispatchQuickQuote("StateCode", stateData[index].Digit_Code);
    // }
  };

  const handleChangePanValue = (value) => {
    setPan({ ...pan, number: value, error: "" });
    dispatchQuickQuote("PanNumber", value);
  };

  const handleChangeCkycValue = (value) => {
    setCkyc({ ...ckyc, number: value, error: "" });
  };

  const submitOwnerDetails = (data, e) => {
    // if (apiRequestQQ.PanImage == '') {
    //   return setPan({ ...pan, error: 'Pan Image is required' })
    // }
    setProceedSpinner(true);
    e.preventDefault();
    console.log("dataa user details", data);
    for (let key in data) {
      if (key != "KycNumber") {
        dispatchQuickQuote(key, data[key]);
      } else if (key == "KycNumber") {
        dispatchQuickQuote("KYC." + "Shriram", data[key] || "");
      }
    }
    dispatchQuickQuote("Gender", data?.customerGender);
    let postData = {
      name:
        apiRequestQQ?.FirstName +
        " " +
        apiRequestQQ?.MiddleName +
        " " +
        apiRequestQQ?.LastName,
      email: apiRequestQQ?.Email,
      phone: apiRequestQQ?.MobileNumber,
      dob: data?.Dob,
      city: data?.City,
      state: data?.State,
      address_line1: data?.StreetNumber,
      address_line2: data?.Street,
      address_line3: data?.Area,
      customerId: apiRequestQQ.customerId,
      customer_type: apiRequestQQ.CustomerType,
      pan_card: data.PanNumber,
      ckyc: data.ckyc,
      gst_no: data.GSTNo,
      pincode: apiRequestQQ.Pincode,
    };
    dispatchProcessFlowMotor("step", "step8");
    dispatchProcessFlowMotor("step8.kycAndUserDetails", postData);
    setManualKycReferenceNumber(false);
    console.log("postData", postData);
    createCustomer(postData);
    console.log(selectedPlan.Api_name);
    if (selectedPlan.Api_name == "Kotak") {
      checkKotakKycDetails(
        data.PanNumber ? data.PanNumber : apiRequestQQ.PanNumber,
        data
      );
    } else if (selectedPlan.Api_name == "Sompo") {
      checkSompoKycDetails(
        data.PanNumber ? data.PanNumber : apiRequestQQ.PanNumber,
        data,
        data?.ManualKYCReferenceNumber
      );
    } else {
      setTimeout(() => {
        apiRequestQQ.CustomerType == "INDIVIDUAL";
        const dataForFlow = {
          ...processFlowObjMotor,
          apiRequestQQ: { ...apiRequestQQ },
          step: "step8",
          step8: { ...processFlowObjMotor.step8, kycAndUserDetails: postData },
        };
        processFlow(dataForFlow);
        handleNextStep();
        setActiveStep2(true);
        setProceedSpinner(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 1000);
    }
    // setTimeout(() => {
    //   toggle(activeTab + 1);
    // }, 400);
  };

  // const submitDummyOwnerDetails = (data) => {
  //   dispatchQuickQuote("Email", data?.Email);
  //   handleNextStep();
  //   setActiveStep2(true);
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };

  const checkKotakKycDetails = (pannumber, formData) => {
    setDisabled("disabled");
    let postData = {
      ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.Kotak,
      quotationNumber: apiRequestQQ.quotationNumber,
      PanNumber: pannumber,
      DOB: formData.Dob,
      FirstName: formData.FirstName,
      MiddleName: formData.MiddleName,
      LastName: formData.LastName,
      CINNumber: formData.CINNumber,

      ProposalNumber: "",
      ClientCallBackURL: "https://insurefast.in/proposal",
      requestId: apiRequestQQ.ApiId,
      Gender: formData.Gender === "Male" ? "M" : "F",
      CustomerType: apiRequestQQ.CustomerType === "INDIVIDUAL" ? "I" : "O",
    };
    PostDataWithToken("kyc/kotak-kyc", postData)
      .then((response) => {
        console.log("RESPONSE KOTAK KYC", response);
        if (response.status === true) {
          if (
            response.data.KYCNumber &&
            response.data.KYCStatus == "CKYCSuccess"
          ) {
            setDisabled("");

            sendSuccessMessageKYC(response);

            dispatchQuickQuote("KYC." + "Kotak", response.data.KYCNumber);
            const {
              KYCCorAdd1,
              KYCCorAdd2,
              KYCFirstName,
              KYCLastName,
              KYCMiddleName,
              KYCCorAddCity,
              KYCCorAddCountry,
              KYCCorAddDistrict,
              KYCCorAddPin,
              KYCCorAddState,
              KYCDOB,
              KYCEmailAdd,
              KYCFullName,
              KYCIDProofNumber,
              KYCPerAddStateCode,
              KYCPerAdd1,
              KYCPAN,
            } = response.data;
            KYCFirstName && dispatchQuickQuote("FirstName", KYCFirstName);
            KYCLastName && dispatchQuickQuote("LastName", KYCLastName);
            KYCMiddleName && dispatchQuickQuote("MiddleName", KYCMiddleName);
            // KYCDOB && dispatchQuickQuote("Dob", KYCDOB);
            KYCPAN && dispatchQuickQuote("PanNumber", KYCPAN);
            // KYCCorAdd1 && dispatchQuickQuote("StreetNumber", KYCCorAdd1);
            // KYCCorAdd2 && dispatchQuickQuote("Street", KYCCorAdd2);
            // KYCEmailAdd && dispatchQuickQuote("Email", KYCEmailAdd);
            // KYCCorAddState && dispatchQuickQuote("State", KYCCorAddState);
            // KYCCorAddCity && dispatchQuickQuote("City", KYCCorAddCity);
            // KYCCorAddPin && dispatchQuickQuote("Pincode", KYCCorAddPin);
            // KYCPerAdd1 && dispatchQuickQuote("PermanentAddress", KYCPerAdd1);
            // KYCCorAddCountry && dispatchQuickQuote("Country", KYCCorAddCountry);
            // KYCCorAddDistrict && dispatchQuickQuote("District", KYCCorAddDistrict);
            // KYCPerAddStateCode && dispatchQuickQuote("StateCode", KYCPerAddStateCode);
            // verifyKotakApi(apiRequestQQ.ApiId, response.data.TokenId);
            // apiRequestQQ.CustomerType == "INDIVIDUAL"
            //   ? toggle(activeTab + 1)
            //   : toggle(activeTab + 2);
            verifyKotakApi(apiRequestQQ.ApiId, response.data.TokenId);
            const dataForFlow = {
              ...processFlowObjMotor,
              apiRequestQQ: { ...apiRequestQQ },
              step: "step8",
              // step8: { ...processFlowObjMotor.step8, kycAndUserDetails: postData },
            };
            processFlow(dataForFlow);
            handleNextStep();
            setActiveStep2(true);
            setProceedSpinner(false);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          } else if (response.data.RequestURL) {
            alert("You have to complete your Kotak E-kyc first");
            window.location.href = response.data.RequestURL;
            setDisabled("");
          } else {
            setDisabled("");
            alert(
              response.data?.ExceptionErrorMsg
                ? response.data?.ExceptionErrorMsg
                : "Please try again"
            );
          }
        }
      })
      .catch((err) => setDisabled(""));
  };

  const verifyKotakApi = (requestid, tokenId) => {
    let postData = {
      requestid: requestid,
      tokenId: tokenId,
    };
    PostDataWithToken("kyc/verify-kotak-kyc", postData).then((response) => {
      if (response.status === true) {
        console.log(response.data);
      }
    });
  };

  const checkSompoKycDetails = (pannumber, formData, refNumber) => {
    setDisabled("disabled");
    let postData = {
      CustomerType: apiRequestQQ.CustomerType === "INDIVIDUAL" ? "I" : "O",
      UniqueId: apiRequestQQ.ApiId,
      // PanNumber: refNumber ? null : pannumber,
      customerKYCIDType: refNumber ? "ManualKycReferenceNumber" : "PanNumber",
      customerKYCIDNumber: refNumber ? refNumber : pannumber,
      Dob: formData.Dob,
      MobileNumber: formData.MobileNo,
      Pincode: formData.pinCode,
    };
    PostDataWithToken("kyc/verify-sompo-kyc", postData)
      .then((response) => {
        // debugger;
        if (
          response?.data?.statusCode === "200" &&
          response?.data?.status === "success"
        ) {
          dispatchQuickQuote("KYC.Sompo", response?.data?.result?.ckycNo);
          dispatchQuickQuote(
            "ProposalId",
            response?.data?.uniqueTransactionNumber
          );
          setProceedSpinner(false);
          handleNextStep();
          window.scrollTo({
            top: "0",
            behavior: "smooth",
          });
        } else {
          setProceedSpinner(false);
          setManualKycReferenceNumber(true);
          window.open(response?.data?.result?.manualKYCurl, "_blank");
        }
      })
      .catch((err) => setDisabled(""));
  };

  // Step 2
  const submitNomineeDetails = (data) => {
    let postData = {
      name: apiRequestQQ?.FirstName + " " + apiRequestQQ?.LastName,
      email: apiRequestQQ.Email,
      phone: apiRequestQQ.MobileNumber,
      dob: apiRequestQQ.Dob,
      city: apiRequestQQ.City,
      state: apiRequestQQ.State,
      address:
        apiRequestQQ.StreetNumber +
        " " +
        apiRequestQQ.Street +
        " " +
        apiRequestQQ.Area,
      pincode: apiRequestQQ.Pincode,
      gender: apiRequestQQ.Gender,
      nominee_name: data.NomineeFirstName + " " + data.NomineeLastName,
      nominee_relation: data.NomineeRelationship,
      nominee_age: moment().diff(data.NomineeDateOfBirth, "years"),
    };
    dispatchProcessFlowMotor("step", "step9");
    dispatchProcessFlowMotor("step9.nomineeDetails", postData);

    let kycObj = apiRequestQQ.KYC;
    let kyc_no;

    for (let key in kycObj) {
      if (kycObj[key] != "") {
        kyc_no = kycObj[key];
      }
    }
    let kyc_ref = apiRequestQQ.KYC.RoyalRefNo;
    postData.kyc_no = kyc_no;
    postData.kyc_ref = kyc_ref;
    if (apiRequestQQ.customerId) {
      postData.customerId = apiRequestQQ.customerId;
    }

    createCustomer(postData);
    if (apiRequestQQ.customerId) {
      postData.customerId = apiRequestQQ.customerId;
    }
    for (let key in data) {
      dispatchQuickQuote(key, data[key]);
    }
    const newData = {
      ...processFlowObjMotor,
      apiRequestQQ: { ...apiRequestQQ },
      step: "step9",
      step9: { ...processFlowObjMotor.step9, nomineeDetails: postData },
    };

    processFlow(newData);
    handleNextStep();
    setActiveStep3(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // STEP 3
  const submitVehicleDetails = (data) => {
    // debugger;
    const vehicleData = { ...data };

    dispatchProcessFlowMotor("step", "step10");
    dispatchProcessFlowMotor("step10.vehicleDetails", vehicleData);

    if (selectedPlan.Api_name == "Reliance" && isValidPUC == false) {
      return sendErrorInfo("Please select valid PUC");
    }
    dispatchQuickQuote("RegistrationNumber", RegistrationNumber);
    for (let key in data) {
      dispatchQuickQuote(key, data[key]);
    }
    let postData = {
      name: apiRequestQQ?.FirstName + " " + apiRequestQQ?.LastName,
      email: apiRequestQQ.Email,
      phone: apiRequestQQ.MobileNumber,
      dob: apiRequestQQ.Dob,
      city: apiRequestQQ.City,
      state: apiRequestQQ.State,
      address:
        apiRequestQQ.StreetNumber +
        " " +
        apiRequestQQ.Street +
        " " +
        apiRequestQQ.Area,
      pincode: apiRequestQQ.Pincode,
      gender: apiRequestQQ.Gender,
      nominee_name: data.NomineeFirstName + " " + data.NomineeLastName,
      nominee_relation: data.NomineeRelationship,
      nominee_age: moment().diff(data.NomineeDateOfBirth, "years"),
      RegistrationNumber: data?.RegistrationNumber,
      PrePolicyNumber: data?.PrePolicyNumber,
      PrePolicyEndDate: data?.PrePolicyEndDate,
      PreviousInsuranceClaimed: data?.PreviousInsuranceClaimed,
      PreInsurerCode: data?.PreInsurerCode,
      PreviousInsurancePolicyNumber: data?.PreviousInsurancePolicyNumber,
      EngineNumber: data?.EngineNumber,
      ChassisNumber: data?.ChassisNumber,
    };

    createCustomer(postData);
    const newData = { ...apiRequestQQ, ...data };
    setDisabled("disabled");
    if (selectedPlan.Api_name === "Kotak") {
      setVehicleDetailsSpinner(true);

      kotakIssueApi(newData)
        .then((response) => {
          // debugger;

          if (response.status === true) {
            if (
              response.data.Fn_Save_Partner_Private_Car_ProposalResult
                .vErrorMessage === "Success"
            ) {
              // alert("kotak success");
              let data = { ...selectedPlan };
              data.vProposalNumber =
                response.data.Fn_Save_Partner_Private_Car_ProposalResult.vProposalNumber;
              data.vProductCode =
                response.data.Fn_Save_Partner_Private_Car_ProposalResult.vProductCode;
              data.vPolicyNumber =
                response.data.Fn_Save_Partner_Private_Car_ProposalResult.vPolicyNumber;
              dispatch(selectedPlanAction(data));
              dispatchProcessFlowMotor("step10.planDetails", data);
              const dataForFlow = {
                ...processFlowObjMotor,
                apiRequestQQ: { ...apiRequestQQ },
                step: "step10",
                step10: {
                  ...processFlowObjMotor.step10,
                  vehicleDetails: vehicleData,
                  planDetails: data,
                },
              };
              if (data?.vProposalNumber === false) {
                // setLoading(true);
              } else {
                // setLoading(false);
                processFlow(dataForFlow);
                setVehicleDetailsSpinner(false);
                navigate("/checkout");
              }
            } else {
              alert("Something went wrong, Try Again");
              // navigate("/");
              setDisabled("");
            }
          } else {
            setDisabled("");
          }
        })
        .catch((err) => {
          console.log(err);
          setDisabled("");
        });
    }
    // else if (selectedPlan.Api_name === "Sompo") {
    //   console.log("data for sompo create quote", newData);
    //   sompoCreateQuote(newData)
    //     .then((response) => {
    //       console.log("SOMP CREATE QUOTE RESPONSE", response);
    //       const data = response?.root;
    //       console.log("DATAAAAAAAA", data);
    //       if (
    //         data.Product.PremiumCalculation.TotalPremium._attributes.Value !==
    //         ""
    //       ) {
    //         let newData = {
    //           ...selectedPlan,
    //           sompoPolicyNo: data?.Customer?.PosPolicyNo?._text,
    //         };
    //         console.log("SELECTED PLAM SOMPO", newData);
    //         console.log("SOMPO POLICY");
    //         dispatch(selectedPlanAction(newData));
    //         dispatchProcessFlowMotor("step10.planDetails", newData);
    //         const dataForFlow = {
    //           ...processFlowObjMotor,
    //           step: "step10",
    //           step10: {
    //             ...processFlowObjMotor.step10,
    //             vehicleDetails: vehicleData,
    //             planDetails: newData,
    //           },
    //         };

    //         if (newData?.sompoPolicyNo === false) {
    //           // setLoading(true);
    //         } else {
    //           // setLoading(false);
    //           processFlow({ processFlow: dataForFlow });
    //           navigate("/checkout");
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       setDisabled("");
    //     });
    // }
    else {
      const dataForFlow = {
        ...processFlowObjMotor,
        apiRequestQQ: { ...apiRequestQQ },
        step: "step10",
        step10: { ...processFlowObjMotor.step10, vehicleDetails: data },
      };
      processFlow(dataForFlow);
      navigate("/checkout");
    }
  };

  const setRegistrationValue = (val) => {
    let number = "";
    let stateCode = val.substring(3, 5);
    let rtoCode = val.substring(6, 8);
    let plateNumber = val.substring(9, 13);
    if (val.length <= 2) {
      if (val.match(/^[A-Za-z]+$/)) {
        number = val;
        if (val.length === 2) {
          number = number + "-";
        }
      } else {
        number = "";
      }
    } else if (val.length > 3 && val.length <= 5) {
      if (stateCode.match(/^[0-9]+$/)) {
        if (val.length > 3 && val.length < 5) {
          number = RegistrationNumber + stateCode;
        }
        if (val.length === 5) {
          number = RegistrationNumber + val.charAt(4) + "-";
        }
      } else {
        stateCode = "";
        number = RegistrationNumber + stateCode;
      }
    }
    if (val.length > 6 && val.length <= 8) {
      if (rtoCode.match(/^[A-Za-z]+$/)) {
        number = val;
        if (val.length === 8) {
          number = number + "-";
        }
      } else {
        number = "";
      }
    } else if (val.length > 9 && val.length <= 14) {
      if (plateNumber.match(/^[0-9]+$/)) {
        number = val;
      }
    }
    setRegistrationNumner(number);
  };

  const selectRtoCity = (val) => {
    dispatchQuickQuote("RtoCode", val.value);
  };

  const handleSelectPincode = (val) => {
    dispatchQuickQuote("RelianceRTOCode", val.value);
    setSelRelianceRTO(val.value);
  };

  const changeHpnState = (e) => {
    const { checked, value } = e.target;

    dispatchQuickQuote("IsHypothecation", checked);
    dispatchMotorQuote("isHypothecation", checked);
    setHpn(checked);
  };

  const changePUCState = (e) => {
    const { checked, value } = e.target;
    dispatchQuickQuote("IsValidPuc", checked);
    dispatchMotorQuote("isValidPuc", checked);
    setIsValidPUC(checked);
  };

  const handleEngineNumber = (val) => {
    dispatchQuickQuote("EngineNumber", val);
  };

  const handleChassisNumber = (val) => {
    dispatchQuickQuote("ChassisNumber", val);
  };

  const handlePrePolicyNumber = (val) => {
    val = val.toUpperCase();

    dispatchQuickQuote("PrePolicyNumber", val);
  };

  const handleSetHpnBank = (val) => {
    dispatchQuickQuote("HpnBank", val.value);
  };

  const handleSetHpnCity = (val) => {
    dispatchQuickQuote("HpnCity", val.City);
    dispatchMotorQuote("hpnCity", val.City);
  };

  const handleInputBank = (event) => {
    const input = event.target.value;

    setUserInputBank(input);

    dispatchQuickQuote("HpnBank", input);

    const filteredBankName = financierData.filter((name) =>
      name.value.toLowerCase().includes(input.toLowerCase())
    );

    setSuggestions(filteredBankName);
  };

  const handleBankSelection = (selectedBank) => {
    setUserInputBank(selectedBank);

    dispatchQuickQuote("HpnBank", selectedBank);
    dispatchMotorQuote("hpnBank", selectedBank);
    setSuggestions([]);
  };

  const [paymentPage, setPaymentPage] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const nomineeRelationship = watch2("NomineeRelationship");
    if (
      nomineeRelationship === "FATHER" ||
      nomineeRelationship === "BROTHER" ||
      nomineeRelationship === "SON"
    ) {
      setValue2("NomineeGender", "Male");
    } else if (
      nomineeRelationship === "MOTHER" ||
      nomineeRelationship === "SISTER" ||
      nomineeRelationship === "DAUGHTER"
    ) {
      setValue2("NomineeGender", "Female");
    }
  }, [watch2("NomineeRelationship")]);

  return (
    <Layout>
      <div className="main-owner-page">
        {/* <div class="container-div-1">
          <div className="d-flex align-items-center">
            <img
              src={
                healthRequestQQ?.state === "health" &&
                healthRequestQQ?.enquiryId !== ""
                  ? "/assets/images/cardiogram.png"
                  : apiRequestQQ?.RegistrationNumber !== "" &&
                    apiRequestQQ?.state === "Pvt Car"
                  ? "/assets/images/car.png"
                  : apiRequestQQ?.RegistrationNumber !== "" &&
                    apiRequestQQ?.state === "MotorBike"
                  ? "/assets/images/bycicle 1.png"
                  : ""
              }
              alt=""
            />
            <h2>
              {healthRequestQQ?.state === "health" &&
              healthRequestQQ?.enquiryId !== ""
                ? healthRequestQQ?.CustomerDetails?.customerFirstName
                : apiRequestQQ?.RegistrationNumber !== "" &&
                  apiRequestQQ?.state === "Pvt Car"
                ? apiRequestQQ?.RegistrationNumber
                : apiRequestQQ?.RegistrationNumber !== "" &&
                  apiRequestQQ?.state === "MotorBike"
                ? apiRequestQQ?.RegistrationNumber
                : ""}
            </h2>
          </div>
          <p className="fs-6">
            {healthRequestQQ?.state === "health" &&
            healthRequestQQ?.enquiryId !== ""
              ? healthRequestQQ?.CustomerDetails?.customerRelation
              : apiRequestQQ?.RegistrationNumber !== "" &&
                apiRequestQQ?.state === "Pvt Car"
              ? "HYUNDAI GRAND i10"
              : apiRequestQQ?.RegistrationNumber !== "" &&
                apiRequestQQ?.state === "MotorBike"
              ? "HONDA CB SHINE SP125"
              : ""}
            {healthRequestQQ?.state === "health" &&
            healthRequestQQ?.enquiryId !== "" ? (
              <>
                <img className="px-2" src="/assets/images/Ellipse 232.png" />
                <span>
                  {" "}
                  {healthRequestQQ?.CustomerDetails?.customerAge} Years
                </span>
              </>
            ) : apiRequestQQ?.RegistrationNumber !== "" &&
              apiRequestQQ?.state === "Pvt Car" ? (
              <>
                <span> 2018</span>
                <img className="px-2" src="/assets/images/Ellipse 232.png" />
                <span> Petrol</span>
              </>
            ) : apiRequestQQ?.RegistrationNumber !== "" &&
              apiRequestQQ?.state === "MotorBike" ? (
              <>
                <span> 2017</span>
                <img className="px-2" src="/assets/images/Ellipse 232.png" />
                <span> Petrol</span>
              </>
            ) : (
              ""
            )}
          </p>
        </div> */}
        <div className="main-vehicle-owner-outer">
          <div className="main-vehicle-owner ">
            <div className="vehicle-owner-details">
              <div className="vehicle-owner">
                <div className="owmer-div-1">
                  <div className="owner-img active">
                    <img src="/assets/images/owner-img.png" alt="" />
                  </div>
                  <div className="owner-text active-text">
                    <h2>Owner Details</h2>
                  </div>
                </div>
                {selectedPlan?.PersonalAccident && (
                  <div className="owmer-div-1">
                    <div
                      className={`owner-img ${
                        activeStep2 === true ? "active" : ""
                      }`}
                    >
                      <img
                        src={
                          activeStep2
                            ? "/assets/images/nomination (1).png"
                            : "/assets/images/nomination black (1).png"
                        }
                        alt=""
                      />
                    </div>
                    <div
                      className={`owner-text ${
                        activeStep2 === true ? "active-text" : ""
                      }`}
                    >
                      <h2>Nominee Details</h2>
                    </div>
                  </div>
                )}
                <div className="owmer-div-1">
                  <div
                    className={`owner-img ${
                      activeStep3 === true ? "active" : ""
                    }`}
                  >
                    <img
                      src={
                        motorRequest?.vehicleType === "4w" &&
                        activeStep3 === true
                          ? `/assets/images/car icon white.png`
                          : motorRequest?.vehicleType === "2w" &&
                            activeStep3 === true
                          ? `/assets/images/bike icon white.png`
                          : motorRequest?.vehicleType === "4w"
                          ? `/assets/images/car icon black.png`
                          : motorRequest?.vehicleType === "2w"
                          ? "/assets/images/owner-bike-img.png"
                          : ""
                      }
                      alt=""
                    />
                  </div>
                  <div
                    className={`owner-text ${
                      activeStep3 === true ? "active-text" : ""
                    }`}
                  >
                    <h2>Vehicle Details</h2>
                  </div>
                </div>
              </div>
              {/* Step 1 */}
              {currentStep === 1 &&
                (kycConfig?.kycConfig?.kycStep === "in_proposal" ||
                kycConfig?.kycConfig?.kycStep === "before_proposal" ? (
                  <KYC
                    setActiveStep2={setActiveStep2}
                    setActiveStep3={setActiveStep3}
                    setCurrentStep={setCurrentStep}
                    kycStatusCheck={
                      extractCustomerLeadId
                        ? true
                        : motorRequest?.customerLeadId
                        ? true
                        : location?.state?.kycStatus
                    }
                    kycMode={kycMode}
                    setKycMode={setKycMode}
                  />
                ) : motorRequest?.customer === CUSTOMER_TYPE.ORGANISATION ? (
                  <OwnerDetailsOrganization
                    pincodeData={pincodeData}
                    proceedSpinner={proceedSpinner}
                    handleSelectPincodeOwner={handleSelectPincodeOwner}
                    setProceedSpinner={setProceedSpinner}
                    handleNextStep={handleNextStep}
                    setActiveStep2={setActiveStep2}
                  />
                ) : null)}

              {currentStep === 2 &&
                (selectedPlan?.Api_name !== ("digit" && "Kotak") ? (
                  <NomineeDetails
                    setCurrentStep={setCurrentStep}
                    setActiveStep3={setActiveStep3}
                    handlePreviousStep={handlePreviousStep}
                  />
                ) : null)}
              {/* Next page */}
              {currentStep === 3 &&
                (selectedPlan?.Api_name !== ("digit" && "Kotak") ? (
                  <VehicleDetails
                    insurerData={insurerData}
                    Hpn={Hpn}
                    isValidPUC={isValidPUC}
                    changeHpnState={changeHpnState}
                    userInputBank={userInputBank}
                    handleInputBank={handleInputBank}
                    suggestions={suggestions}
                    cityData={cityData}
                    handleBankSelection={handleBankSelection}
                    handleSetHpnCity={handleSetHpnCity}
                    changePUCState={changePUCState}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    financierData={financierData}
                    kycMode={kycMode}
                    setKycMode={setKycMode}
                  />
                ) : null)}
            </div>
            <div className="plan-summary">
              <div className="plan-details">
                <div className="summary-heading">
                  <h4 className="text-center">Your Plan Summary</h4>
                </div>
                <div className="summary-details">
                  <div className="summary-img">
                    <img src={selectedPlan?.Logo} alt="" />
                  </div>

                  <div className="plan-type">
                    <div className="idv">
                      <h3>Plan Type</h3>
                      <h3>
                        {`${
                          selectedPlan?.policyType ===
                          POLICY_TYPE?.COMPREHENSIVE
                            ? "Comprehensive"
                            : selectedPlan?.policyType ===
                              POLICY_TYPE?.THIRDPARTY
                            ? "Third Party"
                            : selectedPlan?.policyType === POLICY_TYPE?.ODONLY
                            ? "Own Damage"
                            : selectedPlan?.policyType
                        }`}
                      </h3>
                    </div>
                    <div className="idv">
                      <h3>Net Premium</h3>
                      <h5>
                        ₹
                        {typeof selectedPlan?.NetPremium === "string"
                          ? Math.round(
                              selectedPlan?.NetPremium.replace("INR", "")
                            )
                          : Math.round(selectedPlan?.NetPremium)}
                      </h5>
                    </div>
                    <div className="idv">
                      <h3>Final Premium</h3>
                      <h5>
                        ₹
                        {typeof selectedPlan?.FinalPremium === "string"
                          ? Math.round(
                              selectedPlan?.FinalPremium.replace("INR", "")
                            )
                          : Math.round(selectedPlan?.FinalPremium)}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              {/* Vehicle Details */}
              {/* <div className="plan-details mt-4">
                <div className="summary-heading">
                  <h4 className="text-center">Vehicle Details</h4>
                </div>
                <div className="summary-details">
                  <div className="plan-type">
                    <div className="idv">
                      <h3>Registration Number</h3>
                      <h5 className="fs-6">
                        {formatRegistrationNumber(
                          motorRequest?.registrationNumber
                        )}
                      </h5>
                    </div>
                    <div className="idv">
                      <h3>Vehicle Details</h3>
                      <h5 className="text-end fs-6">
                        {`${motorRequest?.makeName} ${motorRequest?.modelName}`}
                        <br />
                        {`${motorRequest?.variantName}`}
                      </h5>
                    </div>
                  </div>
                </div>
              </div> */}
              {policyOverview === false ? (
                <div>
                  {/* <div className="summary-card">
                    <img
                      src="/assets/images/od policy tamplate copy.jpg"
                      alt=""
                    />
                    <img
                      src="/assets/images/health insu tamplate copy.jpg"
                      alt=""
                    />
                  </div> */}
                </div>
              ) : (
                <>
                  <div>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Policy Details
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          class="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Insurer</strong>
                              </p>
                              <p>{selectedPlan?.Company}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Plan Type</strong>
                              </p>
                              <p>{selectedPlan?.planType}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Sum Insured Value</strong>
                              </p>
                              <p>{selectedPlan?.sumInsuredValue}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Monthly Premium Amount</strong>
                              </p>
                              <p>
                                {selectedPlan?.PremiumAmount?.premiumPerMonth}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Annual Premium Amount</strong>
                              </p>
                              <p>
                                {selectedPlan?.PremiumAmount?.premiumPerYear}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Policy Holder Details
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Name</strong>
                              </p>
                              <p>
                                {
                                  apiRequestQQ?.CustomerDetails
                                    ?.customerFirstName
                                }{" "}
                                {
                                  apiRequestQQ?.CustomerDetails
                                    ?.customerMiddleName
                                }{" "}
                                {
                                  apiRequestQQ?.CustomerDetails
                                    ?.customerLastName
                                }
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Age</strong>
                              </p>
                              <p>
                                {apiRequestQQ?.CustomerDetails?.customerAge}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Mobile Number</strong>
                              </p>
                              <p>
                                {
                                  apiRequestQQ?.CustomerDetails
                                    ?.customerMobileNumber
                                }
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Email</strong>
                              </p>
                              <p>
                                {
                                  apiRequestQQ?.CustomerDetails
                                    ?.customerEmailAddress
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Nominee Details
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Nominee Name</strong>
                              </p>
                              <p>{apiRequestQQ?.NomineeDetails?.NomineeName}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="">
                                <strong>Nominee Relation</strong>
                              </p>
                              <p>
                                {apiRequestQQ?.NomineeDetails?.NomineeRelation}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="paymentBtn"
                    onClick={() => handlePayment()}
                  >
                    Proceed to Payment
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modal} size="lg">
        <ModalHeader>Payment Gateway</ModalHeader>
        <ModalBody>
          <Link to={paymentPage}></Link>
        </ModalBody>
      </Modal>
    </Layout>
  );
};

export default ProposalForm;
