import { debounce } from "lodash";
import React, {
  Component,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Select from "react-select";
import { FixedSizeList as List } from "react-window";

const ReactSelect = ({
  options,
  name,
  placeholder,
  defaultValue,
  onChange = () => {},
}) => {
  const [inputValue, setInputValue] = useState("");
  const memoizedOptions = useMemo(() => options, [options]);
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const debouncedOnChange = useMemo(() => debounce(onChange, 300), [onChange]);

  const customFilterOption = useCallback(
    ({ label }, searchInput) =>
      label.toLowerCase().includes(searchInput.toLowerCase()),
    []
  );

  const getDefaultOptions = () => {
    return options.find((option) => option.value === defaultValue || null);
  };

  useEffect(() => {
    console.log("DEFAULT VALUE PINCODE", defaultValue);
    setSelectedValue(getDefaultOptions());
  }, [defaultValue, options]);

  // Handle input change separately to reduce unnecessary renders
  const handleInputChange = (value) => {
    // Remove special characters (only alphanumeric and space are allowed)
    const sanitizedValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
    setInputValue(sanitizedValue);
  };

  const handleChange = (selectedOption) => {
    console.log("selectedOption", selectedOption);

    // Avoid unnecessary updates if the selected value is the same as the current value
    if (!selectedOption || selectedOption.value !== selectedValue?.value) {
      setSelectedValue(selectedOption); // Update selected value
      debouncedOnChange(selectedOption); // Call onChange with debounced value
    }
  };

  return (
    <div>
      <Select
        styles={{
          control: (base) => ({
            ...base,
            width: "100%",
            height: "50px",
            borderRadius: "7px",
            border: "1px solid #56b96c",
          }),
        }}
        isSearchable
        autosize={true}
        options={memoizedOptions}
        components={{ MenuList, IndicatorSeparator: () => null }}
        onInputChange={handleInputChange} // Debounced input change
        inputValue={inputValue}
        filterOption={customFilterOption}
        onChange={handleChange} // Call onChange directly
        name={name}
        placeholder={placeholder}
        value={selectedValue}
      />
    </div>
  );
};

export default ReactSelect;

const height = 35;

const MenuList = React.memo(({ options, children, maxHeight, getValue }) => {
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
});
