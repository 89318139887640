import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Col, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { GetDataWithToken, PostDataWithToken } from "../../api/apiHelper";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchProcessFlowMotor,
  dispatchQuickQuote,
  dispatchUserDetails,
  resetQuickQuoteResults,
  resetUserDetails,
} from "../../store/action/userActions";
import {
  failedUpdateMsg,
  sendSaveUserMessage,
} from "../services/masterServices";
import { FaFemale, FaHome } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
// import { MdOutlinePolicy, MdPolicy } from "react-icons/md";
import { MdOutlineLocalPolice } from "react-icons/md";
import { MdPolicy } from "react-icons/md";
import { LuBaggageClaim } from "react-icons/lu";

import { AiOutlineTransaction } from "react-icons/ai";
import { AiOutlineLogout } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import LogoutModal from "./LogoutModal";
import { MDBDataTable, MDBIcon } from "mdbreact";
import { getPreviousUserDetails } from "../services/TPApiCall.js";
import DatePicker from "react-datepicker";
import ReactInputMask from "react-input-mask";
import CryptoJS from "crypto-js";
import ReactPaginate from "react-paginate";
import { PageSizes } from "pdf-lib";

const MyProfile = () => {
  const [avatar, setAvatar] = useState();
  const userDetails = useSelector((state) => state?.root?.userDetails);
  const userData = useSelector((state) => state?.root?.userData);
  console.log("userData", userDetails);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mySideBar, setSideBar] = useState("myProfile");
  const location = useLocation();
  const [sideNav, setSideNav] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [customerPolicyData, setCustomerPolicyData] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [defaultDate, setDefaultDate] = useState(null);
  const [customerQuotationData, setCustomerQuotationData] = useState([]);
  const [stepsData, setStepsData] = useState({});
  const [dob, setDob] = useState(defaultDate ? new Date(defaultDate) : null);
  console.log("LOCATION", location);
  const [totalPage, setTotalPage] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10); // Default entries per page
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    console.log("pagesizw", currentPage);
  }, [currentPage]);
  useEffect(() => {
    if (location?.state === null) {
      setSideBar("myProfile");
    } else {
      setSideBar(location?.state);
    }
  }, []);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    reset,
    watch,
    clearErrors,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    setValue("phone", userDetails?.Phone || userDetails?.mobile_no);
    setValue("email", userDetails?.Email || userDetails?.email);
    setValue("name", userDetails?.FullName || userDetails?.name);
    setValue("gender", userDetails?.Gender || userDetails?.gender);
  }, []);

  function formatDate(inputDate) {
    if (!inputDate) return "Invalid Date"; // Handle null/undefined
    const date = moment(
      inputDate,
      ["YYYY-MM-DD", "MM/DD/YYYY", "YYYY/MM/DD", "DD/MM/YYYY"],
      true
    );
    if (date.isValid()) {
      return date.format("YYYY-MM-DD");
    } else {
      return "Invalid Date";
    }
  }

  useEffect(() => {
    console.log("userDetails?.DateofBirth", userDetails?.DateofBirth);
    const formattedDate = formatDate(
      userDetails?.DateofBirth || userDetails?.dob
    );
    console.log("formattedDate", formattedDate);
    setDefaultDate(formattedDate !== "Invalid Date" ? formattedDate : "");
    setDob(formattedDate !== "Invalid Date" ? formattedDate : "");
  }, [userDetails]);
  useEffect(() => {
    const fetchCustomerPolicyData = async () => {
      let userName = userDetails?.username ? "employeeId" : "customerId";
      console.log("userName", userName);

      if (mySideBar === "myPolicies") {
        let obj = {
          [userName]:
            userDetails?.CustomerUniqueNumber || userDetails?.username,
          // customerId: "1452036243563"
        };
        try {
          const response = await PostDataWithToken("motor/getBookedCase", obj);

          console.log("customerPolicy Response", response);
          console.log("customerPolicy Response", response?.data?.length);
          // handleSetDataTable(response.data);

          if (response?.data?.length !== 0) {
            setCustomerPolicyData(response?.data);
          }
        } catch (error) {
          console.log("Error fetching customer policy:", error);
        }
      }
    };
    fetchCustomerPolicyData();
  }, [mySideBar === "myPolicies"]);

  const fetchCustomerQuotationData = async () => {
    if (mySideBar === "myQuotations") {
      const data = {
        pageSize: itemsPerPage,
        pageNo: currentPage + 1,
      };

      try {
        let res;
        // Check if CustomerUniqueNumber is available and send it as customerId
        if (userDetails?.CustomerUniqueNumber) {
          const customerData = {
            ...data,
            customerId: userDetails?.CustomerUniqueNumber,
          };
          res = await PostDataWithToken(
            "admin/getCustomerJourney",
            customerData
          );
          console.log("Customer Journey Response", res);
          if (res?.data?.length !== 0) {
            setCustomerQuotationData(res?.data?.userJourney);
            setTotalPage(res?.data?.totalMotorCaseCount || 0);
            console.log("setCustomerQuotationData", res?.data);
          }
        }

        // setTotalPage(response.data.totalItems || 0);
        // handleSetQuotationDataTable(response.data);
        // setTotalItems(response.data.totalMotorCaseCount || 0);

        // Check if username is available and send it as EmployeeId
        if (userDetails?.username) {
          const employeeData = { ...data, employeeId: userDetails?.username };
          const response = await PostDataWithToken(
            "admin/getEmployeeJourney",
            employeeData
          );
          console.log("Employee Journey Response", response);
          if (response?.data?.length !== 0) {
            setCustomerQuotationData(response?.data?.userJourney);
            setTotalPage(response?.data?.totalMotorCaseCount || 0);
            console.log("setEmployeeQuotationData", response?.data);
          }
        }
      } catch (error) {
        console.log("Error fetching quotation data:", error);
      }
    }
  };

  useEffect(() => {
    fetchCustomerQuotationData();
  }, [mySideBar === "myQuotations"]);

  const decryptAes256Text = (encrypted, key) => {
    // Decode base64 encoded string to a WordArray
    const input = CryptoJS.enc.Base64.parse(encrypted);

    // Extract the IV (first 16 bytes) and the ciphertext (remaining bytes)
    const iv = CryptoJS.lib.WordArray.create(input.words.slice(0, 4), 16); // First 16 bytes (128 bits)
    const ciphertext = CryptoJS.lib.WordArray.create(
      input.words.slice(4),
      input.sigBytes - 16
    ); // Remaining bytes

    // Hash the key using SHA-256
    const hashedKey = CryptoJS.SHA256(key);

    // Decrypt the ciphertext using the hashed key and IV
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: ciphertext },
      hashedKey,
      {
        iv: iv,
        mode: CryptoJS.mode.CTR,
        padding: CryptoJS.pad.NoPadding, // NoPadding because CTR mode doesn't require padding
      }
    );

    // Convert decrypted WordArray to UTF-8 string
    return CryptoJS.enc.Utf8.stringify(decrypted);
  };
  const handlePdfDownload = (data) => {
    const pdf = decryptAes256Text(data, process.env.REACT_APP_DECRYPTION_KEY); // Example: replace "decrepit" with "accepted"
    console.log("Updated URL:", pdf);

    // Open the updated URL in a new tab
    window.open(pdf, "_blank");

    // If you need to handle the file further (e.g., after download), you can add logic here.
  };

  const handleContinueJourney = () => {
    console.log("handleContinueJourney", stepsData);
    const stepData = stepsData?.processFlow;
    const requestData = { ...stepData.apiRequestQQ };

    // const ApiUniqueNumber = { ...stepData.apiRequestQQ.ApiUniqueNumber };
    // const KYC = { ...stepData.apiRequestQQ.KYC };
    // const VariantCode = { ...stepData.apiRequestQQ.VariantCode };
    console.log("handleContinueJourney", stepData, stepData?.apiRequestQQ);
    // console.log("handleContinueJourney", requestData);

    // console.log("handleContinueJourney", ApiUniqueNumber);
    // console.log("handleContinueJourney", KYC);
    // console.log("handleContinueJourney", VariantCode);
    // setQueryStringType(true);
    for (let key in stepData?.apiRequestQQ) {
      if (key === "VariantCode") {
        for (let key in stepData?.apiRequestQQ?.VariantCode) {
          dispatchQuickQuote(
            `VariantCode.${key}`,
            stepData?.apiRequestQQ?.VariantCode[key]
          );
        }
      } else if (key === "ApiUniqueNumber") {
        for (let key in stepData?.apiRequestQQ?.ApiUniqueNumber) {
          dispatchQuickQuote(
            `ApiUniqueNumber.${key}`,
            stepData?.apiRequestQQ?.ApiUniqueNumber[key]
          );
        }
      } else if (key === "KYC") {
        for (let key in stepData?.apiRequestQQ?.KYC) {
          dispatchQuickQuote(`KYC.${key}`, stepData?.apiRequestQQ?.KYC[key]);
        }
      } else {
        console.log(`${key}`, stepData?.apiRequestQQ[key]);
        dispatchQuickQuote(`${key}`, stepData?.apiRequestQQ[key]);
      }
    }
    // for (let key in VariantCode) {
    //   console.log(`${key}`, stepData?.apiRequestQQ[key]);
    //   dispatchQuickQuote(`VariantCode.${key}`, stepData?.apiRequestQQ[key]);
    // }
    if (stepData?.step4?.variantSelection?.Cubic_Capacity !== "") {
      dispatchQuickQuote(
        "CubicCapacity",
        stepData?.step4?.variantSelection?.Cubic_Capacity
      );
      dispatchQuickQuote(
        "FuelType",
        stepData?.step4?.variantSelection?.Fuel_Type
      );
      dispatchQuickQuote(
        "VariantName",
        stepData?.step4?.variantSelection?.label
      );
    }
    if (stepData?.step5?.customerDetails?.customer_name) {
      const nameParts =
        stepData?.step5?.customerDetails?.customer_name.split(" ");
      console.log("firstName", nameParts);
      if (nameParts.length > 2) {
        dispatchQuickQuote("FirstName", nameParts[0]);
        dispatchQuickQuote("MiddleName", nameParts[1]);
        dispatchQuickQuote("LastName", nameParts[2]);
      } else {
        dispatchQuickQuote("FirstName", nameParts[0]);
        dispatchQuickQuote("LastName", nameParts[1]);
      }
      dispatchQuickQuote(
        "MobileNumber",
        stepData?.step5?.customerDetails?.customer_phone
      );
      dispatchQuickQuote(
        "Email",
        stepData?.step5?.customerDetails?.customer_email
      );
      dispatchQuickQuote("CustomerType", requestData?.CustomerType);
      dispatchQuickQuote(
        "PreviousOwnerChanged",
        requestData?.PreviousOwnerChanged
      );
    }
    console.log("stepData?.step6?.pypSelection", stepData?.step6?.pypSelection);
    if (
      stepData &&
      stepData?.step6?.pypSelection &&
      Object.keys(stepData?.step6?.pypSelection)
    ) {
      for (let key in stepData?.step6?.pypSelection) {
        console.log(`${key}`, stepData?.step6?.pypSelection[key]);
        dispatchQuickQuote(`${key}`, stepData?.step6?.pypSelection[key]);
      }
    }
    if (stepData?.step === "step1") {
      console.log("stepDAta", stepData);
      dispatchQuickQuote("quotationNumber", stepData.quotationNumber);
      dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
      dispatchProcessFlowMotor(
        "step1.registerationNumber",
        stepData.step1.registerationNumber
      );

      navigate("/motor", {
        state: {
          activeStep: 2,
          journeyContinue: true,
          vehicleModal: requestData?.vehicleModal,
          vehicleType: requestData?.VehicleType,
        },
      });
    } else if (stepData?.step === "step2") {
      console.log("stepDAta", stepData);
      dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
      dispatchProcessFlowMotor(
        "step1.registerationNumber",
        stepData.step1.registerationNumber
      );
      dispatchProcessFlowMotor("step2.make", stepData.step2.make);

      navigate("/motor", {
        state: {
          activeStep: 3,
          journeyContinue: true,
          vehicleModal: requestData?.vehicleModal,
          vehicleType: requestData?.VehicleType,
          requestData: requestData,
        },
      });
    } else if (stepData?.step === "step3") {
      dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
      dispatchProcessFlowMotor(
        "step1.registerationNumber",
        stepData.step1.registerationNumber
      );
      dispatchProcessFlowMotor("step2.make", stepData.step2.make);
      dispatchProcessFlowMotor("step3.model", stepData.step3.model);

      navigate("/motor", {
        state: {
          activeStep: 4,
          journeyContinue: true,
          vehicleModal: requestData?.vehicleModal,
          vehicleType: requestData?.VehicleType,
          requestData: requestData,
        },
      });
    } else if (stepData?.step === "step4") {
      dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
      dispatchProcessFlowMotor(
        "step1.registerationNumber",
        stepData.step1.registerationNumber
      );
      dispatchProcessFlowMotor("step2.make", stepData.step2.make);
      dispatchProcessFlowMotor("step3.model", stepData.step3.model);
      dispatchProcessFlowMotor(
        "step4.variantSelection",
        stepData.step4.variantSelection
      );

      navigate("/motor", {
        state: {
          activeStep: 6,
          journeyContinue: true,
          vehicleModal: requestData?.vehicleModal,
          vehicleType: requestData?.VehicleType,
          requestData: requestData,
        },
      });
    } else if (stepData?.step === "step5") {
      dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
      dispatchProcessFlowMotor(
        "step1.registerationNumber",
        stepData.step1.registerationNumber
      );
      dispatchProcessFlowMotor("step2.make", stepData.step2.make);
      dispatchProcessFlowMotor("step3.model", stepData.step3.model);
      dispatchProcessFlowMotor(
        "step4.variantSelection",
        stepData.step4.variantSelection
      );
      dispatchProcessFlowMotor(
        "step5.customerDetails",
        stepData.step5.customerDetails
      );

      navigate("/motor", {
        state: {
          activeStep: 8,
          journeyContinue: true,
          vehicleModal: requestData?.vehicleModal,
          vehicleType: requestData?.VehicleType,
          requestData: requestData,
        },
      });
    } else if (stepData?.step === "step6") {
      dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
      dispatchProcessFlowMotor(
        "step1.registerationNumber",
        stepData.step1.registerationNumber
      );
      dispatchProcessFlowMotor("step2.make", stepData.step2.make);
      dispatchProcessFlowMotor("step3.model", stepData.step3.model);
      dispatchProcessFlowMotor(
        "step4.variantSelection",
        stepData.step4.variantSelection
      );
      dispatchProcessFlowMotor(
        "step5.customerDetails",
        stepData.step5.customerDetails
      );
      dispatchProcessFlowMotor(
        "step6.pypSelection",
        stepData.step6.pypSelection
      );

      navigate("/quotelist", {
        state: {
          journeyContinue: true,
          vehicleModal: requestData?.vehicleModal,
          vehicleType: requestData?.VehicleType,
          requestData: requestData,
        },
      });
    } else if (stepData?.step === "step7") {
      dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
      dispatchQuickQuote("NCBSET", false);
      dispatchProcessFlowMotor(
        "step1.registerationNumber",
        stepData.step1.registerationNumber
      );
      dispatchProcessFlowMotor("step2.make", stepData.step2.make);
      dispatchProcessFlowMotor("step3.model", stepData.step3.model);
      dispatchProcessFlowMotor(
        "step4.variantSelection",
        stepData.step4.variantSelection
      );
      dispatchProcessFlowMotor(
        "step5.customerDetails",
        stepData.step5.customerDetails
      );
      dispatchProcessFlowMotor(
        "step6.pypSelection",
        stepData.step6.pypSelection
      );
      dispatch(resetQuickQuoteResults());

      navigate("/quotelist", {
        state: {
          journeyContinue: true,
          vehicleModal: requestData?.vehicleModal,
          vehicleType: requestData?.VehicleType,
          requestData: requestData,
        },
      });
    } else {
      dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
      dispatchQuickQuote("NCBSET", false);
      dispatchProcessFlowMotor(
        "step1.registerationNumber",
        stepData.step1.registerationNumber
      );
      dispatchProcessFlowMotor("step2.make", stepData.step2.make);
      dispatchProcessFlowMotor("step3.model", stepData.step3.model);
      dispatchProcessFlowMotor(
        "step4.variantSelection",
        stepData.step4.variantSelection
      );
      dispatchProcessFlowMotor(
        "step5.customerDetails",
        stepData.step5.customerDetails
      );
      dispatchProcessFlowMotor(
        "step6.pypSelection",
        stepData.step6.pypSelection
      );
      dispatch(resetQuickQuoteResults());

      navigate("/quotelist", {
        state: {
          journeyContinue: true,
          vehicleModal: requestData?.vehicleModal,
          vehicleType: requestData?.VehicleType,
          requestData: requestData,
        },
      });
    }
  };

  useEffect(() => {
    if (Object.keys(stepsData).length > 0) {
      handleContinueJourney();
    }
  }, [stepsData]);

  const updateProfile = async (data) => {
    console.log("update profile data", data);
    dispatchUserDetails(
      "FullName",
      data?.name ? data?.name : userDetails?.FullName
    );
    dispatchUserDetails(
      "Gender",
      data?.gender ? data?.gender : userDetails?.Gender
    );
    dispatchUserDetails(
      "Email",
      data?.email ? data?.email : userDetails?.Email
    );
    dispatchUserDetails("Phone", data?.phone);
    dispatchUserDetails(
      "MaritalStatus",
      data?.maritalStatus ? data?.maritalStatus : userDetails?.MaritalStatus
    );
    dispatchUserDetails(
      "DateofBirth",
      data?.DateOfBirth ? data?.DateOfBirth : userDetails?.DateofBirth
    );
    dispatchUserDetails("City", data?.City ? data?.City : userDetails?.City);

    const postData = {
      customerName: data?.name,
      customerGender: data?.gender,
      customerBody: data?.email ? data?.email : userDetails?.Email,
      customerDob: data?.DateOfBirth
        ? data?.DateOfBirth
        : userDetails?.DateofBirth,
      maritalStatus: data?.maritalStatus
        ? data?.maritalStatus
        : userDetails?.MaritalStatus,
      city: data?.City,

      customerUniqueNumber:
        userDetails?.customerUniqueNumber || userDetails?.CustomerUniqueNumber,
    };
    console.log("postData", postData);
    try {
      const response = await PostDataWithToken(
        "admin/update_customer",
        postData
      );
      console.log(response, "response update");
      if (
        response?.success === true &&
        response?.message === "User Updated Successfully!!"
      ) {
        sendSaveUserMessage();
        navigate("/");
      } else {
        failedUpdateMsg(response?.data?.message);
      }
      // dispatchUserDetails('FullName', response)
    } catch (err) {
      console.log(err);
    }
  };

  const handleSideBarClick = () => {
    if (isMobile) {
      setSideNav(!sideNav);
    }
  };

  console.log(moment(userDetails?.DateofBirth).format(""), "Moment Date");

  const formatDateForDisplay = (dateString) => {
    if (!dateString) return "";

    const [day, month, year] = dateString.split("/");

    return `${year}-${month}-${day}`;
  };

  // Handle date change from DatePicker
  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      setDob(date);
      clearErrors("DateOfBirth");
      setValue("DateOfBirth", formattedDate);
    } else {
      setDob(null);
      clearErrors("DateOfBirth");
      setValue("DateOfBirth", "");
    }
  };

  // Handle manual input change with mask
  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputValue)) {
      const [day, month, year] = inputValue.split("/");
      const date = new Date(`${year}-${month}-${day}`);

      if (!isNaN(date.getTime())) {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        setDob(date);
        clearErrors("DateOfBirth");
        setValue("DateOfBirth", formattedDate);
      }
    } else {
      setDob(null);
      clearErrors("DateOfBirth");
      setValue("DateOfBirth", "");
    }
  };
  const offset = currentPage * entriesPerPage;
  const currentItems = customerQuotationData?.slice(
    offset,
    offset + entriesPerPage
  );
  console.log("pageCount-----", totalPage);
  console.log("pageCount entriesPerPage-----", entriesPerPage);
  const pageCount = Math.ceil(totalPage / entriesPerPage);
  console.log("pageCount", itemsPerPage, pageCount);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // Handle items per page change
  const handleChangeItemPerPage = (value) => {
    const newItemsPerPage = parseInt(value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(0); // Reset to the first page
  };

  // const pageCount = Math.ceil(totalItems / entriesPerPage);
  // Fetch data when currentPage or itemsPerPage changes
  useEffect(() => {
    fetchCustomerQuotationData();
  }, [currentPage, itemsPerPage]);

  // const pageCount = Math.ceil(totalItems / entriesPerPage);
  // Fetch data when currentPage or itemsPerPage changes
  useEffect(() => {
    fetchCustomerQuotationData();

    console.log("currentPage", currentPage, itemsPerPage);
  }, [currentPage, itemsPerPage]);
  return (
    <>
      {/* SideNav, Top Right Section, Pages other than profile */}
      <div className="layout">
        <div className={`dash_header ${sideNav ? "open" : ""}`}>
          <div className="nav_dash_wrap ">
            <div className="nav_dash_wrpLeft">
              <a
                // href="javascript:void(0);"
                className="dashIconFold"
                onClick={() => setSideNav(!sideNav)}
                id="foldBtn"
              >
                <div className="notFolded">
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    className=""
                    data-icon="menu-fold"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM115.4 518.9L271.7 642c5.8 4.6 14.4.5 14.4-6.9V388.9c0-7.4-8.5-11.5-14.4-6.9L115.4 505.1a8.74 8.74 0 0 0 0 13.8z"></path>
                  </svg>
                </div>

                <div className="folded ">
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    className=""
                    data-icon="menu-unfold"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z"></path>
                  </svg>
                </div>
              </a>
            </div>
            {/* Navbar Top Right Section */}
            <div className="nav_dash_wrpRight">
              <span className="d-bock d-md-none d-lg-none">
                {/* <img
                  src="/assets/images/insurefastlogo.png"
                  alt=""
                  onClick={() => navigate("/")}
                /> */}
                <img
                  class="logo_fold"
                  src="/logo.ico"
                  alt=""
                  onClick={() => navigate("/")}
                />
              </span>
              {/* Notification */}
              <div className="nav_right_notification dropdown mr-15">
                <a
                  // href="javascript:void(0);"
                  className="rightMenuBtn newNotiMsg"
                  data-bs-toggle="dropdown"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g opacity="0.6">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16 18H20.5858C21.3668 18 22 17.3668 22 16.5858C22 16.2107 21.851 15.851 21.5858 15.5858L20.5858 14.5858C20.2107 14.2107 20 13.702 20 13.1716L20 9.97067C20 5.56859 16.4314 2 12.0293 2C7.61789 2 4.04543 5.58319 4.05867 9.9946L4.06814 13.1476C4.06977 13.6922 3.84928 14.2139 3.45759 14.5922L2.42864 15.586C2.15471 15.8506 2 16.2151 2 16.5959C2 17.3714 2.62863 18 3.40408 18H8C8 20.2091 9.79086 22 12 22C14.2091 22 16 20.2091 16 18ZM19.5251 15.6464L20.3787 16.5H3.64147L4.49967 15.6711C5.18513 15.009 5.57099 14.0961 5.56813 13.1431L5.55867 9.9901C5.54792 6.40887 8.44807 3.5 12.0293 3.5C15.603 3.5 18.5 6.39702 18.5 9.97067L18.5 13.1716C18.5 14.0998 18.8687 14.9901 19.5251 15.6464ZM14.5 18H9.5C9.5 19.3807 10.6193 20.5 12 20.5C13.3807 20.5 14.5 19.3807 14.5 18Z"
                        fill="black"
                      />
                    </g>
                  </svg>
                </a>
                <div className="dropdown-menu dropdown-menu-end">
                  <div className="notiHeader_top">
                    <div className="notiHeaderTopHeading">
                      <span className=""> There is no notification yet!!</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Side Bar */}
        <div className={`side_nav ${sideNav ? "open" : ""}`}>
          <div class="dash_logo">
            <a href="javascript:void(0);">
              <img
                src="/assets/images/logoNew.svg"
                alt=""
                onClick={() => navigate("/")}
              />
              <img
                class="logo_fold"
                src="/logo.ico"
                alt=""
                onClick={() => navigate("/")}
              />
            </a>
          </div>
          <div className="side_nav_inner">
            <ul className="sideNav_menu">
              {/* My Profile */}
              <li
                className="dash_nav_item"
                onClick={() => {
                  setSideBar("myProfile");
                  handleSideBarClick();
                }}
              >
                <a
                  href="javascript:void(0);"
                  className={mySideBar === "myProfile" ? "active" : ""}
                >
                  <span className="icon_holder">
                    <img src="/assets/images/profile.png"></img>
                  </span>
                  <span className="title_dash_nav">My Profile</span>
                </a>
              </li>
              {/* My Policies */}
              <li
                className="dash_nav_item"
                onClick={() => {
                  setSideBar("myPolicies");
                  handleSideBarClick();
                }}
              >
                <a
                  href="javascript:void(0);"
                  className={mySideBar === "myPolicies" ? "active" : ""}
                >
                  <span className="icon_holder">
                    <img src="/assets/images/policy.png"></img>
                  </span>
                  <span className="title_dash_nav">My Policies</span>
                </a>
              </li>
              {/* My Quotations */}
              <li
                className="dash_nav_item"
                onClick={() => {
                  setSideBar("myQuotations");
                  handleSideBarClick();
                }}
              >
                <a
                  href="javascript:void(0);"
                  className={mySideBar === "myQuotations" ? "active" : ""}
                >
                  <span className="icon_holder">
                    <img src="/assets/images/quotation.png"></img>
                  </span>
                  <span className="title_dash_nav">My Quotations</span>
                </a>
              </li>
              {/* Claim Support */}
              <li
                className="dash_nav_item"
                onClick={() => {
                  setSideBar("claimSupport");
                  handleSideBarClick();
                }}
              >
                <a
                  href="javascript:void(0);"
                  className={mySideBar === "claimSupport" ? "active" : ""}
                >
                  <span className="icon_holder">
                    <img src="/assets/images/claim (1).png"></img>
                  </span>
                  <span className="title_dash_nav">Claim Support</span>
                </a>
              </li>
              {/* My Transactions */}
              {/* <li
                className="dash_nav_item"
                onClick={() => {
                  setSideBar("myTransaction");
                  handleSideBarClick();
                }}
              >
                <a
                  href="javascript:void(0);"
                  className={mySideBar === "myTransaction" ? "active" : ""}
                >
                  <span className="icon_holder">
                    <img src="/assets/images/rupee.png"></img>
                  </span>
                  <span className="title_dash_nav">My Transaction</span>
                </a>
              </li> */}
              {/* Logout */}
              <li
                className="dash_nav_item"
                onClick={() => {
                  setLogoutModal(true);
                }}
              >
                <a href="javascript:void(0);">
                  <span className="icon_holder">
                    <img src="/assets/images/sign-out.png"></img>
                  </span>
                  <span className="title_dash_nav">Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* Profile Section */}
        {mySideBar === "myProfile" && (
          <div className={`page_container ${sideNav ? "open" : ""}`}>
            <div className="main_content">
              <div className="row walletSearchRow">
                <div className="col-12">
                  <div className="dashboardInner_box">
                    <div className="profile_detail">
                      <div className="section_block">
                        <h2 className="sectionHeading">My Profile</h2>
                      </div>
                      <div className="profile_avatar">
                        <div className="avatar_img">
                          <img
                            // src={
                            //   watch("gender") === "male"
                            //     ? "/assets/images/male icon.png"
                            //     : "/assets/images/female icon.png"
                            // }
                            src={
                              userDetails?.Gender === "Male"
                                ? "/assets/images/male icon (1).png"
                                : userDetails?.Gender === "Female"
                                ? "/assets/images/female icon .png"
                                : userDetails?.Gender === null
                                ? watch("gender") === "Male"
                                  ? "/assets/images/male icon.png"
                                  : "/assets/images/female icon.png"
                                : ""
                            }
                          />
                        </div>
                      </div>
                      {/* Profile Tab */}
                      {defaultDate !== null && (
                        <div className="profile-tab">
                          <div className="profile-pills">
                            <ul
                              className="nav nav-pills"
                              id="pills-tab"
                              role="tablist"
                            >
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link active"
                                  id="pills-home-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-home"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-home"
                                  aria-selected="true"
                                >
                                  Personal Detail
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link"
                                  id="pills-profile-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-profile"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-profile"
                                  aria-selected="false"
                                >
                                  Contact Detail
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="tab-content" id="pills-tabContent">
                            {/* Profile Section tab */}
                            <div
                              className="tab-pane fade show active"
                              id="pills-home"
                              role="tabpanel"
                              aria-labelledby="pills-home-tab"
                              tabindex="0"
                            >
                              <div className="profile_info">
                                {
                                  <form
                                    className=""
                                    onSubmit={handleSubmit(updateProfile)}
                                  >
                                    <div className=" w-100 mt-0">
                                      <div className="profile_details">
                                        <div className="mt-3">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <div className="mb-4 mb-md-4">
                                                <div className="profile_gender mt-0">
                                                  <div className="d-flex yesnoinput">
                                                    {userDetails?.Gender ===
                                                    "Male" ? (
                                                      <div>
                                                        <input
                                                          type="radio"
                                                          className="d-none"
                                                          id="male"
                                                          name="gender"
                                                          value={"Male"}
                                                          // {...register("gender")}
                                                          checked={
                                                            userDetails?.Gender ===
                                                            "Male"
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                        <label htmlFor="male">
                                                          <span className="user_img">
                                                            <img src="/assets/images/male-user.svg"></img>
                                                          </span>
                                                          Male
                                                        </label>
                                                      </div>
                                                    ) : userDetails?.Gender ===
                                                      "Female" ? (
                                                      <div>
                                                        <input
                                                          type="radio"
                                                          className="d-none"
                                                          id="female"
                                                          name="gender"
                                                          value={"Female"}
                                                          checked={
                                                            userDetails?.Gender ===
                                                            "Female"
                                                              ? true
                                                              : false
                                                          }
                                                          // {...register("gender")}
                                                        />
                                                        <label htmlFor="female">
                                                          <span className="user_img">
                                                            <img src="/assets/images/male-user.svg"></img>
                                                          </span>
                                                          Female
                                                        </label>
                                                      </div>
                                                    ) : (
                                                      <>
                                                        <div>
                                                          <input
                                                            type="radio"
                                                            className="d-none"
                                                            {...register(
                                                              "gender"
                                                            )}
                                                            id="male"
                                                            name="gender"
                                                            value={"Male"}
                                                            // {...register("gender")}
                                                            // checked={
                                                            //   userDetails?.Gender ===
                                                            //   "Male"
                                                            //     ? true
                                                            //     : false
                                                            // }
                                                          />
                                                          <label htmlFor="male">
                                                            <span className="user_img">
                                                              <img src="/assets/images/male-user.svg"></img>
                                                            </span>
                                                            Male
                                                          </label>
                                                        </div>
                                                        <div>
                                                          <input
                                                            type="radio"
                                                            className="d-none"
                                                            {...register(
                                                              "gender"
                                                            )}
                                                            id="female"
                                                            name="gender"
                                                            value={"Female"}
                                                            // checked={
                                                            //   userDetails?.Gender ===
                                                            //   "Female"
                                                            //     ? true
                                                            //     : false
                                                            // }
                                                            // {...register("gender")}
                                                          />
                                                          <label htmlFor="female">
                                                            <span className="user_img">
                                                              <img src="/assets/images/male-user.svg"></img>
                                                            </span>
                                                            Female
                                                          </label>
                                                        </div>
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6">
                                              <div className="mb-4">
                                                <div className="did-floating-label-content">
                                                  <input
                                                    type="text"
                                                    className="did-floating-input"
                                                    {...register("name")}
                                                    id="name"
                                                    placeholder=" "
                                                    name="name"
                                                    defaultValue={
                                                      userDetails?.FullName
                                                    }
                                                    onInput={(e) =>
                                                      (e.target.value =
                                                        e.target.value.replace(
                                                          /[0-9!@#\$%\^\&*\)\(+=._-]+$/g,
                                                          ""
                                                        ))
                                                    }
                                                  />
                                                  <label
                                                    htmlFor="name"
                                                    className="did-floating-label"
                                                  >
                                                    Full Name
                                                  </label>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-6">
                                              <div className="mb-4">
                                                <div className="did-floating-label-content">
                                                  <DatePicker
                                                    selected={dob}
                                                    onChange={handleDateChange}
                                                    customInput={
                                                      <ReactInputMask
                                                        mask="99/99/9999"
                                                        placeholder="DD/MM/YYYY"
                                                        value={
                                                          dob
                                                            ? moment(
                                                                dob
                                                              ).format(
                                                                "DD/MM/YYYY"
                                                              )
                                                            : ""
                                                        }
                                                        onChange={
                                                          handleInputChange
                                                        }
                                                        alwaysShowMask={false}
                                                      />
                                                    }
                                                    dateFormat="dd/MM/yyyy"
                                                    placeholderText="Select Date of Birth"
                                                    className="did-floating-input"
                                                  />
                                                  <label
                                                    htmlFor="dob"
                                                    className="did-floating-label"
                                                  >
                                                    Date of Birth
                                                  </label>
                                                  {errors?.DateOfBirth && (
                                                    <p className="f-error text-danger">
                                                      {
                                                        errors.DateOfBirth
                                                          .message
                                                      }
                                                    </p>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6">
                                              <div className="mb-4">
                                                <div className="did-floating-label-content">
                                                  <select
                                                    className=" form-select text-truncate"
                                                    {...register(
                                                      "maritalStatus",
                                                      {
                                                        required:
                                                          "Field is required",
                                                      }
                                                    )}
                                                  >
                                                    <option value="">
                                                      Select Marital Status
                                                    </option>
                                                    <option
                                                      selected={
                                                        userDetails?.MaritalStatus ===
                                                        "Single"
                                                          ? true
                                                          : false
                                                      }
                                                      value={"Single"}
                                                    >
                                                      Single
                                                    </option>
                                                    <option
                                                      selected={
                                                        userDetails?.MaritalStatus ===
                                                        "Married"
                                                          ? true
                                                          : false
                                                      }
                                                      value={"Married"}
                                                    >
                                                      Married
                                                    </option>
                                                  </select>
                                                  <label
                                                    htmlFor="maritalStatus"
                                                    className="did-floating-label"
                                                  >
                                                    Marital Status
                                                  </label>
                                                  {errors?.maritalStatus && (
                                                    <span className="text-danger">
                                                      {
                                                        errors?.maritalStatus
                                                          ?.message
                                                      }
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6">
                                              <div className="mb-4">
                                                <div className="did-floating-label-content">
                                                  <input
                                                    type="text"
                                                    className="did-floating-input"
                                                    {...register("City", {
                                                      required:
                                                        "Field is required",
                                                    })}
                                                    id="City"
                                                    onInput={(e) => {
                                                      e.target.value =
                                                        e.target.value.replace(
                                                          /[0-9!@#\$%\^\&*\)\(+=._-]+$/g,
                                                          ""
                                                        );
                                                    }}
                                                    maxLength={20}
                                                    placeholder=" "
                                                    name="City"
                                                    defaultValue={
                                                      userDetails?.City
                                                    }
                                                  />
                                                  <label
                                                    htmlFor="City"
                                                    className="did-floating-label"
                                                  >
                                                    City
                                                  </label>
                                                  {errors?.City && (
                                                    <span className="text-danger">
                                                      {errors?.City?.message}
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {!userDetails?.username && (
                                        <div className="profile_btn_div ">
                                          <button
                                            className="profile_btn"
                                            type="submit"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </form>
                                }
                              </div>
                            </div>
                            {/* Contact Details tab */}
                            <div
                              className="tab-pane fade"
                              id="pills-profile"
                              role="tabpanel"
                              aria-labelledby="pills-profile-tab"
                              tabindex="0"
                            >
                              <div className="profile_info">
                                {
                                  <form
                                    className=""
                                    onSubmit={handleSubmit(updateProfile)}
                                  >
                                    <div className="w-100 mt-0">
                                      <div className="profile_details">
                                        <div className="mt-3">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <div className="mb-4 mb-md-4">
                                                {/* <div className="profile_gender mt-0">
                                                  <div className="d-flex yesnoinput">
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        className="d-none"
                                                        id="male"
                                                        name="gender"
                                                        value={"male"}
                                                        // {...register("gender")}
                                                        checked={
                                                          userDetails?.Gender ===
                                                          "Male"
                                                            ? true
                                                            : false
                                                        }
                                                      />
                                                      <label htmlFor="male">
                                                        <span className="user_img">
                                                          <img src="/assets/images/male-user.svg"></img>
                                                        </span>
                                                        Male
                                                      </label>
                                                    </div>
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        className="d-none"
                                                        id="female"
                                                        name="gender"
                                                        value={"female"}
                                                        checked={
                                                          userDetails?.Gender ===
                                                          "Female"
                                                            ? true
                                                            : false
                                                        }
                                                        // {...register("gender")}
                                                      />
                                                      <label htmlFor="female">
                                                        <span className="user_img">
                                                          <img src="/assets/images/male-user.svg"></img>
                                                        </span>
                                                        Female
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div> */}
                                              </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-6">
                                              <div className="mb-4">
                                                <div className="did-floating-label-content">
                                                  <input
                                                    type="number"
                                                    {...register("phone")}
                                                    className="did-floating-input"
                                                    id="phone"
                                                    name="phone"
                                                    defaultValue={
                                                      userDetails?.Phone
                                                    }
                                                    disabled
                                                    placeholder="Enter Mobile Number"
                                                  />
                                                  <label
                                                    htmlFor="phone"
                                                    className="did-floating-label"
                                                  >
                                                    Mobile No.
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6">
                                              <div className="mb-4">
                                                <div className="did-floating-label-content">
                                                  <input
                                                    type="email"
                                                    className="did-floating-input"
                                                    {...register("email")}
                                                    id="email"
                                                    placeholder=" "
                                                    name="email"
                                                    defaultValue={
                                                      userDetails?.Email
                                                    }
                                                    maxLength={100}
                                                    onInput={(e) => {
                                                      let inputValue =
                                                        e.target.value;

                                                      if (
                                                        inputValue.includes(
                                                          ".com"
                                                        )
                                                      ) {
                                                        inputValue =
                                                          inputValue.replace(
                                                            /\.com.*/,
                                                            ".com"
                                                          );
                                                      } else if (
                                                        inputValue.includes(
                                                          ".in"
                                                        )
                                                      ) {
                                                        inputValue =
                                                          inputValue.replace(
                                                            /\.in.*/,
                                                            ".in"
                                                          );
                                                      }

                                                      e.target.value =
                                                        inputValue;
                                                    }}
                                                  />
                                                  <label
                                                    htmlFor="email"
                                                    className="did-floating-label"
                                                  >
                                                    Email
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="profile_btn_div ">
                                        <button
                                          className="profile_btn"
                                          type="submit"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Pages other than Profile */}
        {mySideBar !== "myProfile" &&
          mySideBar !== "myPolicies" &&
          mySideBar !== "myQuotations" && (
            <div className={`page_container ${sideNav ? "open" : ""}`}>
              <div className="main_content">
                <div className="row walletSearchRow">
                  <div className="col-12">
                    <div className="dashboardInner_box">
                      <div className="profile_detail">
                        <div className="section_block">
                          <h2 className="sectionHeading">
                            {mySideBar === "claimSupport"
                              ? "Claim Support"
                              : mySideBar === "myTransaction"
                              ? "My Transaction"
                              : ""}
                          </h2>
                        </div>
                        <div className="policy_content">
                          <div className="policy_img mb-3">
                            <img src="/assets/images/docSearchingIcon.svg"></img>
                          </div>
                          <h2 className="">
                            No{" "}
                            {mySideBar === "claimSupport"
                              ? "active Claims"
                              : mySideBar === "myTransaction"
                              ? "Transactions"
                              : ""}{" "}
                            found
                          </h2>
                          <p>
                            {mySideBar === "myPolicies"
                              ? "Once you buy a new policy,"
                              : mySideBar === "claimSupport"
                              ? "If there are any Claims,"
                              : mySideBar === "myTransaction"
                              ? "If there are any transactions,"
                              : ""}{" "}
                            it will show up here
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {/* Policy Section */}
        {mySideBar === "myPolicies" && customerPolicyData?.length > 0 ? (
          <div className={`page_container ${sideNav ? "open" : ""}`}>
            <div
              className={`main_content`}
              style={{
                padding: sideNav
                  ? "calc(90px + 30px) 50px 30px 100px"
                  : "calc(90px + 30px) 50px 30px",
              }}
            >
              {/* style={{ padding: "calc(90px + 30px) 50px 30px" }} */}
              <div className="row">
                {/* New-POS table start */}
                <div className="col-12">
                  <div
                    className="dashboardInner_box"
                    style={{ padding: "42px 20px" }}
                  >
                    <div class="section_block">
                      <h2 class="sectionHeading">My Policies</h2>
                    </div>
                    <div
                      className=""
                      // style={{ marginTop: "6rem" }}
                    >
                      <div className="card-body p-0">
                        {/* <div className="text-end">
                                    <Button
                                      className="btn btn-fill btn-primary"
                                      // headers={headers}
                                      // data={csvData}
                                    >
                                      Download CSV
                                    </Button>
                                  </div> */}
                        <div className="text-center">
                          {/* <MDBDataTable
                            scrollX
                            noBottomColumns
                            hover
                            entriesOptions={[10, 25, 50, 75, 100]}
                            entries={10}
                            data={dataTable}
                            infoLabel={["Showing", "to", "of", "entries"]}
                            pagesAmount={10}
                            paginationLabel={["<", ">"]}
                            bordered
                          /> */}
                          <div className="table-responsive p-0">
                            <table className="table align-items-center mb-0 dataTable table-striped table-hover table-bordered">
                              <thead>
                                <tr className="policy-table">
                                  <th className=" text-xxs font-weight-bolder text-dark">
                                    Sr.No.
                                  </th>

                                  <th className=" text-xxs font-weight-bolder text-dark">
                                    Product
                                  </th>
                                  <th className=" text-xxs font-weight-bolder text-dark">
                                    Policy Type
                                  </th>
                                  <th className="text-xxs font-weight-bolder text-dark">
                                    Customer Name
                                  </th>
                                  <th
                                    className=" text-xxs font-weight-bolder text-dark"
                                    style={{
                                      minWidth: "130px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Customer Mobile
                                  </th>
                                  {/* <th
                                    className='text-uppercase text-xxs font-weight-bolder text-dark'
                                    style={{minWidth:"100px",textAlign:"center"}}
                                  >
                                    Customer Email
                                  </th> */}
                                  <th
                                    className=" text-xxs font-weight-bolder text-dark"
                                    style={{
                                      minWidth: "120px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Policy No
                                  </th>
                                  <th className=" text-xxs font-weight-bolder text-dark">
                                    Insurer Name
                                  </th>
                                  <th className="text-xxs font-weight-bolder text-dark">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {customerPolicyData.map((item, index) => (
                                  <tr key={index} className="data-table-info">
                                    <td>{index + 1}</td>
                                    <td> {item?.product} </td>
                                    <td> {item?.policy_type} </td>
                                    <td> {item?.customer_name} </td>
                                    <td> {item?.customer_mobile_no} </td>
                                    {/* <td> {item?.customer_email} </td> */}
                                    <td> {item?.policy_no} </td>
                                    <td> {item?.insurer_name} </td>
                                    <td>
                                      <span
                                        onClick={() =>
                                          handlePdfDownload(item?.policy_doc)
                                        }
                                      >
                                        <MDBIcon
                                          className="ms-1"
                                          icon="download"
                                          size="sm"
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* New-POS table end */}
              </div>
            </div>
          </div>
        ) : (
          mySideBar === "myPolicies" && (
            <div className={`page_container ${sideNav ? "open" : ""}`}>
              <div className="main_content">
                <div className="row walletSearchRow">
                  <div className="col-12">
                    <div className="dashboardInner_box">
                      <div className="profile_detail">
                        <div className="section_block">
                          <h2 className="sectionHeading">
                            {mySideBar === "myPolicies" && "My Policies"}
                          </h2>
                        </div>
                        <div className="policy_content">
                          <div className="policy_img mb-3">
                            <img src="/assets/images/docSearchingIcon.svg"></img>
                          </div>
                          <h2 className="">
                            No{" "}
                            {mySideBar === "myPolicies" && "active Policies "}
                            found
                          </h2>
                          <p>
                            {mySideBar === "myPolicies" &&
                              "Once you buy a new policy,"}
                            it will show up here
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}

        {/* Quotation Section */}
        {mySideBar === "myQuotations" && customerQuotationData?.length > 0 ? (
          <div className={`page_container ${sideNav ? "open" : ""}`}>
            <div
              className={`main_content`}
              style={{
                padding: sideNav
                  ? "calc(90px + 30px) 50px 30px 100px"
                  : "calc(90px + 30px) 50px 30px",
              }}
            >
              {/* style={{ padding: "calc(90px + 30px) 50px 30px" }} */}
              <div className="row">
                {/* New-POS table start */}
                <div className="col-12">
                  <div
                    className="dashboardInner_box"
                    style={{ padding: "42px 20px" }}
                  >
                    <div class="section_block">
                      <h2 class="sectionHeading">My Quotations</h2>
                    </div>
                    <div className="">
                      <div className="card-body p-0">
                        <div className="text-center">
                          {/* <MDBDataTable
                            striped
                            scrollX
                            noBottomColumns
                            hover
                            entriesOptions={[10, 25, 50, 75, 100]}
                            entries={10}
                            data={quotationDataTable}
                            infoLabel={["Showing", "to", "of", "entries"]}
                            pagesAmount={10}
                            paginationLabel={["<", ">"]}
                            bordered
                          /> */}
                          <div className="table-responsive p-0">
                            <table className="table align-items-center mb-0 dataTable table-hover table-bordered">
                              <thead>
                                <tr>
                                  <th className="text-xxs font-weight-bolder text-dark">
                                    Sr.No.
                                  </th>

                                  {/* <th
                                    className='text-uppercase text-xxs font-weight-bolder text-dark'
                                  >
                                    Customer Email
                                  </th> */}
                                  {/* <th
                                    className='text-uppercase text-xxs font-weight-bolder text-dark'
                                  >
                                    Customer Mobile
                                  </th> */}
                                  <th
                                    className=" text-xxs font-weight-bolder text-dark"
                                    style={{
                                      minWidth: "130px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Make
                                  </th>
                                  <th
                                    className=" text-xxs font-weight-bolder text-dark"
                                    style={{
                                      minWidth: "100px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Model
                                  </th>
                                  <th
                                    className=" text-xxs font-weight-bolder text-dark"
                                    style={{
                                      minWidth: "120px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Variant
                                  </th>
                                  <th className=" text-xxs font-weight-bolder text-dark">
                                    Product
                                  </th>
                                  <th className=" text-xxs font-weight-bolder text-dark">
                                    Vehicle
                                  </th>
                                  <th className=" text-xxs font-weight-bolder text-dark">
                                    Customer Name
                                  </th>
                                  {/* <th
                                    className='text-uppercase text-xxs font-weight-bolder text-dark'
                                  >
                                    Business Type
                                  </th> */}
                                  {/* <th
                                    className='text-uppercase text-xxs font-weight-bolder text-dark'
                                  >
                                    policyType
                                  </th> */}
                                  <th className="text-uppercase text-xxs font-weight-bolder text-dark">
                                    Date/Time
                                  </th>
                                  <th className="text-uppercase text-xxs font-weight-bolder text-dark">
                                    Case Url
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {customerQuotationData.map((item, index) => (
                                  <tr key={index} className="data-table-info">
                                    <td>{index + 1}</td>
                                    <td>{item?.make}</td>
                                    <td>{item?.model}</td>
                                    <td>{item?.variant}</td>
                                    <td>{item?.productType}</td>
                                    <td>{item?.vehicleType}</td>
                                    <td>{item?.customerName}</td>
                                    <td>
                                      {new Date(item.createdAt).toLocaleString(
                                        [],
                                        {
                                          dateStyle: "short",
                                          timeStyle: "medium",
                                        }
                                      )}
                                    </td>
                                    <td>
                                      <a
                                        href={item?.caseUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Proceed Here
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="d-flex align-items-center justify-content-between flex-wrap mt-3">
                            <div>
                              <p>
                                Showing {currentPage * itemsPerPage + 1} -{" "}
                                {Math.min(
                                  (currentPage + 1) * itemsPerPage,
                                  totalPage
                                )}{" "}
                                of {totalPage}
                              </p>
                            </div>
                            <div className="main-page-item">
                              <div className="show-page">
                                <select
                                  className="page-select"
                                  onChange={(e) =>
                                    handleChangeItemPerPage(e.target.value)
                                  }
                                >
                                  <option value={10}>10</option>
                                  <option value={20}>20</option>
                                  <option value={30}>30</option>
                                  <option value={50}>50</option>
                                </select>
                              </div>
                              <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={(e) => handlePageClick(e)}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel="<"
                                renderOnZeroPageCount={null}
                                containerClassName="pagination"
                                activeClassName="active"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* New-POS table end */}
              </div>
            </div>
          </div>
        ) : (
          mySideBar === "myQuotations" && (
            <div className={`page_container ${sideNav ? "open" : ""}`}>
              <div className="main_content">
                <div className="row walletSearchRow">
                  <div className="col-12">
                    <div className="dashboardInner_box">
                      <div className="profile_detail">
                        <div className="section_block">
                          <h2 className="sectionHeading">
                            {mySideBar === "myQuotations" && "My Quotations"}
                          </h2>
                        </div>
                        <div className="policy_content">
                          <div className="policy_img mb-3">
                            <img src="/assets/images/docSearchingIcon.svg"></img>
                          </div>
                          <h2 className="">
                            No {mySideBar === "myQuotations" && "Quotations "}
                            found
                          </h2>
                          <p>
                            {mySideBar === "myQuotations" &&
                              "Once you view a Quotation, "}
                            it will show up here
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
        {logoutModal && (
          <LogoutModal show={logoutModal} setModel={setLogoutModal} />
        )}
      </div>
    </>
  );
};

export default MyProfile;
