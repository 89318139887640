import React from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { resetUserDetails } from "../../store/action/userActions";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";

const LogoutModal = ({ show, setModel }) => {
  const toggleLoginModel = () => setModel(!show);
  console.log("SHOW ", show);
  const toggle = () => setModel(!show);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <Modal isOpen={show} toggle={toggle} backdrop="static" centered size="md">
        <ModalBody>
          <div className="p-3">
            <div className="modal-logo">
              <a>
                <img src="/assets/images/insurefastlogos.svg"></img>
              </a>
            </div>
            <div className="modal_heading w-100 mb-4">
              <h4 className="text-center">Are you sure want to logout?</h4>
              <div
                onClick={toggleLoginModel}
                className="v2_close false"
                style={{ cursor: "pointer" }}
              >
                <a>
                  {/* <img
                    src="/assets/images/delete_icon.svg"
                    title="Close"
                    alt="close"
                  /> */}
                  <RxCross2 style={{ color: "#fff" }} />
                </a>
              </div>
            </div>
            <div className="d-flex justify-content-center gap-5 align-items-center my-3">
              <button
                className="btn btn-primary d-lg-block w-auto"
                onClick={() => {
                  dispatch(resetUserDetails());
                  navigate("/");
                  toggle();
                }}
              >
                Yes
              </button>
              <button
                className="btn btn-primary d-lg-block w-auto"
                onClick={toggle}
              >
                No
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default LogoutModal;
