import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./common.css";
import LoginModel from "../QuotelistComponents/LoginModel";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchMotorQuote,
  resetUserDetails,
} from "../../store/action/userActions";
import { sendLogoutMessage } from "../services/masterServices";
import { FaRegUserCircle } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";

import SignUpModal from "../QuotelistComponents/SignUpModal";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { GiCrenelCrown } from "react-icons/gi";
import { LuBaggageClaim } from "react-icons/lu";
import { BiSupport } from "react-icons/bi";
import { IoMdLogIn } from "react-icons/io";
import LogoutModal from "../Profile/LogoutModal";
import { CgProfile } from "react-icons/cg";
import { MdOutlinePolicy } from "react-icons/md";
import { AiOutlineTransaction } from "react-icons/ai";
import { PiSignOutBold } from "react-icons/pi";
import { VEHICLE_TYPE } from "../utility/constants";

// import { colors } from "react-select/dist/declarations/src/theme";
const Header = () => {
  const [loginModel, setLoginModel] = useState(false);
  const [signUpModel, setSignUpModal] = useState(false);
  const userDetails = useSelector((state) => state?.root?.userDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [url, setUrl] = useState("");
  const [menuBar, setMenuBar] = useState(false);
  const [loginSideBar, setLoginSideBar] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const [logoutModal, setLogoutModal] = useState(false);
  const [productActive, setProductActive] = useState(false);

  // const toggle = setLogoutModal(!logoutModal);

  useEffect(() => {
    setUrl(window?.location?.pathname);
  }, []);

  useEffect(() => {
    if (menuBar || loginSideBar) {
      document.body.classList.add("open");
      document.body.style.overflow = "hidden";
    } else {
      document.body.classList.remove("open");
      document.body.style.overflow = "";
    }

    return () => {
      document.body.classList.remove("open");
      document.body.style.overflow = "";
    };
  }, [menuBar, loginSideBar]);

  const handleMyAccount = (data) => {
    navigate("/my-profile", { state: data });
  };

  const handleProductType = (box) => {
    const currentBox = box;
    let currentVehicleType;
    if (box === "MotorBike") {
      currentVehicleType = "2w";
      dispatchMotorQuote("vehicleType", VEHICLE_TYPE.TWO_WHEELER);
    } else if (box === "Pvt Car") {
      currentVehicleType = "4w";
      dispatchMotorQuote("vehicleType", VEHICLE_TYPE.FOUR_WHEELER);
    }
    navigate("/motor", {
      state: {
        selectedVehicleType: {
          vehicleModal: currentBox,
          vehicleType: currentVehicleType,
        },
        activeStep: 0,
      },
    });
  };

  return (
    <header>
      <div className="container">
        <nav className="nav-bar">
          <Link to="/">
            <img
              className="navbar-brand logo"
              src="/assets/images/logoNew.svg"
            />
          </Link>
          <div className="account">
            {userDetails?.FullName || userDetails?.name ? (
              <div class="dropdown d-lg-none">
                <button
                  class="btn dropdown-toggle"
                  // type="button"
                  // data-bs-toggle="dropdown"
                  // aria-expanded="false"
                  onClick={() => {
                    setLoginSideBar(!loginSideBar);
                    setMenuBar(false);
                  }}
                >
                  {isMobile ? (
                    <span>
                      <FaRegUserCircle />
                    </span>
                  ) : (
                    ""
                  )}
                </button>

                <ul class="dropdown-menu" style={{ height: "fit-content" }}>
                  <li style={{ cursor: "pointer" }}>
                    <a
                      className="dropdown-item"
                      onClick={() => handleMyAccount("myProfile")}
                    >
                      <CgProfile />
                      My Profile
                    </a>
                  </li>
                  <li style={{ cursor: "pointer" }}>
                    <a
                      className="dropdown-item"
                      onClick={() => handleMyAccount("myPolicies")}
                    >
                      My Policies
                    </a>
                  </li>
                  <li style={{ cursor: "pointer" }}>
                    <a
                      className="dropdown-item"
                      onClick={() => handleMyAccount("myTransaction")}
                    >
                      My Transactions
                    </a>
                  </li>
                  <li style={{ cursor: "pointer" }}>
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        // dispatch(resetUserDetails());
                        // navigate("/");
                        setLogoutModal(true);
                      }}
                    >
                      Sign Out
                    </a>
                  </li>
                </ul>
              </div>
            ) : (
              <a
                // style={{
                //   color: "#fff",
                //   textDecoration: "none",
                //   ":hover": { color: "#56b96c" },
                // }}
                onClick={() => {
                  setLoginModel(true);
                  dispatch(resetUserDetails());
                }}
                className="btn d-lg-none"
              >
                <IoMdLogIn />
              </a>
            )}

            <div
              className={`menu-bar d-block d-lg-none ${
                loginSideBar === false && menuBar === true ? "open" : ""
              }`}
              onClick={() => {
                setMenuBar(!menuBar);
                setLoginSideBar(false);
              }}
              id="nav-icon2"
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <div
            className={`navigation ${
              menuBar ? "open" : "" || loginSideBar ? "open" : ""
            }`}
          >
            <ul className="sideNav_menu">
              {loginSideBar && isMobile ? (
                <span
                  className="ms-3 py-1 px-3 mb-3"
                  style={{
                    backgroundColor: "#56B96C",
                    color: "#fff",
                    fontSize: "0.8rem",
                    borderRadius: "2rem",
                  }}
                >
                  {userDetails?.FullName || userDetails?.name}
                </span>
              ) : (
                ""
              )}
              <li className="dash_nav_item">
                <a
                  href="javascript:void(0);"
                  class={`product active ${
                    productActive ? "productActive" : ""
                  }`}
                >
                  {loginSideBar ? (
                    <span
                      class="title_dash_nav"
                      onClick={() => handleMyAccount("myProfile")}
                    >
                      <img src="/assets/images/profile.png"></img>
                      My Profile
                    </span>
                  ) : (
                    <>
                      <span class="icon_holder">
                        <img src="/assets/images/protection.png"></img>
                      </span>
                      <span
                        class="title_dash_nav"
                        onClick={() => setProductActive(!productActive)}
                      >
                        Insurance Product{" "}
                        <svg
                          class="icon"
                          width="14"
                          height="16"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                        >
                          <path d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z" />
                        </svg>
                      </span>
                      <ul class="dropdown-nav">
                        <li>
                          <span>
                            <img src="/assets/images/cardiogram 1.png" />
                            Health
                          </span>
                        </li>
                        <li onClick={() => handleProductType("MotorBike")}>
                          <span>
                            {" "}
                            <img src="/assets/images/bycicle.svg" />
                            Bike
                          </span>
                        </li>
                        <li onClick={() => handleProductType("Pvt Car")}>
                          <span>
                            {" "}
                            <img src="/assets/images/car 1.svg" />
                            Car
                          </span>
                        </li>
                        <li>
                          <span>
                            {" "}
                            <img src="/assets/images/family.svg" />
                            Family
                          </span>
                        </li>
                        {/* <li>
                          <span>
                            {" "}
                            <img src="/assets/images/taxi.svg" />
                            Taxi
                          </span>
                        </li> */}
                        <li>
                          <span>
                            {" "}
                            <img src="/assets/images/pet-house.svg" />
                            Pet
                          </span>
                        </li>
                        <li>
                          <span>
                            {" "}
                            <img src="/assets/images/airplane.png" />
                            Travel
                          </span>
                        </li>
                      </ul>
                    </>
                  )}
                </a>
              </li>
              {loginSideBar ? (
                <li className="dash_nav_item">
                  <a href="javascript:void(0);" class="">
                    <span
                      class="title_dash_nav"
                      onClick={() => handleMyAccount("myPolicies")}
                    >
                      <img src="/assets/images/policy.png"></img>
                      My Policies
                    </span>
                  </a>
                </li>
              ) : null}
              {loginSideBar ? (
                <li className="dash_nav_item">
                  <a href="javascript:void(0);" class="">
                    {loginSideBar ? (
                      <span
                        class="title_dash_nav"
                        onClick={() => handleMyAccount("myQuotations")}
                      >
                        <img src="/assets/images/quotation.png"></img>
                        My Quotations
                      </span>
                    ) : null}
                  </a>
                </li>
              ) : null}

              {/* <li className="dash_nav_item">
                <a href="javascript:void(0);" class="">
                  {loginSideBar ? (
                    <span
                      class="title_dash_nav"
                      onClick={() => handleMyAccount("myTransaction")}
                    >
                      <img src="/assets/images/rupee.png"></img>
                      My Transactions
                    </span>
                  ) : (
                    <>
                      <span class="icon_holder">
                        <img src="/assets/images/claim (1).png"></img>
                      </span>
                      <span class="title_dash_nav">Claims</span>
                    </>
                  )}
                </a>
              </li> */}
              {loginSideBar ? (
                <li className="dash_nav_item">
                  <a href="javascript:void(0);" class="">
                    <span
                      class="title_dash_nav"
                      onClick={() => {
                        // dispatch(resetUserDetails());
                        // navigate("/");
                        setLoginSideBar(!loginSideBar);
                        setMenuBar(false);
                        setLogoutModal(true);
                      }}
                    >
                      <img src="/assets/images/sign-out.png"></img>
                      Sign Out
                    </span>
                  </a>
                </li>
              ) : null}

              {userDetails?.FullName || userDetails?.name ? (
                <li>
                  <div class="dropdown d-none d-lg-block">
                    <button
                      class="btn btn-login dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {isMobile ? (
                        <span>
                          <FaRegUserCircle />
                        </span>
                      ) : (
                        userDetails?.FullName || userDetails?.name
                      )}
                    </button>

                    <ul class="dropdown-menu" style={{ height: "fit-content" }}>
                      <li style={{ cursor: "pointer" }}>
                        <a
                          className="dropdown-item"
                          onClick={() => handleMyAccount("myProfile")}
                        >
                          <img src="/assets/images/profile.png"></img>
                          My Profile
                        </a>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <a
                          className="dropdown-item"
                          onClick={() => handleMyAccount("myPolicies")}
                        >
                          <img src="/assets/images/policy.png"></img>
                          My Policies
                        </a>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <a
                          className="dropdown-item"
                          onClick={() => handleMyAccount("myQuotations")}
                        >
                          <img src="/assets/images/quotation.png"></img>
                          My Quotations
                        </a>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <a
                          className="dropdown-item"
                          onClick={() => handleMyAccount("myTransaction")}
                        >
                          <img src="/assets/images/rupee.png"></img>
                          My Transactions
                        </a>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <a
                          className="dropdown-item"
                          onClick={() => handleMyAccount("")}
                        >
                          <img src="/assets/images/share-icon.svg"></img>
                          Share
                        </a>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <a
                          className="dropdown-item"
                          // onClick={() => handleMyAccount("")}
                        >
                          <img src="/assets/images/support.png"></img>
                          Support
                        </a>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        <a
                          class="dropdown-item"
                          onClick={() => {
                            // dispatch(resetUserDetails());
                            // navigate("/");
                            setLogoutModal(true);
                          }}
                        >
                          <img src="/assets/images/sign-out.png"></img>
                          Sign Out
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : (
                <li>
                  <a
                    // style={{
                    //   color: "#fff",
                    //   textDecoration: "none",
                    //   ":hover": { color: "#56b96c" },
                    // }}
                    onClick={() => {
                      setLoginModel(true);
                      dispatch(resetUserDetails());
                    }}
                    className="btn btn-primary d-none d-lg-block"
                  >
                    Login
                  </a>
                </li>
              )}
            </ul>
          </div>
          {/* <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button> */}
          {/* <div className='collapse navbar-collapse' id='navbarSupportedContent'> */}
          {/* <ul className='navbar-nav ms-auto mb-2 mb-lg-0'> */}
          {/* <li className="nav-item dropdown">
              <a
                className="nav-link active dropdown-toggle fs-5 mx-3"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Insurance Products
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="#">
                    Health
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Car
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Bike
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Taxi
                  </a>
                </li>
              </ul>
            </li> */}
          {/* <li className='nav-item mx-3'>
              <Link
                to='/contact-us'
                className='nav-link active fs-5'
                aria-current='page'
              >
                Contact Us
              </Link>
            </li> */}
          {/* <li className="nav-item mx-3">
              <Link
                to="/privacy-policy"
                className="nav-link active fs-5"
                aria-current="page"
              >
                Privacy & Policy
              </Link>
            </li> */}
          {/* <li className='nav-item mx-3'>
              <Link to='/about-us' className='nav-link active fs-5'>
                About Us
              </Link>
            </li>

            <li className='nav-item mx-3'>
              <Link to='/support' className='nav-link active fs-5' href='#'>
                Support
              </Link>
            </li> */}
          {/* </ul> */}

          {/* {userDetails?.Phone ? (
            <button
              onClick={() => {
                dispatch(resetUserDetails())
                sendLogoutMessage()
                navigate('/')
              }}
              className='btn ms-5 loginBtn'
              type='submit'
            >
              Logout
            </button>
          ) : (
            <button
              onClick={() => setLoginModel(true)}
              className='btn ms-5 loginBtn'
              type='submit'
            >
              Login
            </button>
          )}

          {url !== '/my-profile' && userDetails?.Phone ? (
            <button
              title='My Profile'
              onClick={() => {
                navigate('/my-profile')
              }}
              className='btn ms-1 '
              type='submit'
            >
              <img
                src='./assets/images/userProfile.png'
                alt='Profile'
                width='50px'
              />
            </button>
          ) : (
            ''
          )}
        </div> */}
        </nav>
        {loginModel && (
          <LoginModel
            show={loginModel}
            setModel={setLoginModel}
            setSignUpModal={setSignUpModal}
          />
        )}
        {signUpModel && (
          <SignUpModal show={signUpModel} setModel={setSignUpModal} />
        )}

        {logoutModal && (
          <LogoutModal show={logoutModal} setModel={setLogoutModal} />
        )}
      </div>
    </header>
  );
};

export default Header;
