import React from "react";
import "./OurPartners.css";
import { insurers } from "../utility/constants";

const OurPartner = () => {
  return (
    <div className="partners mb-5 container">
      <div className="thought-process-top text-center mt-3">
        <h2>Our Partners</h2>
        <img src="/assets/images/underlline.png" />
      </div>
      <div className="partners-logo-div">
        <div className="partners-logo">
          <img
            className="color"
            src="/assets/images/partners/Shriram_general.jpg"
            alt="partner-images"
          />
        </div>
        <div className="partners-logo">
          <img
            className="color"
            src="/assets/images/logos/partners/usgi-logo.svg"
            alt=""
          />
        </div>
        <div className="partners-logo">
          <img
            className="color"
            src="/assets/images/partners/digit.png"
            alt=""
          />
        </div>
        <div className="partners-logo">
          <img className="color" src={insurers.Kotak.logo} alt="" />
        </div>

        <div className="partners-logo">
          <img
            className="color"
            src="/assets/images/partners/general.jpg"
            alt="partner-images"
          />
        </div>
        <div className="partners-logo">
          <img
            className="color"
            src="/assets/images/partners/co-img.png"
            alt="partner-images"
          />
        </div>
        <div className="partners-logo">
          <img
            className="color"
            src="/assets/images/partners/magma-img.png"
            alt="partner-images"
          />
        </div>
        <div className="partners-logo">
          <img
            className="color"
            src="/assets/images/partners/bajaj-img.jpg"
            alt="partner-images"
          />
        </div>
        <div className="partners-logo">
          <img src="/assets/images/partners/Sbi.png" alt="partner-images" />
        </div>
        <div className="partners-logo">
          <img
            src="/assets/images/partners/zuno_general_insurance.jpg"
            alt="partner-images"
          />
        </div>
        <div className="partners-logo">
          <img
            src="/assets/images/partners/royal-img.jpg"
            alt="partner-images"
          />
        </div>
        <div className="partners-logo">
          <img
            src="/assets/images/partners/national-img.png"
            alt="partner-images"
          />
        </div>
        <div className="partners-logo">
          <img src="/assets/images/partners/Ms-img.png" alt="partner-images" />
        </div>
        <div className="partners-logo">
          <img
            src="/assets/images/partners/liberty-img.jpg"
            alt="partner-images"
          />
        </div>
        <div className="partners-logo">
          <img
            src="/assets/images/partners/iffco-img.jpg"
            alt="partner-images"
          />
        </div>
        <div className="partners-logo">
          <img src="/assets/images/partners/capital.png" alt="partner-images" />
        </div>
        <div className="partners-logo">
          <img src="/assets/images/partners/care.png" alt="partner-images" />
        </div>
        <div className="partners-logo">
          <img
            src="/assets/images/partners/real-img.jpg"
            alt="partner-images"
          />
        </div>
        <div className="partners-logo">
          <img src="/assets/images/partners/hdfc.jpg" alt="partner-images" />
        </div>
        <div className="partners-logo">
          <img src="/assets/images/partners/rajega.jpg" alt="partner-images" />
        </div>
        <div className="partners-logo">
          <img
            src="/assets/images/partners/tata.png"
            style={{ height: "100px", width: "100px" }}
            alt="partner-images"
          />
        </div>
        <div className="partners-logo">
          <img src="/assets/images/partners/star.jpg" alt="partner-images" />
        </div>

        <div className="partners-logo">
          <img
            src="/assets/images/partners/oriental.png"
            alt="partner-images"
          />
        </div>
        <div className="partners-logo">
          <img src="/assets/images/partners/niwa.png" alt="partner-images" />
        </div>
        <div className="partners-logo">
          <img
            src="/assets/images/partners/new_india.png"
            alt="partner-images"
          />
        </div>
        <div className="partners-logo">
          <img src="/assets/images/partners/icici.jfif" alt="partner-images" />
        </div>
      </div>
    </div>
    // <div className="partner-outer">
    //   <div className="thought-process-top text-center mt-3">
    //     <h2>Our Partners</h2>
    //     <img src="/assets/images/underlline.png" />
    //   </div>
    //   <div className="ourpartner">
    //     <ul className="partnerlogos">
    //       <li>
    //         <img
    //           src="./assets/images/logos/partners/usgi-logo.svg"
    //           alt="img"
    //           className="img-fluid"
    //         />
    //       </li>
    //       <li>
    //         <img
    //           src="./assets/images/digitimg.png"
    //           alt="img"
    //           className="img-fluid"
    //         />
    //       </li>
    //       <li>
    //         <img
    //           src="./assets/images/kotakimg.png"
    //           alt="img"
    //           className="img-fluid"
    //         />
    //       </li>
    //       <li>
    //         <img
    //           src="./assets/images/bajajimg.png"
    //           alt="img"
    //           className="img-fluid"
    //         />
    //       </li>
    //       <li>
    //         <img
    //           src="./assets/images/shiramimg.png"
    //           alt="img"
    //           className="img-fluid"
    //         />
    //       </li>
    //       {/* <li>
    //       <img
    //         src="./assets/images/sbiimg.png"
    //         alt="img"
    //         className="img-fluid"
    //       />
    //     </li> */}
    //     </ul>
    //   </div>
    // </div>
  );
};

export default OurPartner;
