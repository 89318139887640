import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { dispatchPetQuickQuote } from "../../store/action/userActions";
import { useForm } from "react-hook-form";

// import { resetUserDetails } from "../../store/action/userActions";
// import { useNavigate } from "react-router-dom";

const PetModal = ({ show, setModel }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const togglepetModel = () => setModel(!show);
  const [activeStep, setActiveStep] = useState(1);
  console.log("SHOW ", show);
  const toggle = () => setModel(!show);
  //   const dispatch = useDispatch();
  //   const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  const numberOfDOgs = Array.from({ length: value }, (image, index) => index);
  const [accordionOpen, setAccordionOpen] = useState(
    Array(numberOfDOgs.length).fill(false)
  );

  const toggleAccordion = (index) => {
    console.log("...accordionOpen", accordionOpen);
    const newAccordionOpen = [...accordionOpen];
    newAccordionOpen[index] = !newAccordionOpen[index];
    for (let i = 0; i < accordionOpen.length; i++) {
      if (i !== index) {
        newAccordionOpen[i] = false;
      }
    }

    setAccordionOpen(newAccordionOpen);
  };

  const decrement = () => {
    if (value <= 0) {
      return;
    } else {
      setValue(value - 1);
    }
  };

  const increment = () => {
    if (value >= 5) {
      return;
    } else {
      setValue(value + 1);
    }
  };

  const submitPetDetails = (data) => {
    console.log("PET DETAILS", data);
    data?.petDetails.forEach((petDetail, index) => {
      Object.entries(petDetail).forEach(([key, value]) => {
        dispatchPetQuickQuote(`petDetails[${index}].${key}`, value);
      });
    });
  };

  return (
    <>
      <Modal
        isOpen={show}
        toggle={toggle}
        backdrop="static"
        centered
        size={activeStep === 2 ? "lg" : "md"}
      >
        <ModalBody>
          {activeStep === 1 && (
            <>
              <div className="p-3">
                <div className="modal-logo">
                  <a>
                    <img src="/assets/images/insurefastlogos.svg"></img>
                  </a>
                </div>
                <div className="modal_heading w-100 mb-4">
                  <h4 className="text-center">
                    Number of pet dogs that you own.
                  </h4>
                  <div onClick={togglepetModel} className="v2_close false">
                    <a>
                      <img
                        src="./assets/images/delete_icon.svg"
                        title="Close"
                        alt="close"
                      />
                    </a>
                  </div>
                </div>
                <div className="dog_count">
                  <input
                    type="text"
                    class="input-number"
                    value={value}
                    readOnly
                  />
                  <div className="count_num">
                    <span
                      className="input-number-increment"
                      onClick={() => {
                        increment();
                        dispatchPetQuickQuote(
                          "planDetails.dogCount",
                          value + 1
                        );
                      }}
                    >
                      +
                    </span>
                    <span
                      className="input-number-decrement"
                      onClick={() => {
                        decrement();
                        dispatchPetQuickQuote(
                          "planDetails.dogCount",
                          value - 1
                        );
                      }}
                    >
                      -
                    </span>
                  </div>
                </div>
                <p className="pet_img">
                  {numberOfDOgs.map((image, index) => (
                    <span
                      class="sheep"
                      v-for="sheep in sheepCount"
                      key={index}
                    ></span>
                  ))}
                </p>
              </div>
            </>
          )}
          {activeStep === 2 && (
            <>
              <div className="p-3">
                <div className="modal-logo">
                  <a>
                    <img src="/assets/images/insurefastlogos.svg"></img>
                  </a>
                </div>
                <div className="modal_heading w-100 mb-4">
                  <h4 className="text-center">
                    Number of pet dogs that you own.
                  </h4>
                  <div onClick={togglepetModel} className="v2_close false">
                    <a>
                      <img
                        src="./assets/images/delete_icon.svg"
                        title="Close"
                        alt="close"
                      />
                    </a>
                  </div>
                </div>
                <div className="pet_breed">
                  <form onSubmit={handleSubmit(submitPetDetails)}>
                    <ul class="accordion">
                      {numberOfDOgs.map((count, index) => (
                        <li class="accordion-item" key={index}>
                          <input
                            id={`s${index}`}
                            class="hide"
                            type="checkbox"
                            checked={accordionOpen[index]}
                            onChange={() => toggleAccordion(index)}
                          />
                          <label for={`s${index}`} class="accordion-label">
                            {index === 0
                              ? "First"
                              : index === 1
                              ? "Second"
                              : index === 2
                              ? "Third"
                              : index === 3
                              ? "Fourth"
                              : index === 4
                              ? "Fifth"
                              : ""}{" "}
                            Pet
                          </label>
                          <p class="accordion-child">
                            <div className="pet_det">
                              <div className="pet_form">
                                <div className="pet_input_field">
                                  <div className="rofile_details">
                                    <div className="mb-4">
                                      <div className="did-floating-label-content">
                                        <input
                                          type="text"
                                          className="did-floating-input"
                                          {...register(
                                            `petDetails[${index}].dogName`
                                          )}
                                          // id="name"
                                          placeholder="Enter Full Name"
                                          // name="name"
                                        />
                                        <label
                                          htmlFor="name"
                                          className="did-floating-label"
                                        >
                                          Name
                                        </label>
                                      </div>
                                    </div>
                                    <div className="mb-4">
                                      <div className="did-floating-label-content">
                                        <select
                                          className="did-floating-input text-truncate form-select"
                                          {...register(
                                            `petDetails[${index}].ageOfDog.ageInYears`
                                          )}
                                          placeholder=" "
                                        >
                                          <option className="did-floating-label">
                                            0
                                          </option>
                                          <option className="did-floating-label">
                                            1
                                          </option>
                                          <option className="did-floating-label">
                                            2
                                          </option>
                                        </select>
                                        <label
                                          htmlFor="email"
                                          className="did-floating-label"
                                        >
                                          Years
                                        </label>
                                      </div>
                                    </div>
                                    <div className="mb-4">
                                      <div className="did-floating-label-content">
                                        <select
                                          className="did-floating-input text-truncate form-select"
                                          {...register(
                                            `petDetails[${index}].ageOfDog.ageInMonth`
                                          )}
                                          placeholder=" "
                                          // name="email"
                                        >
                                          <option className="did-floating-label">
                                            0
                                          </option>
                                          <option className="did-floating-label">
                                            1
                                          </option>
                                          <option className="did-floating-label">
                                            2
                                          </option>
                                        </select>
                                        <label
                                          htmlFor="email"
                                          className="did-floating-label"
                                        >
                                          Month
                                        </label>
                                      </div>
                                    </div>
                                    <div className="mb-4">
                                      <div className="did-floating-label-content">
                                        <select
                                          className="did-floating-input text-truncate form-select"
                                          {...register(
                                            `petDetails[${index}].dogWeight`
                                          )}
                                          placeholder=" "
                                          // name="email"
                                        >
                                          <option className="did-floating-label">
                                            0
                                          </option>
                                          <option className="did-floating-label">
                                            11 Kg to 25 Kg
                                          </option>
                                          <option className="did-floating-label">
                                            25 Kg to 50 Kg
                                          </option>
                                        </select>
                                        <label
                                          htmlFor="email"
                                          className="did-floating-label"
                                        >
                                          Expected Adult Weight
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="pet_breed_img">
                                <div className="pseudo-search">
                                  <input
                                    type="text"
                                    placeholder="Search..."
                                    autofocus
                                  />

                                  <button
                                    class="fa fa-search"
                                    type="submit"
                                  ></button>
                                </div>
                                <div className="pet_breed">
                                  <div className="breed_list">
                                    <ul>
                                      <li>
                                        <a href="#">
                                          <div class="fplogo">
                                            {" "}
                                            <img src="/assets/images/pomeranian.png"></img>
                                          </div>
                                          <div class="fptext">
                                            <p className="text-truncate">
                                              Pomeranian
                                            </p>
                                          </div>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <div class="fplogo">
                                            {" "}
                                            <img src="/assets/images/pug.avif"></img>
                                          </div>
                                          <div class="fptext">
                                            <p className="text-truncate">Pug</p>
                                          </div>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <div class="fplogo">
                                            {" "}
                                            <img src="/assets/images/bulldog.jpeg"></img>
                                          </div>
                                          <div class="fptext">
                                            <p className="text-truncate">
                                              Bulldog
                                            </p>
                                          </div>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <div class="fplogo">
                                            {" "}
                                            <img src="/assets/images/Siberian.webp"></img>
                                          </div>
                                          <div class="fptext">
                                            <p className="text-truncate">
                                              Siberian Husky
                                            </p>
                                          </div>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <div class="fplogo">
                                            {" "}
                                            <img src="/assets/images/Alaskan.webp"></img>
                                          </div>
                                          <div class="fptext">
                                            <p className="text-truncate">
                                              Alaskan Malamute
                                            </p>
                                          </div>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <div class="fplogo">
                                            {" "}
                                            <img src="/assets/images/pomeranian.png"></img>
                                          </div>
                                          <div class="fptext">
                                            <p className="text-truncate">
                                              Pomeranian
                                            </p>
                                          </div>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <div class="fplogo">
                                            {" "}
                                            <img src="/assets/images/pomeranian.png"></img>
                                          </div>
                                          <div class="fptext">
                                            <p className="text-truncate">
                                              Pomeranian
                                            </p>
                                          </div>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <div class="fplogo">
                                            {" "}
                                            <img src="/assets/images/pomeranian.png"></img>
                                          </div>
                                          <div class="fptext">
                                            <p className="text-truncate">
                                              Pomeranian
                                            </p>
                                          </div>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <div class="fplogo">
                                            {" "}
                                            <img src="/assets/images/pomeranian.png"></img>
                                          </div>
                                          <div class="fptext">
                                            <p className="text-truncate">
                                              Pomeranian
                                            </p>
                                          </div>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </p>
                        </li>
                      ))}
                    </ul>
                    <div class="rto-sub">
                      <button
                        type="submit"
                        class="btn-primary"
                        // onClick={() => setActiveStep(activeStep + 1)}
                      >
                        Next
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
          {activeStep === 3 && (
            <div className="p-lg-3">
              <div className="modal-logo">
                <a>
                  <img src="/assets/images/insurefastlogos.svg"></img>
                </a>
              </div>
              <div className="modal_heading w-100 mb-4">
                <h4 className="text-center">What is the breed of your pet?</h4>
                <div onClick={togglepetModel} className="v2_close false">
                  <a>
                    <img
                      src="./assets/images/delete_icon.svg"
                      title="Close"
                      alt="close"
                    />
                  </a>
                </div>
              </div>
              <div className="pseudo-search">
                <input type="text" placeholder="Search..." autofocus />

                <button class="fa fa-search" type="submit"></button>
              </div>

              <div className="pet_breed">
                <span className="breed_heading">Popular pet breeds</span>
                <div className="breed_list">
                  <ul>
                    <li>
                      <a href="#">
                        <div class="fplogo">
                          {" "}
                          <img src="/assets/images/pomeranian.png"></img>
                        </div>
                        <div class="fptext">
                          <p className="text-truncate">Pomeranian</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div class="fplogo">
                          {" "}
                          <img src="/assets/images/pug.avif"></img>
                        </div>
                        <div class="fptext">
                          <p className="text-truncate">Pug</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div class="fplogo">
                          {" "}
                          <img src="/assets/images/bulldog.jpeg"></img>
                        </div>
                        <div class="fptext">
                          <p className="text-truncate">Bulldog</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div class="fplogo">
                          {" "}
                          <img src="/assets/images/Siberian.webp"></img>
                        </div>
                        <div class="fptext">
                          <p className="text-truncate">Siberian Husky</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div class="fplogo">
                          {" "}
                          <img src="/assets/images/Alaskan.webp"></img>
                        </div>
                        <div class="fptext">
                          <p className="text-truncate">Alaskan Malamute</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div class="fplogo">
                          {" "}
                          <img src="/assets/images/pomeranian.png"></img>
                        </div>
                        <div class="fptext">
                          <p className="text-truncate">Pomeranian</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div class="fplogo">
                          {" "}
                          <img src="/assets/images/pomeranian.png"></img>
                        </div>
                        <div class="fptext">
                          <p className="text-truncate">Pomeranian</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div class="fplogo">
                          {" "}
                          <img src="/assets/images/pomeranian.png"></img>
                        </div>
                        <div class="fptext">
                          <p className="text-truncate">Pomeranian</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div class="fplogo">
                          {" "}
                          <img src="/assets/images/pomeranian.png"></img>
                        </div>
                        <div class="fptext">
                          <p className="text-truncate">Pomeranian</p>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
          {activeStep === 4 && (
            <>
              <div className="p-3">
                <div className="modal-logo">
                  <a>
                    <img src="/assets/images/insurefastlogos.svg"></img>
                  </a>
                </div>
                <div className="modal_heading w-100 mb-4">
                  <h4 className="text-center">What’s the age of your pet?</h4>
                  <div onClick={togglepetModel} className="v2_close false">
                    <a>
                      <img
                        src="./assets/images/delete_icon.svg"
                        title="Close"
                        alt="close"
                      />
                    </a>
                  </div>
                </div>
                <div className="pet_input_field">
                  <div className="rofile_details">
                    <div className="mb-4">
                      <div className="did-floating-label-content">
                        <input
                          type="text"
                          className="did-floating-input"
                          // {...register("fullName", {
                          //   required: "Field is Required",
                          // })}
                          // id="name"
                          placeholder="Enter Full Name"
                          // name="name"
                        />
                        <label htmlFor="name" className="did-floating-label">
                          Name
                        </label>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="did-floating-label-content">
                        <select
                          className="did-floating-input text-truncate"
                          //   {...register("MaritalStatus")}
                          placeholder=" "
                          // name="email"
                        >
                          <option className="did-floating-label" value={0}>
                            0
                          </option>
                          <option className="did-floating-label" value={1}>
                            1
                          </option>
                          <option className="did-floating-label" value="">
                            2
                          </option>
                        </select>
                        <label htmlFor="email" className="did-floating-label">
                          Years
                        </label>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="did-floating-label-content">
                        <select
                          className="did-floating-input text-truncate"
                          //   {...register("MaritalStatus")}
                          placeholder=" "
                          // name="email"
                        >
                          <option className="did-floating-label">0</option>
                          <option className="did-floating-label" value="Single">
                            1
                          </option>
                          <option className="did-floating-label" value="">
                            2
                          </option>
                        </select>
                        <label htmlFor="email" className="did-floating-label">
                          Month
                        </label>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="did-floating-label-content">
                        <select
                          className="did-floating-input text-truncate"
                          //   {...register("MaritalStatus")}
                          placeholder=" "
                          // name="email"
                        >
                          <option className="did-floating-label">0</option>
                          <option className="did-floating-label" value="Single">
                            11 Kg to 25 Kg
                          </option>
                          <option className="did-floating-label" value="">
                            25 Kg to 50 Kg
                          </option>
                        </select>
                        <label htmlFor="email" className="did-floating-label">
                          Expected Adult Weight
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div class="d-flex final-step-inner-2-button gap-4 justify-content-center">
            <div class="rto-sub final-step-inner">
              <button
                type="button"
                class="btn"
                onClick={() => setActiveStep(activeStep - 1)}
              >
                Previous
              </button>
            </div>
            <div class="rto-sub">
              <button
                type="button"
                class="btn-primary"
                onClick={() => setActiveStep(activeStep + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PetModal;
